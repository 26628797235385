export enum SocketEvent {
  PING = 'PING',
  PONG = 'PONG',
  NEW_ACTIVITY = 'NEW_ACTIVITY',
  NEW_CHAT_MESSAGE = 'NEW_CHAT_MESSAGE',
  REFETCH_NOTIFICATIONS = 'REFETCH_NOTIFICATIONS',
}

export type NewChatMessagePayload = {
  messageId: string;
  message: string;
  senderId: string;
  senderName: string;
  channelUrl: string;
  chatRoomId: string;
  replyToId: string;
};
