import { IconProps } from './type';

export const NotificationOffIcon = ({ color = 'currentColor', size = 24 }: IconProps): JSX.Element => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.18121 9.70753C6.81898 6.80963 9.07857 4.25 11.999 4.25V4.25C14.9195 4.25 17.1791 6.80963 16.8168 9.70753L16.5177 12.1005C16.5056 12.1974 16.5263 12.2955 16.5765 12.3792L18.5903 15.7355C18.9902 16.402 18.5101 17.25 17.7328 17.25H6.26521C5.48792 17.25 5.00781 16.402 5.40772 15.7355L7.4215 12.3792C7.47173 12.2955 7.49244 12.1974 7.48033 12.1005L7.18121 9.70753Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M14.499 17.25C14.499 18.6307 13.3797 19.75 11.999 19.75C10.6183 19.75 9.49902 18.6307 9.49902 17.25"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <line
      x1="3.99902"
      y1="19.8686"
      x2="19.8677"
      y2="4"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </svg>
);
