import { createPopper, Placement } from '@popperjs/core';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { MutableRefObject, ReactNode, RefObject, useEffect, useRef } from 'react';
import { globalEvents } from 'utils/event';
import styles from './Dropdown.module.scss';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  closeMe: () => void;
  buttonRef: RefObject<HTMLElement> | MutableRefObject<Element>;
  positioningStrategy?: 'fixed' | 'absolute';
  placement?: Placement;
  padding?: boolean;
  zIndex?: number;
};

export const Dropdown = ({
  children,
  isOpen,
  closeMe,
  buttonRef,
  positioningStrategy = 'absolute',
  placement = 'bottom-start',
  padding = true,
  zIndex = 2,
}: Props): JSX.Element => {
  const router = useRouter();
  const menuRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!menuRef.current || !arrowRef.current || !buttonRef.current) return;
    const popperInstance = createPopper(buttonRef.current, menuRef.current, {
      strategy: positioningStrategy,
      placement,
      onFirstUpdate: (state) => {
        if (!state || !state.elements) return;
        state.elements.popper.style.opacity = '1';
        state.elements.popper.style.visibility = 'visible';
      },
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: ({ placement }: { placement: string }) =>
              placement === 'bottom-end' ? [20, 10] : [-22, 10],
          },
        },
        {
          name: 'arrow',
          options: {
            element: arrowRef.current,
            padding: { left: -22, right: 10 },
          },
        },
      ],
    });

    const forceUpdate = () => {
      popperInstance.forceUpdate();
    };
    globalEvents.on('dropdownResize', forceUpdate);

    return () => {
      globalEvents.off('dropdownResize', forceUpdate);
      popperInstance.destroy();
    };
  }, [isOpen, menuRef.current, arrowRef.current, buttonRef.current]);

  // TODO: Figure out this type
  const onClickOutside = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      closeMe();
    }
  };

  useEffect(() => {
    const handleRouteChange = () => closeMe();
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => document.addEventListener('click', onClickOutside));
      const inModal = document.querySelector('[role="modal-inner"]');
      if (inModal) {
        inModal.addEventListener('click', onClickOutside);
      }
    } else document.removeEventListener('click', onClickOutside);
    return () => {
      document.removeEventListener('click', onClickOutside);
      const inModal = document.querySelector('[role="modal-inner"]');
      if (inModal) {
        inModal.removeEventListener('click', onClickOutside);
      }
    };
  }, [isOpen]);

  if (isOpen && children) {
    return (
      <div
        className={classNames(styles.menu, { [styles.padding]: padding })}
        ref={menuRef}
        onClick={(e) => e.stopPropagation()}
        style={{ zIndex }}
        role="dropdown"
      >
        <div className={styles.arrow} ref={arrowRef} />
        <div className={styles.content}>{children}</div>
      </div>
    );
  }

  return <></>;
};

export default Dropdown;
