import { IconProps } from './type';

export const EditIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.7764 17.7278L7.53373 13.4851L15.3119 5.70697C15.7024 5.31644 16.3356 5.31644 16.7261 5.70697L19.5545 8.5354C19.9451 8.92592 19.9451 9.55908 19.5545 9.94961L11.7764 17.7278Z"
      stroke={color}
      strokeWidth="1.6"
    />
    <path d="M6.11989 19.142L7.5341 13.4851L11.7767 17.7278L6.11989 19.142Z" fill={color} />
  </svg>
);
