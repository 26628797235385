import Link from 'next/link';
import { Button } from 'ui/generic';
import { UserSadIcon } from 'ui/icons';
import styles from './ErrorMessage.module.scss';

type Props = {
  title?: string;
  subtitle?: string;
  onAction?: () => void;
  actionTitle?: string;
  href?: string;
  as?: string;
  hideButton?: boolean;
  icon?: React.ReactNode;
  isSignedIn?: boolean;
};

export const ErrorMessage = ({
  title = 'Internal server error',
  subtitle = 'If the problem persists, please get in touch.',
  onAction = () => window.location.reload(),
  actionTitle = 'Reload',
  href,
  as,
  hideButton,
  icon,
}: Props): JSX.Element => {
  let button = (
    <Button className="mt-5" onClick={onAction}>
      {actionTitle}
    </Button>
  );

  if (href) {
    button = (
      <Link href={href} as={as}>
        <a className="mt-5">
          <Button>{actionTitle}</Button>
        </a>
      </Link>
    );
  }

  return (
    <div className={styles.container}>
      {icon ? icon : <UserSadIcon color="var(--accentAlert)" />}
      <h2 className="uiNav mt-5">{title}</h2>
      <p className="uiBase mt-5">{subtitle}</p>
      {!hideButton && button}
    </div>
  );
};

export const ErrorMessageFullPage = (props: Props): JSX.Element => {
  return (
    <div className={styles.fullPage}>
      <ErrorMessage {...props} />
      {props.isSignedIn && (
        <div className={styles.background}>
          <div className={styles.backgroundInner} />
        </div>
      )}
    </div>
  );
};
