import { IconProps } from './type';

export const PatronIcon = ({ color = 'currentColor', size = 20 }: IconProps): JSX.Element => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.14706 3.33334L2.5 7.2436M5.14706 3.33334L10 3.33334M5.14706 3.33334L7.13235 7.2436M14.8529 3.33334L17.5 7.2436M14.8529 3.33334L10 3.33334M14.8529 3.33334L12.8676 7.2436M17.5 7.2436L10 16.6667M17.5 7.2436H12.8676M2.5 7.2436L10 16.6667M2.5 7.2436H7.13235M7.13235 7.2436L12.8676 7.2436M7.13235 7.2436L10 16.6667M7.13235 7.2436L10 3.33334M10 16.6667L12.8676 7.2436M12.8676 7.2436L10 3.33334"
      stroke={color}
      strokeWidth="1.6"
      strokeLinejoin="round"
    />
  </svg>
);
