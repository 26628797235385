export function chunkArray<T>(input: Array<T>, columns: number): T[][] {
  const output: Array<Array<T>> = Array(columns)
    .fill('')
    .map(() => []);
  input.forEach((value, index) => {
    const i = index % columns;
    output[i].push(value);
  });

  return output;
}

export function shuffleArray<T>(array: T[]): T[] {
  return array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
}

export function shuffleByOtherArray<T>(array: T[], otherArray: T[]): T[] {
  return array.filter((item) => otherArray.includes(item));
}

export function getRandomItem<T>(array: T[]): T {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
}

/**
 * Remove duplicate objects from array of objects, by key
 */
export function removeDuplicates<T>(array: T[], key: keyof T): T[] {
  return array.filter((item, index, self) => {
    return self.findIndex((t) => t[key] === item[key]) === index;
  });
}

export function removeDuplicatesIgnoreNulls<T>(array: T[], key: keyof T): T[] {
  return array.filter((item, index, self) => {
    if (!item[key]) return true;
    return self.findIndex((t) => t[key] === item[key]) === index;
  });
}
/**
 * Sorts an array of objects by a string property case insensitive
 */
export function sortBy<T extends Record<string, any>>(array: T[], property: keyof T): T[] {
  return array.sort((a, b) => {
    const aProp = a[property].toLowerCase();
    const bProp = b[property].toLowerCase();
    if (aProp < bProp) return -1;
    if (aProp > bProp) return 1;
    return 0;
  });
}
