import classnames from 'classnames';
import { forwardRef } from 'react';
import { LaurelLeftIcon, LaurelRightIcon } from 'ui/icons';
import styles from './Rating.module.scss';
import { Star } from './Star';

const numStars = 5;

type Props = {
  initValue: number;
  size?: number;
  withoutLurels?: boolean;
};

export const Rating = forwardRef<HTMLDivElement, Props>(
  ({ initValue, size = 32, withoutLurels = false }, ref) => {
    const laurelSize = size + 5;
    const offsetLaurels = -1.3 * (laurelSize - size);
    const starWidth = size - size / 5;
    const innerWidth = starWidth * 5;
    const containerWidth = withoutLurels ? innerWidth : innerWidth + laurelSize * 0.5 * 2;
    return (
      <div
        className={classnames(styles.container, { [styles.withoutLurels]: withoutLurels })}
        style={{ width: containerWidth, height: size }}
        ref={ref}
      >
        {!withoutLurels && (
          <span className={styles.laurelLeft} style={{ bottom: offsetLaurels }}>
            <LaurelLeftIcon size={laurelSize} />
          </span>
        )}
        <span className={styles.inner} style={{ width: starWidth * 5, marginLeft: Math.floor(size / -5) }}>
          {[...Array(numStars)].map((_, index: number) => (
            <span key={index} style={{ width: starWidth, height: size }}>
              <Star value={initValue} index={index} width={size} height={size} />
            </span>
          ))}
        </span>
        {!withoutLurels && (
          <span className={styles.laurelRight} style={{ bottom: offsetLaurels }}>
            <LaurelRightIcon size={laurelSize} />
          </span>
        )}
      </div>
    );
  }
);

Rating.displayName = 'Rating';
