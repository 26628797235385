/* eslint-disable @typescript-eslint/no-unused-vars */
import constate from 'constate';
import throttle from 'lodash.throttle';
import { useCallback, useEffect, useState } from 'react';
import { isBrowser } from 'utils/device';

type SizeState = {
  smScreen: boolean;
  mdScreen: boolean;
  lgScreen: boolean;
};

type State = SizeState & {
  isSizeReady: boolean;
};

const isSmall = () => isBrowser() && window.innerWidth < 600;
const isMedium = () => isBrowser() && window.innerWidth >= 600 && window.innerWidth < 900;
const isLarge = () => isBrowser() && window.innerWidth >= 900;

function useScreenSizeHook(): State {
  const [isSizeReady, setIsSizeReady] = useState(false);
  const [state, setState] = useState<SizeState>({
    smScreen: true,
    mdScreen: false,
    lgScreen: false,
  });

  const setScreenSize = useCallback(() => {
    if (isSmall()) {
      return setState({ smScreen: true, mdScreen: false, lgScreen: false });
    } else if (isMedium()) {
      return setState({ smScreen: false, mdScreen: true, lgScreen: false });
    } else {
      return setState({ smScreen: false, mdScreen: false, lgScreen: true });
    }
  }, [state, setState]);

  useEffect(() => {
    setScreenSize();
    setIsSizeReady(true);
    const calcInnerWidth = throttle(function () {
      setScreenSize();
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return { ...state, isSizeReady };
}

const [ScreenSizeProvider, useScreenSize] = constate(useScreenSizeHook);

export { ScreenSizeProvider, useScreenSize as useScreenSize };
