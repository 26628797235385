import styles from './Loader.module.scss';

type Props = {
  color?: string;
  size?: number;
};

export const Loader = ({ color = 'currentColor', size = 24 }: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <div
        style={{ backgroundColor: `${color}`, width: `${size}px`, height: `${size}px` }}
        className={styles.spinner}
      />
    </div>
  );
};
