import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import LoadingContainer from 'components/basics/LoadingContainer';
import { ReactNode, useEffect, useRef } from 'react';
import styles from './SearchSuggestions.module.scss';

type Props = {
  suggestions?: ReactNode;
  searchHistory?: ReactNode;
  moreHref?: string;
  moreAs?: string;
  loading?: boolean;
};

const SearchSuggestions = ({ suggestions, searchHistory, loading }: Props): JSX.Element | null => {
  const scrollable = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!scrollable.current) return;
    disableBodyScroll(scrollable.current);
    return () => clearAllBodyScrollLocks();
  }, []);

  return (
    <div
      className={styles.container}
      ref={scrollable}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <LoadingContainer loading={loading}>
        <div className={styles.inner}>
          {searchHistory && <div className="pt-3">{searchHistory}</div>}
          {suggestions && suggestions}
        </div>
      </LoadingContainer>
    </div>
  );
};

export default SearchSuggestions;
