export const routes = {
  landing: '/',
  landingSignUp: '/#signup',
  signIn: '/signin',
  register: '/register',
  library: '/library',
  profileWantToRead: '/[handle]/wants-to-read',
  profileIsReading: '/[handle]/is-reading',
  profileDropped: '/[handle]/dropped',
  profileHasRead: '/[handle]/has-read',
  invite: '/invite',
  inviteCode: '/invite/code',
  inviteLink: '/invite/[inviteLinkId]',
  notifications: '/notifications',
  clubs: '/clubs',
  club: '/club/[clubHandle]',
  clubPost: '/club/[clubHandle]/post/[postId]',
  blog: '/blog',
  blogArticle: '/blog/[...uri]',
  userOwnedBook: '/[handle]/book/[bookSlug]',
  globalBook: '/book/[bookSlug]',
  globalBookShare: '/book/[bookSlug]/share',
  shelf: '/[handle]/shelf/[shelfSlug]',
  highlight: '/[handle]/book/[bookSlug]/moment/[momentId]',
  profile: '/[handle]',
  profileActivity: '/[handle]/activity',
  profileClubs: '/[handle]/clubs',
  profileReviews: '/[handle]/reviews',
  profileGoals: '/[handle]/goals',
  bookCover: '/book/[bookSlug]/cover',
  highlightShare: '/[handle]/book/[bookSlug]/moment/[momentId]/share',
  profileShare: '/[handle]/share',
  clubShare: '/club/[clubHandle]/share',
  shelfShare: '/[handle]/shelf/[shelfSlug]/share',
  activity: '/activity/[activityId]',
  goodreads: '/goodreads/import',
  goodreadsStart: '/api/goodreads/login',
  storygraph: '/storygraph/import',
  waitlist: '/waitlist',
  waitlistConfirmation: '/waitlist-confirmation',
  appPublic: '/app/public',
  appPublicSignin: '/app/public/signin',
  appPublicWaitlist: '/app/public/waitlist',
  appPublicWaiting: '/app/public/waiting',
  reviewShare: '/review/[reviewId]/share',
  inviteShare: '/invite/[inviteLinkId]/share',
  search: '/search',
  searchWithQuery: '/search/[query]',
  goals: '/goals',
  goal: '/[handle]/goal/[goalSlug]',
  exportCsv: '/api/export/csv',
  goalProgressShare: '/[handle]/goal/[goalSlug]/share',
  goalCreatedShare: '/[handle]/goal/[goalSlug]/share-created',
  explore: '/explore',
  chat: '/chat',
  meetLiteral: '/pages/meet-literal',
  librarianInfo: `/pages/librarian`,
  author: '/author/[slug]',
  patrons: '/patrons',
  featureIdeas: '/feature-ideas',
  pagesAbout: '/about',
  pagesPartners: '/partners',
  wrapped: '/wrapup',
  wrappedById: '/wrapup/[profileId]',
  wrappedShare: '/wrapup/[profileId]/share',

  // Pages
  pagesFaq: '/pages/faq',
  pagesApi: '/pages/api',
} as const;

export const changeRouteKeepParams = (currentPath: string, newPath: string): string => {
  const hasQueries = currentPath.indexOf('?');
  const searchQuery = hasQueries >= 0 ? currentPath.substring(hasQueries) : '';
  const params = new URLSearchParams(searchQuery);
  const paramsStr = params.toString() !== '' ? `?${params.toString()}` : '';
  return `${newPath}${paramsStr}`;
};
