import {
  ApolloClient,
  ApolloLink,
  FieldMergeFunction,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { offsetLimitPagination } from '@apollo/client/utilities';
import * as Sentry from '@sentry/node';
import { SentryLink } from 'apollo-link-sentry';
import { createUploadLink } from 'apollo-upload-client';
import { deleteCookie } from 'hooks/useCookie';
import getConfig from 'next/config';
import { parseCookies } from 'nookies';
import { NextRequest } from 'types/next';
import { noop } from './noop';
import { sendRnMessage } from './reactNative';

const { publicRuntimeConfig } = getConfig();

const isBrowser = typeof document !== 'undefined';

let client: ApolloClient<NormalizedCacheObject>;

const getApolloClient = (req?: NextRequest): ApolloClient<NormalizedCacheObject> => {
  // Client-side we return the existing client.
  // Server-side we always create a new one
  if (isBrowser && client) {
    return client;
  }

  const sentryLink = new SentryLink({
    attachBreadcrumbs: {
      includeQuery: true,
      includeVariables: true,
      includeFetchResult: true,
      includeError: true,
    },
  });

  const uploadLink = createUploadLink({
    uri: publicRuntimeConfig.apiUrl,
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // Or from the request object server-side
    const ctx = req ? { req } : undefined;
    const { token } = parseCookies(ctx);
    // return the headers to the context so httpLink can read them
    const headersWithAuth = { ...headers };
    if (token) headersWithAuth.authorization = `Bearer ${token}`;
    return {
      headers: headersWithAuth,
    };
  });

  const errorLink = onError(({ graphQLErrors = [], networkError }) => {
    graphQLErrors
      .filter((error) => error.extensions?.code !== 'EXPECTED')
      .forEach((error) => {
        Sentry.captureException(`[GRAPHQL error]: ${JSON.stringify(error)}`);
      });

    if (networkError?.message) {
      Sentry.captureException(`[GRAPHQL network error]: ${JSON.stringify(networkError)}`);
    }

    const shouldBeLoggedOut = graphQLErrors.some(
      (error) => error.message === 'Not authorized' || error.message === 'Unauthenticated user'
    );

    if (shouldBeLoggedOut) {
      deleteCookie('token');
      sendRnMessage('LOGOUT');

      if (isBrowser && window.location.pathname !== '/signin') {
        window.location.replace('/signin');
      }
    }
  });

  const retryLink = new RetryLink({
    delay: {
      initial: 300,
      max: Infinity,
      jitter: true,
    },
    attempts: {
      max: 5,
      retryIf: (error, _operation) => {
        if (error?.code === 20 || error?.name === 'AbortError' || error?.name === 'ABORT_ERR') return false;
        return !!error;
      },
    },
  });

  const mutationDetectionLink = new ApolloLink((operation, forward) => {
    try {
      const isMutation = operation.query.definitions.some(
        (d) => 'operation' in d && d.operation == 'mutation'
      );
      if (isMutation) {
        sendRnMessage('MUTATION');
      }
    } catch (error) {
      noop();
    }
    return forward(operation);
  });

  client = new ApolloClient({
    link: ApolloLink.from([authLink, sentryLink, retryLink, errorLink, mutationDetectionLink, uploadLink]),
    ssrMode: !isBrowser,
    cache: new InMemoryCache({
      possibleTypes: {
        Activity: [
          'MomentActivity',
          'ReadingStateActivity',
          'BookReviewActivity',
          'BookOnShelfActivity',
          'BookInClubActivity',
          'ClubPostActivity',
          'CreateGoalActivity',
          'CompleteGoalActivity',
        ],
        Notification: [
          'FollowProfileNotification',
          'GoodReadsImportNotification',
          'LikeActivityNotification',
          'LikeClubPostNotification',
          'CommentActivityNotification',
          'CommentClubPostNotification',
          'DiscoveryNotification',
          'MentionNotification',
          'ClubPostNotification',
          'ClubReviewNotification',
          'ClubMomentNotification',
          'AddedToClubNotification',
          'ClubInviteNotification',
          'ClubInviteAcceptedNotification',
          'ClubPostMentionNotification',
          'GoalCompleteNotification',
          'GoalEndNotification',
          'ReviewMentionNotification',
          'MomentMentionNotification',
        ],
      },
      typePolicies: {
        Query: {
          fields: {
            aggregatedFeed: offsetLimitPagination(['profileId']),
            clubTimeline: offsetLimitPagination(['clubId']),
            followers: offsetLimitPagination(['profileId']),
            following: offsetLimitPagination(['profileId']),
            notifications: offsetLimitPagination(),
            myBooks: {
              ...offsetLimitPagination(),
              merge: customMergeFunction,
            },
            myBooksByReadingState: {
              ...offsetLimitPagination(['readingStatus']),
              merge: customMergeFunction,
            },
            myBooksByReadingStates: {
              ...offsetLimitPagination(['readingStatus']),
              merge: customMergeFunction,
            },
            booksByShelf: {
              ...offsetLimitPagination(['shelfSlug']),
              merge: customMergeFunction,
            },
            myReviews: {
              ...offsetLimitPagination(),
              merge: customMergeFunction,
            },
            myMoments: {
              ...offsetLimitPagination(),
              merge: customMergeFunction,
            },
            shelvesByBooksAndProfile: {
              ...offsetLimitPagination(),
              merge: customMergeFunction,
            },
            booksByReadingStateAndProfile: offsetLimitPagination(['profileId', 'readingStatus']),
            getShelvesByProfileId: offsetLimitPagination(['profileId']),
            getClubsByProfileId: offsetLimitPagination(['profileId']),
            getClubMemberships: { ...offsetLimitPagination(['clubId']) },
            getClubBooks: {
              ...offsetLimitPagination(['clubId']),
              merge: customMergeFunction,
            },
            getEditionsByBookId: offsetLimitPagination(['bookId', 'audiobook']),
            getClubInvites: {
              ...offsetLimitPagination(['clubId']),
            },
            bookRelatedReadingStatesByStatus: {
              ...offsetLimitPagination(['id', 'bookId', 'me', 'query', 'status']),
              merge: customMergeFunction,
            },
            booksByAuthor: offsetLimitPagination(['authorId']),
            recentBooksByProfileId: offsetLimitPagination(['profileId']),
            booksInGoal: { ...offsetLimitPagination(['participantId']), merge: customMergeFunction },
            getUserReviews: offsetLimitPagination(['profileId']),
            myReadingStatesWithWork: { ...offsetLimitPagination(['id']), merge: customMergeFunction },
            myGoalParticipations: offsetLimitPagination(['earliestEndDate', 'latestEndDate']),
            goalParticipations: offsetLimitPagination(['handle', 'earliestEndDate', 'latestEndDate']),
            getMyBookCollections: offsetLimitPagination(['profileId', 'bookId', 'query']),
            likesByTarget: offsetLimitPagination(['targetId', 'targetType']),
            posts: offsetLimitPagination(['clubId', 'sortBy']),
            chatRoomMembers: offsetLimitPagination(['chatRoomId', 'role']),
            chatMessages: {
              ...offsetLimitPagination(['chatRoomId', 'replyToId']),
              merge: customMergeFunction,
            },
            myChatRoomMemberships: {
              ...offsetLimitPagination(['status']),
            },
          },
        },
        ReadingState: {
          keyFields: ['profileId', 'bookId'],
        },
        ShelfBookProfile: {
          keyFields: ['slug', 'bookId'],
        },
        BookOnShelf: {
          keyFields: ['bookId', 'shelfId'],
        },
      },
    }),
  });

  return client;
};

const customMergeFunction: FieldMergeFunction = (existing, incoming, options) => {
  if (options.args?.offset === 0) {
    return [...incoming];
  }
  return [...(existing || []), ...incoming].filter(
    (item, index, array) => index === array.findIndex((t) => t.__ref === item.__ref)
  );
};

export default getApolloClient;
