import { Book } from 'generated/graphql';
import { useMemo } from 'react';
import { AssetSize } from '../types';

export function useCoverImage(book: Pick<Book, 'cover'>, assetSize: AssetSize): string | undefined {
  const url = useMemo(() => {
    if (!book.cover) return;
    let secureUrl = book.cover.replace('&edge=curl', '').replace('http:', 'https:');

    if (secureUrl.includes('assets.literal.club') || secureUrl.includes('assets-dev.literal.club')) {
      secureUrl += `?size=${assetSize}`;
    }

    return secureUrl;
  }, [book]);

  return url;
}
