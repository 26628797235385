import { IconProps } from './type';

export const PenIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.29282 13.2221L4.87868 18.8787L10.5355 17.4645L10.5354 17.4643L11.6665 16.3331L20.4347 7.56498C20.8252 7.17446 20.8252 6.54129 20.4347 6.15077L17.6062 3.32234C17.2157 2.93182 16.5825 2.93182 16.192 3.32234L7.42389 12.0905L6.29252 13.2218L6.29282 13.2221ZM10.5352 15.2017L8.55526 13.2218L16.8991 4.87798L18.879 6.85788L10.5352 15.2017Z"
      fill={color}
    />
  </svg>
);
