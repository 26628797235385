import { IconProps } from './type';

export const MedalIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 13L5.0002 20.3462L7.41924 19.0456L8.8639 21.3815L10.5004 15.5002"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.5 15.5001L15.0705 21.3814L16.5152 19.0455L18.9343 20.3461L17 13"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.8545 3.15432C11.9334 3.0706 12.0666 3.0706 12.1455 3.15432L13.6165 4.71431C13.6633 4.76391 13.7321 4.78627 13.7991 4.77364L15.9061 4.3762C16.0191 4.35487 16.1269 4.43312 16.1415 4.54726L16.4146 6.67394C16.4233 6.74156 16.4658 6.8001 16.5275 6.82925L18.4657 7.74618C18.5697 7.79539 18.6108 7.92201 18.5556 8.02296L17.5265 9.90401C17.4938 9.96382 17.4938 10.0362 17.5265 10.096L18.5556 11.977C18.6108 12.078 18.5697 12.2046 18.4657 12.2538L16.5275 13.1707C16.4658 13.1999 16.4233 13.2584 16.4146 13.3261L16.1415 15.4527C16.1269 15.5669 16.0191 15.6451 15.9061 15.6238L13.7991 15.2264C13.7321 15.2137 13.6633 15.2361 13.6165 15.2857L12.1455 16.8457C12.0666 16.9294 11.9334 16.9294 11.8545 16.8457L10.3835 15.2857C10.3367 15.2361 10.2679 15.2137 10.2009 15.2264L8.09393 15.6238C7.98085 15.6451 7.87314 15.5669 7.85849 15.4527L7.58538 13.3261C7.57669 13.2584 7.53416 13.1999 7.47253 13.1707L5.53433 12.2538C5.43031 12.2046 5.38917 12.078 5.4444 11.977L6.47349 10.096C6.50621 10.0362 6.50621 9.96382 6.47349 9.90401L5.4444 8.02296C5.38917 7.92201 5.43032 7.79539 5.53433 7.74618L7.47253 6.82925C7.53416 6.8001 7.57669 6.74156 7.58538 6.67394L7.85849 4.54726C7.87314 4.43312 7.98085 4.35487 8.09393 4.3762L10.2009 4.77364C10.2679 4.78627 10.3367 4.76391 10.3835 4.71431L11.8545 3.15432Z"
      stroke={color}
      strokeWidth="1.6"
    />
  </svg>
);
