import { BookPartsFragment } from 'generated/graphql';
import Link from 'next/link';
import { Cover } from 'ui/generic';
import styles from './Small.module.scss';

type BookProps = {
  book: BookPartsFragment;
};

type Props = BookProps & {
  withLink?: boolean;
  href?: string;
  as?: string;
};

const Inner = ({ book }: BookProps) => (
  <>
    <div className={styles.image}>
      <Cover book={book} />
    </div>
    <div>
      <h4>{book.title}</h4>
      <div className={styles.authors}>
        {book.authors && book.authors.map((author) => author.name).join(', ')}
      </div>
    </div>
  </>
);

const Small = ({ book, href, as, withLink = true }: Props): JSX.Element => {
  if (!withLink) {
    return (
      <div className={styles.book}>
        <Inner book={book} />
      </div>
    );
  }

  return (
    <Link href={href || `/book/[bookSlug]`} as={as || `/book/${book.slug}`}>
      <a className={styles.book}>
        <Inner book={book} />
      </a>
    </Link>
  );
};

export default Small;
