import { IconProps } from './type';

export const HighlightIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.36971 17.8535C4.23766 18.0822 4.227 18.3614 4.34122 18.5995C4.45543 18.8376 4.67981 19.004 4.94084 19.0442L11.3036 20.0235C11.6297 20.0737 11.9531 19.9185 12.1181 19.6328L13.5514 17.1503L13.7245 17.2502C14.0514 17.439 14.4658 17.3735 14.7186 17.0931L21.1381 9.97423C21.2998 9.7949 21.3719 9.55213 21.3342 9.31363C21.2965 9.07512 21.1531 8.86639 20.944 8.74566L10.7373 2.85283C10.7059 2.8347 10.6738 2.81894 10.6411 2.80549C10.5701 2.75058 10.4886 2.70678 10.3983 2.67751C9.97799 2.54129 9.52683 2.77159 9.39061 3.19189L6.43519 12.3108L6.23561 12.9266L6.79622 13.2503L6.96961 13.3504L4.36971 17.8535ZM8.4751 12.372C8.47093 12.3695 8.46673 12.367 8.4625 12.3646C8.45826 12.3622 8.45402 12.3598 8.44976 12.3574L8.15683 12.1883L10.6079 4.62563L19.2878 9.63697L13.9639 15.5409L13.6674 15.3697C13.6645 15.368 13.6615 15.3663 13.6586 15.3646C13.6556 15.3629 13.6527 15.3612 13.6497 15.3595L11.0604 13.8646L11.0513 13.8594L8.4751 12.372ZM8.35525 14.1504L6.34009 17.6407L11.0061 18.3588L12.1657 16.3503L10.2694 15.2554L10.2603 15.2502L8.35525 14.1504Z"
      fill={color}
    />
  </svg>
);
