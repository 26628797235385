import { IconProps } from './type';

export const AudiobookIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 9.40269C18.7942 5.44579 16.3826 1.48888 11.5595 2.05415C6.73633 2.61943 3.72186 7.70689 5.53055 11.6638C7.33923 15.6207 9.82315 16.1318 10.3536 18.4471C10.9746 21.1574 12.7652 22.4847 15.1768 21.8387C17.5884 21.1927 18.9951 18.4471 18.7941 17.3165C17.7893 16.7512 16 15 17.5 13"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M10.4041 12C8.43425 10.268 8.43438 7.1502 11.0606 6.22648C12.7021 5.64916 14.3435 6.22651 15.0001 7.38119C15.8334 8.58746 17.5 11.4 17.5 13"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M16.4999 15C15.5 15 14.5 13 16.9999 12.5"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </svg>
);
