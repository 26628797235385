import classNames from 'classnames';
import { ReactNode, useEffect, useRef, useState } from 'react';
import styles from './Collapsable.module.scss';

type Props = {
  children: ReactNode;
  openText?: string;
  closeText?: string;
  lines?: number;
  additionalContent?: ReactNode;
  lineHeight?: number;
};

export const Collapsable = ({
  children,
  additionalContent,
  openText = 'More',
  closeText = 'Less',
  lines = 6,
  lineHeight = 20.3,
}: Props): JSX.Element => {
  const [isExpanded, setExpanded] = useState(false);
  const [isButtonNeeded, setIsButtonNeeded] = useState(false);
  const element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if ((element.current && element.current.scrollHeight - 1 > lines * lineHeight) || additionalContent) {
      setIsButtonNeeded(true);
    }
  }, [element.current]);

  const buttonTopPosition =
    element.current && element.current.scrollHeight < (lines + 1) * lineHeight
      ? null
      : lines * lineHeight - lineHeight - 3;

  return (
    <div className={styles.outer}>
      <div
        className={classNames(styles.container, {
          [styles.limited]: !isExpanded,
        })}
        style={{ ['--lines' as string]: lines }}
        ref={element}
      >
        {children}
        {isExpanded && additionalContent}
      </div>
      {isButtonNeeded && !isExpanded && (
        <button
          style={buttonTopPosition ? { position: 'absolute', top: buttonTopPosition, right: 15 } : {}}
          className={classNames(styles.button, { [styles.noFade]: !buttonTopPosition })}
          onClick={() => setExpanded((state) => !state)}
        >
          {isExpanded ? closeText : openText}
          {!(!closeText && isExpanded)}
        </button>
      )}
    </div>
  );
};
