/**
 * Keep alive component
 * https://stackoverflow.com/questions/59124463/nextjs-how-to-not-unmount-previous-page-when-going-to-next-page-to-keep-state
 *
 * Tried following libraries before implementing this one:
 * - react-keep-alive: has a lot of dom manipulation bugs and crashes our app
 * - react-activation: does not handle SSR correctly
 * - there are some others but they are built on react-router, which next.js doesn't use
 *
 * Basic Usage:
 * 1) Wrap nextjs <Component /> with <KeepAliveProvider> in _app.tsx
 * 2) Wrap components export with "withKeepAlive" and provide unique name like this: export default withKeepAlive(IndexPage, 'index');
 */
import { NextRouter } from 'next/router'; // eslint-disable-line import/no-extraneous-dependencies
import {
  cloneElement,
  EffectCallback,
  Fragment,
  memo,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';

type KeepAliveCacheType = {
  [name: string]: {
    Component: any;
    pageProps: any;
    name: string;
    enabled?: boolean;
  };
};

type KeepAliveNameFnArgs = {
  props: any;
  router: NextRouter;
};

export type KeepAliveName = string | ((nameArgs: KeepAliveNameFnArgs) => string);

export type KeepAliveOptsProps = {
  keepScrollEnabled?: boolean;
  applyNewProps?: boolean | ((cachedProps: any, newProps: any) => Object);
};

type KeepAliveData = {
  name: KeepAliveName;
} & KeepAliveOptsProps;

type ExtendChildrenType = {
  type: {
    keepAlive: KeepAliveData;
  };
};

type KeepAliveProviderProps = {
  children: ReactElement & ExtendChildrenType;
  router: NextRouter;
};

const defaultEnabled = true;

// const whitelisted: string[] = [routes.landing, routes.clubs, routes.explore, routes.search, routes.library];
const whitelisted: string[] = [];

const KeepAliveProvider = (props: KeepAliveProviderProps) => {
  const { children, router } = props;

  const pageProps = children?.props;
  const componentData = cloneElement(children);
  const CurrentComponent = componentData?.type;
  const keepAliveCache = useRef<KeepAliveCacheType>({});
  const [, forceUpdate] = useState<any>();

  // KeepAlive name
  const keepAliveName = router.pathname;
  const isEnabled = () => keepAliveCache?.current?.[keepAliveName]?.enabled;
  const isKeptAlive = whitelisted.includes(router.pathname);

  // Add Component to retainedComponents if we haven't got it already
  if (isKeptAlive && !keepAliveCache.current[keepAliveName]) {
    const Component: any = componentData?.type;
    const MemoComponent = memo(Component);
    keepAliveCache.current[keepAliveName] = {
      Component: MemoComponent,
      pageProps,
      name: keepAliveName,
      enabled: defaultEnabled,
    };
  }

  // Save the scroll position of current page before leaving
  const handleRouteChangeStart = () => {
    const key = '__literal_scroll_' + router.asPath;
    const payload = JSON.stringify({ x: self.pageXOffset, y: self.pageYOffset });
    sessionStorage.setItem(key, payload);
  };

  // Restore the scroll position of cached page
  const handleRouteChangeComplete = (newUrl: string) => {
    const key = '__literal_scroll_' + newUrl;

    const stringData = sessionStorage.getItem(key);
    const position = stringData ? (JSON.parse(stringData) as { x: number; y: number }) : { x: 0, y: 0 };

    window.scrollTo(0, position.y || 0);
    // Just in case try again in next event loop
    setTimeout(() => {
      window.scrollTo(0, position.y || 0);
    }, 0);
  };

  // Enable/disable loading from cache
  const handleLoadFromCache = (event: any) => {
    const { name: controlsName, enabled: controlsEnabled } = event?.detail || {};

    if (keepAliveCache.current[controlsName]) {
      keepAliveCache.current[controlsName].enabled = controlsEnabled;
    }
  };

  // Drop cache
  const handleDropCache = (event: any) => {
    const { name: dropKeepAliveName, scrollToTop } = event?.detail || {};

    // If no name, drop all cache
    if (!dropKeepAliveName) {
      keepAliveCache.current = {};
    } else if (typeof dropKeepAliveName === 'string') {
      delete keepAliveCache.current?.[dropKeepAliveName];
    } else if (typeof dropKeepAliveName === 'function') {
      const caches = dropKeepAliveName?.(Object.keys(keepAliveCache.current));
      const cachesToRemove: string[] = Array.isArray(caches) ? caches : [caches];

      // eslint-disable-next-line no-unused-expressions
      cachesToRemove
        ?.filter((exists) => exists)
        ?.forEach((cacheName) => delete keepAliveCache.current?.[cacheName]);
    }

    if (scrollToTop && typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }

    forceUpdate({});
  };

  // On back
  useEffect(() => {
    router.beforePopState(() => {
      const key = '__literal_scroll_' + router.asPath;
      setTimeout(() => {
        sessionStorage.removeItem(key);
      });
      return true;
    });

    return () => {
      router.beforePopState(() => true);
    };
  }, []);

  // Handle scroll position caching - requires an up-to-date router.asPath
  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router.asPath]);

  // Emit mounting events
  useEffect(() => {
    if (isKeptAlive) {
      window.dispatchEvent(
        new CustomEvent('onKeepAliveMount', {
          detail: keepAliveName,
        })
      );

      return () => {
        window.dispatchEvent(
          new CustomEvent('onKeepAliveUnmount', {
            detail: keepAliveName,
          })
        );
      };
    }
  }, [CurrentComponent, pageProps]);

  /**
   * Listen to changes (enabled/disabled)
   */
  useEffect(() => {
    window.addEventListener('keepAliveControls_LoadFromCache', handleLoadFromCache);
    window.addEventListener('keepAliveControls_DropCache', handleDropCache);

    return () => {
      window.removeEventListener('keepAliveControls_LoadFromCache', handleLoadFromCache);
      window.removeEventListener('keepAliveControls_DropCache', handleDropCache);
    };
  }, []);

  const getCachedViewProps = (cachedProps: Object) => {
    // Apply cached props
    return cachedProps;
  };

  /**
   * Custom useEffect which runs only when component alive.
   */
  const getKeepAliveEffect = (isHidden: boolean) => {
    const useKeepAliveEffect = (effect: EffectCallback, deps?: any[]) =>
      useEffect(() => {
        if (!isHidden) {
          return effect();
        }
      }, deps);

    return useKeepAliveEffect;
  };

  return (
    // eslint-disable-next-line react/jsx-fragments
    <Fragment>
      {(!isKeptAlive || !isEnabled()) && children}

      <div
        style={{ display: isKeptAlive && isEnabled() ? 'block' : 'none' }}
        id="keep-alive-container"
        data-keepalivecontainer={true}
      >
        {Object.values(keepAliveCache.current).map(
          ({ Component, pageProps: cachedProps, name: cacheName }) => (
            <div
              key={cacheName}
              style={{ display: keepAliveName === cacheName ? 'block' : 'none' }}
              data-keepalive={cacheName}
              data-keepalive-hidden={keepAliveName !== cacheName}
            >
              <Component
                isHiddenByKeepAlive={keepAliveName !== cacheName}
                useEffect={getKeepAliveEffect(keepAliveName !== cacheName)}
                {...getCachedViewProps(cachedProps)}
              />
            </div>
          )
        )}
      </div>
    </Fragment>
  );
};

export default KeepAliveProvider;
