import { IconProps } from './type';

export const CloseIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 19L19 5" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <path d="M5 5L19 19" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
  </svg>
);

export const CloseDisplayIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.6665 25.3337L25.3332 6.66699" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <path d="M6.66683 6.66699L25.3335 25.3337" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
  </svg>
);
