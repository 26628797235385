import constate from 'constate';
import { EventMap } from 'containers/ChatNew/types';
import { useNotificationCount } from 'containers/ChatNew/useNotificationCount';
import { useUnreadClubsPostCountQuery } from 'generated/graphql';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import { io, Socket } from 'socket.io-client';
import { NewChatMessagePayload, SocketEvent } from 'types/sockets';
import { routes } from 'utils/routes';
import { useAuthContext } from './useAuth';
import { useBrowserNotification } from './useBrowserNotification';
import { useNotificationsContext } from './useNotifications';

const { publicRuntimeConfig } = getConfig();

type State = {
  send: (key: SocketEvent) => void;
};

export function useSockets(): State {
  const { refetchNotificationsCount } = useNotificationsContext();
  const { token, isSignedIn } = useAuthContext();
  const { refetch: refetchTotalUnreadCount } = useUnreadClubsPostCountQuery({ skip: true });

  const router = useRouter();
  const { profile } = useAuthContext();
  const { notify } = useBrowserNotification();
  const { fetchUnseen } = useNotificationCount();

  const socket = useRef<Socket<EventMap>>();

  // React garbage
  const profileRef = useRef<typeof profile>();
  useEffect(() => {
    profileRef.current = profile;
  }, [profile]);

  const pathRef = useRef<string>();
  useEffect(() => {
    pathRef.current = router.pathname;
  }, [router.pathname]);

  // On mount
  useEffect(() => {
    if (!token || !publicRuntimeConfig.socketUrl) return;

    socket.current = io(publicRuntimeConfig.socketUrl, {
      path: getSocketPath(),
      extraHeaders: { Authorization: `Bearer ${token}` },
      withCredentials: true,
    });

    socket.current.on('NEW_ACTIVITY', () => {
      refetchNotificationsCount();
      refetchTotalUnreadCount();
    });

    socket.current.on('REFETCH_NOTIFICATIONS', () => {
      // Delay so that the notifications can be unread in stream
      setTimeout(() => {
        refetchNotificationsCount();
      }, 1000);
    });

    socket.current.on('NEW_CHAT_MESSAGE', (data: NewChatMessagePayload) => {
      setTimeout(() => {
        if (profileRef.current) {
          fetchUnseen();
        }

        if (pathRef.current === routes.chat) return;
        if (data.senderId === profileRef.current?.id) return;

        notify({
          title: 'Literal | New message from ' + data.senderName,
          body: data.message || '',
          tag: `chat-message-${data.channelUrl}-${data.messageId}`,
          onClick: () => {
            router.push(routes.chat, `${routes.chat}?channel=${data.channelUrl}`);
          },
        });
      }, 1000);
    });

    socket.current.onAny((name, data) => {
      if (data) log(name, data);
      else log(name);
    });

    socket.current.on('connect', () => {
      socket.current?.emit('PING');
    });

    return () => {
      socket.current?.disconnect();
    };
  }, [token]);

  function send(type: SocketEvent, payload?: any) {
    socket.current?.emit(type, payload);
  }

  return { send };
}

const [SocketsProvider, useSocketsContext] = constate(useSockets);

export { SocketsProvider, useSocketsContext };

function log(...message: string[]) {
  console.log(
    '%c[RECEIVED SOCKETS MESSAGE]:',
    'background-color: #278458; color: var(--uiWhite)',
    ...message
  );
}

export function getSocketPath() {
  const newUrl = new URL(publicRuntimeConfig.socketUrl);
  const path = newUrl.pathname === '/' ? '/socket.io' : newUrl.pathname + '/socket.io';
  return path;
}
