import axios from 'axios';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export async function uploadImage(buffer: File, dir: string, token: string): Promise<string> {
  const base = publicRuntimeConfig.assetsServiceUrl;

  try {
    const url = `${base}/api/user/upload/${dir}`;

    const formData = new FormData();
    formData.append('image', buffer);

    const result = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    });

    return result.data.url;
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      throw `${error.code} ${error.message}`;
    }

    throw error;
  }
}
