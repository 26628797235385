import { IconProps } from './type';

export const SettingsIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5061 4.36797C10.7439 2.7036 13.1469 2.70361 13.3847 4.36797V4.36797C13.549 5.51808 14.9292 6.02043 15.7944 5.24503V5.24503C17.0464 4.12292 18.8872 5.66754 17.9996 7.09538V7.09538C17.3862 8.08204 18.1206 9.35405 19.2817 9.31617V9.31617C20.9621 9.26136 21.3794 11.6279 19.7816 12.1511V12.1511C18.6775 12.5126 18.4225 13.9591 19.3363 14.6765V14.6765C20.6588 15.7146 19.4573 17.7957 17.897 17.1694V17.1694C16.8188 16.7367 15.6936 17.6808 15.9326 18.8178V18.8178C16.2784 20.4631 14.0203 21.285 13.2276 19.8023V19.8023C12.6798 18.7778 11.211 18.7778 10.6632 19.8023V19.8023C9.87048 21.285 7.6124 20.4631 7.95817 18.8178V18.8178C8.19711 17.6808 7.07195 16.7367 5.99376 17.1694V17.1694C4.43347 17.7957 3.23197 15.7146 4.55445 14.6765V14.6765C5.4683 13.9591 5.21325 12.5126 4.10915 12.1511V12.1511C2.51137 11.6279 2.92864 9.26136 4.60902 9.31617V9.31617C5.77019 9.35405 6.50458 8.08204 5.8912 7.09538V7.09538C5.00354 5.66754 6.84435 4.12292 8.09636 5.24503V5.24503C8.96152 6.02043 10.3417 5.51808 10.5061 4.36797V4.36797Z"
        stroke={color}
        strokeWidth="1.6"
      />
      <circle cx="12.1527" cy="11.9264" r="3.35" stroke={color} strokeWidth="1.6" />
    </svg>
  );
};
