import { IconProps } from './type';

export const TrendingIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 19L9.07916 10.9097L13.3565 13.3502L18.9236 4.69628M18.9236 4.69628L14.337 5.64058M18.9236 4.69628L20.3991 9.14058"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </svg>
);
