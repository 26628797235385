import { utcToLocal } from 'date-utils';
import { RelativeDate } from 'ui/generic';
import styles from './NotificationTime.module.scss';

type Props = {
  time?: string | null;
};

export const NotificationTime = ({ time }: Props): JSX.Element => {
  return <span className={styles.date}>{time && <RelativeDate date={utcToLocal(time)} />}</span>;
};
