import { IconProps } from './type';

export const ReadingIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 6C7 5.44771 7.44772 5 8 5H16C16.5523 5 17 5.44772 17 6V18.3333L12 15L7 18.3333V6Z"
      stroke={color}
      strokeWidth="1.6"
    />
  </svg>
);
