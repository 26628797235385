import { useRouter } from 'next/router';
import { routes } from 'utils/routes';
import { MobileTopBar } from './MobileTopBar';

export const MobileTopBarWithBlacklist = (): JSX.Element | null => {
  const router = useRouter();

  const blacklist: string[] = [
    routes.landing,
    routes.search,
    routes.clubs,
    routes.library,
    routes.appPublic,
    routes.appPublicWaiting,
    routes.appPublicWaitlist,
  ];

  if (
    blacklist.includes(router.pathname) ||
    router.pathname.includes(routes.search) ||
    router.pathname.includes(routes.library)
  ) {
    return null;
  }

  return <MobileTopBar />;
};
