import { useAuthContext } from 'hooks/useAuth';
import { useScreenSize } from 'hooks/useScreenSize';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Button, ButtonSimple, LinkSimple } from 'ui/generic';
import { routes } from 'utils/routes';
import styles from './Footer.module.scss';

const Footer = () => {
  const router = useRouter();
  const { isSignedIn } = useAuthContext();
  const { smScreen } = useScreenSize();

  return (
    <>
      {!isSignedIn && router.pathname !== routes.landing && router.pathname !== routes.globalBook ? (
        <div className={styles.banner}>
          <div className={styles.bannerInner}>
            <h2 className="displayHeader mb-6">Join the club</h2>
            <p className="mb-8">
              Track what you're reading, see what your friends are into and explore the ideas of the world's
              greatest authors together.
            </p>
            {smScreen ? (
              <>
                <div className={styles.apps}>
                  <a
                    href="https://apps.apple.com/app/literal-club/id1566119883"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src="/img/app-store-logo.png" alt="Download on the App Store" loading="lazy" />
                  </a>

                  <a
                    href="https://play.google.com/store/apps/details?id=com.literal"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/img/play-store-logo.png"
                      alt="Download on the Google Play Store"
                      loading="lazy"
                    />
                  </a>
                </div>
                <div className="mt-6">
                  ... or{' '}
                  <Link href={routes.register}>
                    <a>
                      <ButtonSimple variant="fadedUnderline">sign up right here</ButtonSimple>
                    </a>
                  </Link>
                </div>
              </>
            ) : (
              <Link href={routes.register}>
                <a>
                  <Button variant="focus">Sign up for free</Button>
                </a>
              </Link>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.divider} />
      )}

      <footer className={styles.footer}>
        <div className={styles.row}>
          <div className={styles.column}>
            <div className={styles.label}>Company</div>
            <ul>
              <li>
                <LinkSimple variant="faded" href="/about">
                  About
                </LinkSimple>
              </li>
              <li>
                <LinkSimple variant="faded" href="/partners">
                  Partners
                </LinkSimple>
              </li>
              <li>
                <LinkSimple variant="faded" href="/pages/careers" asHtmlAnchor>
                  Careers
                </LinkSimple>
              </li>
              <li>
                <LinkSimple variant="faded" href="/pages/press" asHtmlAnchor>
                  Press
                </LinkSimple>
              </li>
              <li>
                <LinkSimple variant="faded" href="/pages/legal" asHtmlAnchor>
                  Legal
                </LinkSimple>
              </li>
              <li>
                <LinkSimple variant="faded" href="/pages/privacy-policy" asHtmlAnchor>
                  Privacy
                </LinkSimple>
              </li>
            </ul>
          </div>
          <div className={styles.column}>
            <div className={styles.label}>Social</div>
            <ul>
              <li>
                <LinkSimple
                  variant="faded"
                  href="https://twitter.com/literalclub"
                  rel="noreferrer"
                  target="_blank"
                >
                  Twitter
                </LinkSimple>
              </li>
              <li>
                <LinkSimple
                  variant="faded"
                  href="https://www.instagram.com/literalclub/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Instagram
                </LinkSimple>
              </li>
              <li>
                <LinkSimple
                  variant="faded"
                  href="https://www.facebook.com/literalclub"
                  target="_blank"
                  rel="noreferrer"
                >
                  Facebook
                </LinkSimple>
              </li>
              <li>
                <LinkSimple
                  variant="faded"
                  href="https://tiktok.com/@literalclub"
                  target="_blank"
                  rel="noreferrer"
                >
                  TikTok
                </LinkSimple>
              </li>
            </ul>
          </div>
          <div className={styles.column}>
            <div className={styles.label}>Support</div>
            <ul>
              <li>
                <LinkSimple variant="faded" href="/pages/faq" asHtmlAnchor>
                  FAQ
                </LinkSimple>
              </li>
              <li>
                <LinkSimple variant="faded" href="/changelog" asHtmlAnchor>
                  Changelog
                </LinkSimple>
              </li>
              <li>
                <LinkSimple variant="faded" href="/feature-ideas">
                  Feature ideas
                </LinkSimple>
              </li>
              <li>
                <LinkSimple variant="faded" href="/pages/api" asHtmlAnchor>
                  API
                </LinkSimple>
              </li>
              <li>
                <LinkSimple variant="faded" href="/pages/widget" asHtmlAnchor>
                  Widget
                </LinkSimple>
              </li>
              <li>
                <LinkSimple variant="faded" href="/pages/contact" asHtmlAnchor>
                  Contact
                </LinkSimple>
              </li>
            </ul>
          </div>
          <div className={styles.column}>
            <div className={styles.label}>App</div>
            <ul>
              <li>
                <LinkSimple
                  variant="faded"
                  href="https://apps.apple.com/app/literal-club/id1566119883"
                  target="_blank"
                  rel="noreferrer"
                >
                  iPhone app
                </LinkSimple>
              </li>
              <li>
                <LinkSimple
                  variant="faded"
                  href="https://play.google.com/store/apps/details?id=com.literal"
                  target="_blank"
                  rel="noreferrer"
                >
                  Android app
                </LinkSimple>
              </li>
            </ul>
          </div>

          <div className={styles.column}>
            <div className={styles.label}>Help us</div>
            <ul>
              <li>
                <LinkSimple variant="faded" href={routes.patrons}>
                  Become a Patron
                </LinkSimple>
              </li>
              <li>
                <LinkSimple variant="faded" href={routes.librarianInfo}>
                  Become a Librarian
                </LinkSimple>
              </li>
            </ul>
          </div>
          <div className={styles.mark}>
            <svg width="27" height="37" viewBox="0 0 27 37" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.724766 22.39L12.7927 23.9998L13.2765 20.3731L4.83516 19.2471L7.27067 0.988923L3.64404 0.505157L0.724766 22.39Z"
                fill="var(--uiBlack)"
              />
              <path
                d="M1.94385 32.2403L25.2972 28.4698L25.8772 32.0626L2.52392 35.8331L1.94385 32.2403Z"
                fill="var(--uiBlack)"
              />
              <path
                d="M23.688 15.0347L21.926 10.7233L20.2633 6.58783L18.4643 2.11073L16.068 3.09655L17.8249 7.43902L19.4666 11.5072L21.2917 16.0205L23.688 15.0347Z"
                fill="var(--uiBlack)"
              />
              <path
                d="M14.3785 13.5268L17.4113 9.9921L20.2943 6.59286L23.4146 2.91253L25.3773 4.60434L22.3394 8.17022L19.4976 11.5122L16.3411 15.2187L14.3785 13.5268Z"
                fill="var(--uiBlack)"
              />
              <path
                d="M17.4113 9.99212L12.9278 9.26595L13.3413 6.71286L17.8559 7.44407L22.3394 8.17024L26.8229 8.8964L26.4094 11.4495L21.9882 10.7334L17.4113 9.99212Z"
                fill="var(--uiBlack)"
              />
              <defs>
                <clipPath id="clip0">
                  <rect width="26.6885" height="37" fill="white" transform="translate(0.311523)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
