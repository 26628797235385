import { Fragment, ReactNode } from 'react';
import Button from './Button';
import styles from './EmptyState.module.scss';

/**
 * Some rules around empty states:
 * - Use the `text` prop to explain what the user would see if they had data, while providing constructive guidance about next steps
 * - Use the `actions` prop to help add missing data.
 */

type EmptyStateAction =
  | {
      text?: string;
      onClick?: () => void;
    }
  | {
      button?: ReactNode;
    };

export type EmptyStateConfig = {
  text?: string;
  illustrationKey?: string;
  actions?: Array<EmptyStateAction>;
  margin?: Margin;
};

export const EmptyState = ({ text, illustrationKey, actions, margin }: EmptyStateConfig) => {
  return (
    <div className={styles.container} style={getMarginStyle(margin)}>
      <div className={styles.inner}>
        {text && (
          <p className={styles.text}>
            {text.split('\n').map((t, i) => (
              <Fragment key={i}>
                {t} <br />
              </Fragment>
            ))}
          </p>
        )}
        {illustrationKey && (
          <img className={styles.illustration} src={`/img/illustrations/${illustrationKey}.svg`} alt="" />
        )}
        {actions && (
          <div className={styles.buttons}>
            {actions.map((action) => {
              if ('onClick' in action) {
                return (
                  <Button key={action.text} mini variant="tertiary" onClick={action.onClick}>
                    {action.text}
                  </Button>
                );
              } else if ('button' in action) {
                return action.button;
              }
            })}
          </div>
        )}
      </div>
    </div>
  );
};

type Margin =
  | string
  | {
      '0': string;
      '600'?: string;
      '900'?: string;
      '1120'?: string;
    };

const getMarginStyle = (margin?: Margin) => {
  const styles: Record<string, string> = {};
  if (!margin) return {};
  if (typeof margin === 'string') {
    styles['--margin-prop'] = margins(margin);
  } else {
    styles['--margin-prop'] = margins(margin['0']);
  }
  styles['--margin-prop-600'] = margin['600'] ? margins(margin['600']) : 'var(--margin-prop)';
  styles['--margin-prop-900'] = margin['900'] ? margins(margin['900']) : 'var(--margin-prop-600)';
  styles['--margin-prop-1120'] = margin['1120'] ? margins(margin['1120']) : 'var(--margin-prop-900)';
  return styles;
};

const margins = (margin: string) => {
  const sides = margin.split(' ');
  if (sides.length === 0) return '0';
  return sides.map(convert).join(' ');
};

const convert = (side: string) => {
  const number = parseInt(side);
  return number ? `var(--size-${number})` : '0';
};
