import { useEffect, RefObject } from 'react';

type HandleArrowKeysProps = {
  event: KeyboardEvent;
  currentIndex: number;
  availableElements: NodeListOf<HTMLElement>;
};
function handleArrowKey({ event, currentIndex, availableElements }: HandleArrowKeysProps) {
  // If the focus isn't in the container, focus on the first thing
  if (currentIndex === -1) availableElements[0].focus();

  // Move the focus up or down
  let nextElement;
  if (event.key === 'ArrowDown') {
    nextElement = availableElements[currentIndex + 1];
  }

  if (event.key === 'ArrowUp') {
    nextElement = availableElements[currentIndex - 1];
  }

  nextElement && nextElement.focus();
  event.preventDefault();
}

/**
 * Implement arrow key navigation for the given parentNode
 * @param {object}  options
 * @param {Event}   options.e          Keydown event
 * @param {DOMNode} options.parentNode The parent node to operate on. Arrow keys won't navigate outside of this node
 * @param {String}  options.selectors  Selectors for elements we want to be able to key through
 */
type HandleEventsProps = {
  event: KeyboardEvent;
  parentNode: HTMLElement | null;
  selectors?: string;
};
function handleEvents({ event, parentNode, selectors = 'a,button,input' }: HandleEventsProps) {
  if (!parentNode) return;

  const key = event.key;
  if (!['ArrowUp', 'ArrowDown', 'Enter'].includes(key)) {
    return;
  }

  let activeElement: HTMLElement | null = null;
  if (document.activeElement instanceof HTMLElement) {
    activeElement = document.activeElement;
  }

  // If we're not inside the container, don't do anything
  if (!parentNode.contains(activeElement)) return;

  // Get the list of elements we're allowed to scroll through
  const availableElements = parentNode.querySelectorAll<HTMLElement>(selectors);

  // No elements are available to loop through.
  if (!availableElements.length) return;

  // Which index is currently selected
  const currentIndex = Array.from(availableElements).findIndex(
    (availableElement) => availableElement === activeElement
  );

  if (key === 'ArrowDown' || key === 'ArrowUp') {
    handleArrowKey({ event, currentIndex, availableElements });
  }
}

/**
 * A react hook to enable arrow key navigation on a component.
 * @returns a useRef, which can be applied to a component
 */
type Props<T> = {
  refObject: RefObject<T>;
  selectors: string;
};
const useArrowKeyNavigation = <T>(props: Props<T>): void => {
  const { selectors, refObject } = props;

  useEffect(() => {
    const eventHandler = (event: KeyboardEvent) => {
      if (refObject.current instanceof HTMLElement) {
        handleEvents({ event, parentNode: refObject.current, selectors });
      }
    };
    document.addEventListener('keydown', eventHandler);
    return () => document.removeEventListener('keydown', eventHandler);
  }, []);
};

export default useArrowKeyNavigation;
