import classNames from 'classnames';
import throttle from 'lodash.throttle';
import { ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { DotIcon, LeftIcon, RightIcon } from '../icons';
import { ButtonSimple } from './ButtonSimple';
import styles from './VerticalScrollList.module.scss';

type Props = {
  items: ReactNode[];
  gap?: number;
  withControls?: boolean;
  mobilePadding?: boolean;
  containerWidth?: boolean;
  safeCenter?: boolean;
};

type State = {
  viewWidth: number;
  pagesCount: number;
  currentPage: number;
};

export const VerticalScrollList = ({
  items,
  gap,
  withControls,
  containerWidth,
  mobilePadding,
  safeCenter,
}: Props) => {
  const [state, setState] = useState<State | null>(null);

  const viewRef = useRef<HTMLDivElement>(null);
  const trackRef = useRef<HTMLDivElement>(null);

  useGrabToScroll(trackRef.current);

  const calculateState = () => {
    if (!viewRef.current || !trackRef.current) return;
    const viewWidth = viewRef.current.clientWidth;
    const trackWidth = trackRef.current.scrollWidth;
    const pagesCount = Math.ceil(Math.round((trackWidth / viewWidth) * 10) / 10);
    const scrollPositionEnd = trackRef.current.scrollLeft + viewWidth;
    const currentPage = Math.ceil(scrollPositionEnd / viewWidth);
    setState({ pagesCount, currentPage, viewWidth });
  };
  const throttledCalculateState = throttle(calculateState, 60);

  useEffect(() => {
    throttledCalculateState();

    trackRef.current?.addEventListener('scroll', throttledCalculateState);
    window.addEventListener('resize', throttledCalculateState);

    return () => {
      trackRef.current?.removeEventListener('scroll', throttledCalculateState);
      window.removeEventListener('resize', throttledCalculateState);
    };
  }, [trackRef.current]);

  const canBeCentered = useCanBeCentered(viewRef, trackRef);

  return (
    <>
      <div className={styles.view} ref={viewRef}>
        <div
          className={classNames(styles.track, {
            [styles.containerWidth]: containerWidth,
            [styles.mobilePadding]: mobilePadding,
            [styles.snap]: isTouchScreen(),
            [styles.center]: safeCenter && canBeCentered,
          })}
          ref={trackRef}
          style={{ gap: `var(--size-${gap || 4})` }}
        >
          {items}
        </div>
      </div>
      {withControls && state && state.pagesCount > 1 && (
        <div className={classNames(styles.controls, { [styles.containerWidth]: containerWidth })}>
          <div className={styles.dots}>
            {Array.from(Array(state.pagesCount).keys()).map((index) => (
              <div
                onClick={() =>
                  trackRef.current?.scroll({
                    left: index * state.viewWidth,
                    behavior: 'smooth',
                  })
                }
              >
                <DotIcon
                  color={state.currentPage === index + 1 ? 'var(--textPrimary)' : 'var(--uiOutline)'}
                />
              </div>
            ))}
          </div>
          <div className={styles.arrowButtons}>
            <ButtonSimple
              variant="faded"
              onClick={() => trackRef.current?.scrollBy({ left: -1 * state.viewWidth, behavior: 'smooth' })}
            >
              <LeftIcon />
            </ButtonSimple>
            <ButtonSimple
              variant="faded"
              onClick={() => trackRef.current?.scrollBy({ left: state.viewWidth, behavior: 'smooth' })}
            >
              <RightIcon />
            </ButtonSimple>
          </div>
        </div>
      )}
    </>
  );
};

const useGrabToScroll = (track: HTMLDivElement | null) => {
  if (isTouchScreen()) return;

  const isDown = useRef(false);
  const isDragging = useRef(false);
  const startX = useRef<number>(0);
  const scrollLeft = useRef<number>(0);
  const velX = useRef<number>(0);
  const momentumId = useRef<number | null>(null);

  const handleMouseDown = (e: MouseEvent) => {
    if (!track) return;
    isDown.current = true;
    track.classList.add(styles.active);
    startX.current = e.pageX - track.offsetLeft;
    scrollLeft.current = track.scrollLeft;
    cancelMomentumTracking();
  };
  const handleMouseLeave = () => {
    if (!track) return;
    isDown.current = false;
    track.classList.remove(styles.active);
  };
  const handleMouseMove = (e: MouseEvent) => {
    if (!track) return;
    if (!isDown.current) return;
    e.preventDefault();
    isDragging.current = true;
    const x = e.pageX - track.offsetLeft;
    const walk = (x - startX.current) * 1; //scroll-fast
    const prevScrollLeft = track.scrollLeft;
    track.scrollLeft = scrollLeft.current - walk;
    velX.current = track.scrollLeft - prevScrollLeft;
  };
  const handleClick = (e: MouseEvent) => {
    // Prevent link when dragging
    if (isDragging.current) {
      e.preventDefault();
      e.stopPropagation();
      isDragging.current = false;
      return false;
    }
  };
  const handleMouseUp = () => {
    if (!track) return;
    isDown.current = false;
    track.classList.remove(styles.active);
    beginMomentumTracking();
  };

  // Momentum
  const beginMomentumTracking = () => {
    cancelMomentumTracking();
    momentumId.current = requestAnimationFrame(momentumLoop);
  };
  const cancelMomentumTracking = () => {
    if (momentumId.current) {
      cancelAnimationFrame(momentumId.current);
    }
  };
  const momentumLoop = () => {
    if (!track) return;
    track.scrollLeft += velX.current;
    velX.current *= 0.95;
    if (Math.abs(velX.current) > 0.5) {
      momentumId.current = requestAnimationFrame(momentumLoop);
    }
  };

  useEffect(() => {
    if (!track) return;

    track.addEventListener('mousedown', handleMouseDown);
    track.addEventListener('mouseleave', handleMouseLeave);
    track.addEventListener('mouseup', handleMouseUp);
    track.addEventListener('click', handleClick);
    track.addEventListener('mousemove', handleMouseMove);
    track.addEventListener('wheel', cancelMomentumTracking);

    return () => {
      if (!track) return;
      track.removeEventListener('mousedown', handleMouseDown);
      track.removeEventListener('mouseleave', handleMouseLeave);
      track.removeEventListener('mouseup', handleMouseUp);
      track.removeEventListener('click', handleClick);
      track.removeEventListener('mousemove', handleMouseMove);
      track.removeEventListener('wheel', cancelMomentumTracking);
    };
  }, [track, handleMouseDown, handleMouseLeave, handleMouseUp, handleMouseMove, cancelMomentumTracking]);
};

const isTouchScreen = (): boolean => {
  return (
    typeof window !== 'undefined' &&
    ('ontouchstart' in window || navigator.maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0)
  );
};

const useCanBeCentered = (viewRef: RefObject<HTMLDivElement>, trackRef: RefObject<HTMLDivElement>) => {
  const [canBe, setCanBe] = useState(false);

  const check = throttle(() => {
    if (!trackRef.current || !viewRef.current) return;
    setCanBe(trackRef.current.scrollWidth <= viewRef.current.clientWidth);
  }, 100);

  useEffect(() => {
    check();
    window.addEventListener('resize', check);
    return () => window.removeEventListener('resize', check);
  }, [trackRef.current, viewRef.current]);

  return canBe;
};
