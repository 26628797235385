import { IconProps } from './type';

export const ChatOnIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.5 9L19.5 9C20.0523 9 20.5 9.44772 20.5 10V16C20.5 16.5523 20.0523 17 19.5 17H19C18.4477 17 18 17.4477 18 18V19.3573C18 19.5853 17.7198 19.6943 17.5657 19.5262L15.5473 17.3243C15.3578 17.1176 15.0904 17 14.8101 17H11.5C10.9477 17 10.5 16.5523 10.5 16V12"
      stroke={color}
      strokeWidth="1.6"
    />
    <path
      d="M14.5 5C14.5 4.44772 14.0523 4 13.5 4L4.5 4C3.94771 4 3.5 4.44772 3.5 5V11C3.5 11.5523 3.94772 12 4.5 12H5C5.55228 12 6 12.4477 6 13V14.3573C6 14.5853 6.28022 14.6943 6.43429 14.5262L8.45275 12.3243C8.64216 12.1176 8.9096 12 9.1899 12H13.5C14.0523 12 14.5 11.5523 14.5 11V5Z"
      stroke={color}
      strokeWidth="1.6"
    />
    <circle cx="18" cy="6" r="4.8" fill="#E14D4D" stroke="var(--uiWhite)" strokeWidth="1.6" />
  </svg>
);
