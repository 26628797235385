import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/node';
import { DocumentHead } from 'components/DocumentHead';
import Layout from 'components/Layout';
import { ProtectedRoutes } from 'components/ProtectedRoutes';
import { KeepAliveProvider } from 'containers/KeepAlive';
import Header from 'containers/navigation/Header';
import { AuthProvider, AuthState } from 'hooks/useAuth';
import { GlobalContextProvider } from 'hooks/useGlobal';
import { QueryParamProvider } from 'hooks/useQueryParams';
import type { AppContext, AppProps } from 'next/app';
import App from 'next/app';
import getConfig from 'next/config';
import { useEffect } from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import matchAllPolyfill from 'string.prototype.matchall';
import { NextRequest } from 'types/next';
import 'ui/styles/global.scss'; // Important: this must be the first import in the file
import getApolloClient from 'utils/getApolloClient';
import { routes } from 'utils/routes';
import { getMyProfile } from 'utils/ssrDataService';
import { DeviceDetectProvider } from '../hooks/useDeviceDetect';

// required for tiptap to work
const fromEntriesPolyfill = require('object.fromentries');

const { publicRuntimeConfig } = getConfig();

if (publicRuntimeConfig.sentryEnabled) {
  Sentry.init({
    enabled: publicRuntimeConfig.sentryEnabled,
    dsn: publicRuntimeConfig.sentryDsn,
    environment: publicRuntimeConfig.sentryEnv,
    release: process.env.NEXT_PUBLIC_COMMIT_SHA,
    maxValueLength: 2000,
  });
}

function MyApp({
  Component,
  pageProps,
  router,
  err,
  authState,
}: AppProps & { err: any; authState: AuthState }): JSX.Element {
  useEffect(() => {
    fromEntriesPolyfill.shim();
    matchAllPolyfill.shim();
    smoothscroll.polyfill();
  }, []);

  const client = getApolloClient();

  const withoutLayout: string[] = [
    routes.bookCover,
    routes.highlight,
    routes.highlightShare,
    routes.profileShare,
    routes.globalBookShare,
    routes.clubShare,
    routes.appPublic,
    routes.appPublicWaiting,
    routes.appPublicWaitlist,
    routes.reviewShare,
    routes.inviteShare,
    routes.goalProgressShare,
    routes.goalCreatedShare,
    routes.shelfShare,
    routes.wrapped,
    routes.wrappedById,
    routes.wrappedShare,
  ];

  const withFullWidthLayout: string[] = [
    routes.userOwnedBook,
    routes.globalBook,
    routes.chat,
    routes.chat,
    routes.patrons,
  ];
  const withoutFooterLayout: string[] = [routes.chat, routes.chat];

  const withLayout = !withoutLayout.includes(router.pathname) && !err;
  const fullWidth = withFullWidthLayout.includes(router.pathname) && !err;
  const withoutFooter = withoutFooterLayout.includes(router.pathname) && !err;

  return (
    <ApolloProvider client={client}>
      <QueryParamProvider>
        <DeviceDetectProvider>
          <AuthProvider initialAuthState={authState}>
            <ProtectedRoutes>
              <GlobalContextProvider>
                <DocumentHead />
                {withLayout ? (
                  <Layout fullWidth={fullWidth} header={<Header />} withoutFooter={withoutFooter}>
                    <KeepAliveProvider router={router}>
                      <Component {...pageProps} err={err} />
                    </KeepAliveProvider>
                  </Layout>
                ) : (
                  // Workaround for https://github.com/vercel/next.js/issues/8592
                  // <KeepAliveProvider router={router}>
                  <Component {...pageProps} err={err} />
                  // </KeepAliveProvider>
                )}
              </GlobalContextProvider>
            </ProtectedRoutes>
          </AuthProvider>
        </DeviceDetectProvider>
      </QueryParamProvider>
    </ApolloProvider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const request = appContext.ctx.req as NextRequest | undefined;
  const myProfile = await getMyProfile(request).catch(() => null);
  const authState: AuthState = { myProfile };

  const appProps = await App.getInitialProps(appContext);
  return { ...appProps, authState };
};

export default MyApp;
