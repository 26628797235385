import React from 'react';
import { noop } from 'utils/noop';
import { ModalState } from './types';

export function createModalContext<N extends string>() {
  return React.createContext<ModalState<N>>({
    startWizard: noop,
    setModal: noop,
  });
}
