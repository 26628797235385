import { IconProps } from './type';

export const LogoutIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 6.88235L18 5V19.1176L12 21V6.88235Z" stroke={color} strokeWidth="1.6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99995 2.9967H6.19995V3.7967L6.19995 19.1891V19.9891H6.99995H12V18.3891H7.79995L7.79995 4.5967L17.2 4.5967V4.95779L18 4.70214V19.0826L15.1632 19.9891H18H18.8V19.1891V3.7967V2.9967H18L6.99995 2.9967Z"
      fill={color}
    />
  </svg>
);
