import classNames from 'classnames';
import { ComponentProps, forwardRef, useState } from 'react';

import styles from './FileInput.module.scss';

type Props = {
  id: string;
  name: string;
  icon?: React.ReactNode;
  mini?: boolean;
  className?: string;
};

const FileInput = forwardRef<HTMLInputElement, Props & ComponentProps<'input'>>(
  (
    { id, icon, accept = 'image/png, image/jpeg', name, multiple = false, children, mini = false, ...rest },
    ref
  ) => {
    const [fileName, setFileName] = useState('');

    return (
      <div className={styles.container}>
        <label
          className={classNames(styles.input, {
            [styles.mini]: mini,
          })}
          htmlFor={id}
        >
          {icon && <div className={styles.icon}>{icon}</div>}

          {children}
          <input
            onChange={({ target }) => {
              if (target.files && target.files.length > 0) {
                const file = target.files[0];
                return setFileName(file.name);
              }

              setFileName('');
            }}
            id={id}
            type="file"
            name={name}
            accept={accept}
            multiple={multiple}
            ref={ref}
            {...rest}
          />
        </label>
        {fileName && <div className={styles.fileName}>{fileName}</div>}
      </div>
    );
  }
);

FileInput.displayName = 'FileInput';

export { FileInput };
