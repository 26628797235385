import Small from 'components/book/Small';
import { BookPartsFragment } from 'generated/graphql';
import { useMyReadingStates } from 'hooks/useReadingStates';
import { useMemo } from 'react';
import { Tag } from 'ui/generic';
import { LibraryIcon } from 'ui/icons';
import { ActionItem } from 'ui/specific/actionList';

type Props = {
  onClick: () => void;
  suggestion: BookPartsFragment;
};

const SearchSuggestion = ({ suggestion, onClick }: Props): JSX.Element => {
  const { readingStates } = useMyReadingStates();
  const isShowingAvatar = useMemo(
    () => readingStates && readingStates?.some((x) => x.bookId == suggestion.id),
    [readingStates, suggestion]
  );

  return (
    <ActionItem
      item={{
        render: (
          <div className="my-2 w-full flex justify-between wrap">
            <Small withLink={false} book={suggestion} />
            {isShowingAvatar && <Tag noHover icon={<LibraryIcon />}></Tag>}
          </div>
        ),
        onClick: onClick,
      }}
    />
  );
};

export default SearchSuggestion;
