import { IconProps } from './type';

export const BookIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 4H16C17.1046 4 18 4.89543 18 6V18C18 19.1046 17.1046 20 16 20H6V4Z"
      stroke={color}
      strokeWidth="1.6"
    />
    <path
      d="M6 4H16C17.1046 4 18 4.89543 18 6V15C18 16.1046 17.1046 17 16 17H6V4Z"
      stroke={color}
      strokeWidth="1.6"
    />
    <line x1="14.2" y1="7.8" x2="9.8" y2="7.8" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
  </svg>
);
