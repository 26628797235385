export const QS = {
  // Chat
  channel: 'channel',
  conversation: 'conversation',
  chatSettings: 'chat-settings',
  startChat: 'startChat',
  initmessage: 'initmessage',
  chatShowReplyTo: 'reply-to',

  // Club
  clubMembersModal: 'members-modal',
  clubSettingsModal: 'settings-modal',
  clubModeratorSettingsTab: 'settings-tab',
  clubWizzard: 'pg',
  clubSection: 'section',
  clubPostSort: 'sortby',

  // Library
  librarySection: 'section',
  libraryReadingStatus: 'status',
  libraryShelf: 'shelf',
  librarySort: 'library-sort',
  librarySortDirection: 'library-sort-direction',

  // Onboarding
  onboardingLibraryStep: 's',
  onboardingModal: 'm',
  onboardingWizard: 'w',
  registerStep: 'rs',

  // Profile
  followModal: 'follows',
  profileSettings: 'settings',
  profileSettingsTab: 'tab',

  // Explore
  discoveryProfiles: 'discovery-profiles',
  discoveryProfilesByCommunity: 'discovery-profiles-by-community',
  searchTab: 't',
  survey: 'survey',

  // Global
  createBook: 'create-book',
  myBookData: 'my-book-data',
  createClub: 'create-club',
  likesModal: 'likes',
  bookReadingStatesModal: 'book-rs',
} as const;

// check if object has duplicate values
export const hasDuplicates = (obj: Record<string, string>, knownDuplicates: string[]) => {
  const values = Object.values(obj).filter((v) => !knownDuplicates.includes(v));
  return values.length !== new Set(values).size;
};

const qsHasDuplicates = hasDuplicates(QS, ['section']);
if (qsHasDuplicates) {
  console.error(`You have defined 2 query string params that have duplicate values: ${JSON.stringify(QS)}`);
}

export function canGoBack() {
  return history.state.idx > 0;
}
