import { IconProps } from './type';

export const CommentIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 6C4 5.44772 4.44772 5 5 5H19C19.5523 5 20 5.44772 20 6V16C20 16.5523 19.5523 17 19 17H17.25C16.6977 17 16.25 17.4477 16.25 18V20.4626C16.25 20.6752 16.0016 20.7908 15.839 20.6539L11.7792 17.2351C11.5989 17.0833 11.3707 17 11.135 17H5C4.44772 17 4 16.5523 4 16V6Z"
        stroke={color}
        strokeWidth="1.6"
      />
    </svg>
  );
};
