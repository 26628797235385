import { useAuthContext } from 'hooks/useAuth';
import { NextRouter, useRouter } from 'next/router';
import { useEffect } from 'react';
import { isMobileApp } from 'utils/device';
import { routes } from 'utils/routes';

type Props = {
  children: JSX.Element;
};

// Warning: NO SSR! protectedRoutes are not rendered on the server
const protectedRoutes: string[] = [
  routes.library,
  routes.invite,
  routes.notifications,
  routes.goodreads,
  routes.clubs,
  routes.goals,
  routes.chat,
  routes.wrapped,
];

const appSignInPathnames: string[] = [
  routes.appPublic,
  routes.appPublicSignin,
  routes.appPublicWaiting,
  routes.appPublicWaitlist,
];

const allSignInPathnames: string[] = [...appSignInPathnames, routes.signIn];

export const ProtectedRoutes = ({ children }: Props): JSX.Element | null => {
  const router = useRouter();
  const { isSignedIn, isLoading } = useAuthContext();

  const pathIsSignIn = allSignInPathnames.includes(router.pathname);
  const pathIsProtected = protectedRoutes.includes(router.pathname);

  useEffect(() => {
    if (isLoading) return;
    if (pathIsSignIn && isSignedIn) {
      handleSignInPageRedirect(router);
    } else if (pathIsSignIn && !isSignedIn) {
      handleWrongSigninPage(router);
    }
    if (pathIsProtected && !isSignedIn) {
      handleProtectedRoute(router);
    }
    if (isMobileApp()) {
      handleRedirectInApp(router, isSignedIn);
    }
  }, [isLoading, isSignedIn, pathIsProtected, router]);

  // Don't render until auth state is decided
  if (pathIsProtected && (isLoading || !isSignedIn)) return null;

  return children;
};

export function handleSignInPageRedirect(router: NextRouter) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const origin = urlSearchParams.get('origin');
  if (origin) {
    const url = decodeURIComponent(origin);
    router.replace(url);
  } else {
    router.replace(routes.landing);
  }
}

export function handleProtectedRoute(router: NextRouter) {
  const signInPathname = isMobileApp() ? routes.appPublicSignin : routes.signIn;
  router.replace({
    pathname: signInPathname,
    query: { origin: router.asPath },
  });
}

function handleWrongSigninPage(router: NextRouter) {
  if (isMobileApp() && router.pathname === routes.signIn) {
    router.replace(routes.appPublicSignin);
  } else if (!isMobileApp() && appSignInPathnames.includes(router.pathname)) {
    router.replace(routes.signIn);
  }
}

function handleRedirectInApp(router: NextRouter, isSignedIn: boolean) {
  if (!isSignedIn && router.pathname === routes.landing) {
    router.replace(routes.appPublic);
  }
}
