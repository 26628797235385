import { IconProps } from './type';

export const WantToReadIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.47716 14.9756L4.88075 9.49938C4.481 9.02311 4.60821 8.30136 5.1467 7.99046L11.7425 4.18239C12.281 3.87149 12.9696 4.12219 13.1822 4.70652L15.6266 11.4253C15.6879 11.5937 15.8347 11.7165 16.0113 11.747L19.4377 12.3392C19.4838 12.3472 19.4947 12.4084 19.4542 12.4318L8.43503 18.7937C8.39453 18.817 8.34696 18.777 8.3631 18.7331L9.56345 15.4697C9.62533 15.3014 9.59241 15.1129 9.47716 14.9756Z"
      stroke={color}
      strokeWidth="1.6"
    />
    <path
      d="M15.8611 20.5324C16.082 20.9151 16.5713 21.0462 16.9539 20.8253C17.3366 20.6043 17.4677 20.1151 17.2468 19.7324L15.8611 20.5324ZM13.4107 16.2882L15.8611 20.5324L17.2468 19.7324L14.7963 15.4882L13.4107 16.2882Z"
      fill={color}
    />
  </svg>
);
