import { IconProps } from './type';

export const DeleteIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="8.2" stroke={color} strokeWidth="1.6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.5656 9.5657C15.8781 9.25328 15.8781 8.74675 15.5656 8.43433C15.2532 8.12191 14.7467 8.12191 14.4343 8.43433L12 10.8686L9.56564 8.43433C9.25322 8.12191 8.74669 8.12191 8.43427 8.43433C8.12185 8.74675 8.12185 9.25328 8.43427 9.5657L10.8686 12L8.43427 14.4343C8.12185 14.7467 8.12185 15.2533 8.43427 15.5657C8.74668 15.8781 9.25322 15.8781 9.56564 15.5657L12 13.1314L14.4343 15.5657C14.7467 15.8781 15.2532 15.8781 15.5656 15.5657C15.8781 15.2533 15.8781 14.7467 15.5656 14.4343L13.1313 12L15.5656 9.5657Z"
      fill={color}
    />
  </svg>
);
