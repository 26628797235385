import { IconProps } from './type';

export const ShelvesIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 17H20" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <path d="M6 20L6 17" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <path d="M18 20L18 17" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <path d="M6 14L6 11" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <path d="M18 14L18 11" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <path d="M6 8L6 5" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <path d="M18 8L18 5" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <path d="M4 11H20" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <path d="M4 5H20" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
  </svg>
);
