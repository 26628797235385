import { useNotificationCount } from 'containers/ChatNew/useNotificationCount';
import Link from 'next/link';
import { useEffect } from 'react';
import { ChatIcon, ChatOnIcon } from 'ui/icons';
import { routes } from 'utils/routes';
import styles from './NavChat.module.scss';

type Props = {
  className?: string;
};

export const NavChat = (props: Props) => {
  const { fetchUnseen, unseen } = useNotificationCount();

  useEffect(() => {
    fetchUnseen();
  }, []);

  const isUnread = unseen > 0;

  return (
    <div className={styles.wrapper}>
      <Link href={routes.chat}>
        <a className={props.className}>{isUnread ? <ChatOnIcon color="var(--uiBlack)" /> : <ChatIcon />}</a>
      </Link>
    </div>
  );
};
