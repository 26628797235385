export const LeaveIcon = ({ color = 'currentColor' }): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 6.88235L18 5V19.1176L12 21L12 6.88235Z" stroke={color} strokeWidth="1.6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99995 2.99658H6.19995V3.79658V19.189V19.989H6.99995H12V18.389H7.79995V4.59658H17.2V4.95767L18 4.70202V19.0825L15.1632 19.989H18H18.8V19.189V3.79658V2.99658H18H6.99995Z"
      fill={color}
    />
  </svg>
);
