import { IconProps } from './type';

export const FlagIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line
      x1="6.35022"
      y1="20.0731"
      x2="9.07217"
      y2="4.6488"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M8.65526 6.089C13.4708 6.01357 13.0863 7.34772 15.9712 7.575C17.4985 7.69532 18.2151 6.84215 19.9532 6.97909L19.3123 15.1152C17.5741 14.9783 16.8575 15.8315 15.3302 15.7111C12.4453 15.4839 12.0919 14.0687 7.27638 14.1441"
      stroke={color}
      strokeWidth="1.6"
    />
  </svg>
);
