import { IconProps } from './type';

export const HomeDisplayIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.18628 13.168L7.18628 26.168C7.18628 26.7203 7.63399 27.168 8.18628 27.168H24.8529C25.4052 27.168 25.8529 26.7203 25.8529 26.168V13.168"
      stroke={color}
      strokeWidth="1.6"
    />
    <path
      d="M13.853 27.168L13.853 17.5013C13.853 16.949 14.3007 16.5013 14.853 16.5013H18.1864C18.7386 16.5013 19.1864 16.949 19.1864 17.5013V27.168"
      stroke={color}
      strokeWidth="1.6"
    />
    <path
      d="M4.62059 14.6016C4.30817 14.9141 4.30817 15.4206 4.62059 15.733C4.93301 16.0454 5.43955 16.0454 5.75196 15.733L4.62059 14.6016ZM27.2873 15.733C27.5997 16.0454 28.1062 16.0454 28.4186 15.733C28.7311 15.4206 28.7311 14.9141 28.4186 14.6016L27.2873 15.733ZM5.75196 15.733C9.11956 12.3654 11.2414 10.0776 12.8885 8.59531C14.5282 7.11976 15.5015 6.63398 16.5201 6.63398V5.03398C14.8725 5.03398 13.5122 5.88154 11.8182 7.40599C10.1317 8.92372 7.91966 11.3026 4.62059 14.6016L5.75196 15.733ZM16.5201 6.63398C17.5387 6.63398 18.5117 7.11971 20.1512 8.59528C21.7981 10.0776 23.9196 12.3654 27.2873 15.733L28.4186 14.6016C25.1196 11.3026 22.9078 8.92372 21.2215 7.40602C19.5278 5.88159 18.1677 5.03398 16.5201 5.03398V6.63398Z"
      fill={color}
    />
  </svg>
);
