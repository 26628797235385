import { FileInput } from 'components/basics/FileInput';
import LoadingSpinner from 'components/basics/LoadingSpinner';
import { useSendFeedbackMutation } from 'generated/graphql';
import { useAuthContext } from 'hooks/useAuth';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Divider, LinkSimple, TextArea } from 'ui/generic';
import { LinkIcon } from 'ui/icons';
import Modal from 'ui/specific/modal/Modal';
import { uploadImage } from 'utils/uploadImage';
import styles from './FeedbackModal.module.scss';

type Props = {
  setVisible: (newVal: boolean) => void;
};

type FeedbackFormData = {
  message: string;
  image: FileList;
};

const FeedbackModal = ({ setVisible }: Props): JSX.Element => {
  const { token } = useAuthContext();
  const { register, handleSubmit, formState, watch } = useForm<FeedbackFormData>();

  const imageFiles = watch('image');

  const [sendFeedback, result] = useSendFeedbackMutation();
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async ({ message, image }: FeedbackFormData) => {
    setIsLoading(true);

    if (token && image[0]) {
      const imageUrl = await uploadImage(image[0], 'feedback', token);
      message += `\n ${imageUrl}`;
    }

    await sendFeedback({ variables: { message } });
    setIsLoading(false);
  };

  const isSubmitted = result?.data?.sendFeedback?.success || false;

  return (
    <Modal
      close={() => {
        if (!formState.isDirty) return setVisible(false);
        const areYouSure = confirm('You have unsaved changes. Are you sure you want to leave?');
        if (areYouSure) {
          setVisible(false);
        }
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
        {isSubmitted ? (
          <>
            <h2 className="displayHeader">Thanks for your feedback!</h2>
            <p className="mt-5">We got your message and will get back to you soon.</p>
          </>
        ) : (
          <>
            <h2 className="displayHeader">Feedback</h2>
            <p className="mt-5">
              Please let us know if you have feedback, a request or experienced a bug. If you have feature
              ideas, you can{' '}
              <LinkSimple variant="underline" href="https://literal.club/feature-ideas/" asHtmlAnchor>
                vote on them here or submit your own
              </LinkSimple>
              .
            </p>

            <div className="mb-8 mt-10">
              <Divider />
            </div>

            <TextArea
              id="message"
              {...register('message')}
              label="Description"
              placeholder="Please describe your bug or comment."
            />

            <p className="mt-10">Optionally add a screenshot that helps us better understand your message.</p>
            <div className="mt-5 flex align-center">
              <FileInput className="mt-10" mini {...register('image')} id="image" icon={<LinkIcon />}>
                Attach image
              </FileInput>

              {imageFiles?.[0] && (
                <div className="ml-2" style={{ lineHeight: '32px' }}>
                  {imageFiles[0].name}
                </div>
              )}
            </div>

            <div className="mt-10">
              {isLoading ? <LoadingSpinner /> : <Button type="submit">Submit</Button>}
            </div>
          </>
        )}
      </form>
    </Modal>
  );
};

export { FeedbackModal };
