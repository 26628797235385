import { IconProps } from './type';

export const StopIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="8.2" stroke={color} strokeWidth="1.6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00002 5.86863L7.56571 6.43431L17.5657 16.4343L18.1314 17L17 18.1314L16.4343 17.5657L6.43434 7.56569L5.86865 7L7.00002 5.86863Z"
      fill={color}
    />
  </svg>
);
