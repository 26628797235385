import { IconProps } from './type';

export const InviteIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.811 9.79972C19.6768 9.25855 20.7999 9.88105 20.7999 10.9021V19C20.7999 19.9941 19.9941 20.8 19 20.8H4.99995C4.00584 20.8 3.19995 19.9941 3.19995 19V10.9021C3.19995 9.88105 4.32309 9.25855 5.18895 9.79972L12 14.0566L18.811 9.79972ZM19.2 11.4434L12.6889 15.5128C12.2674 15.7762 11.7325 15.7762 11.311 15.5128L4.79995 11.4434V19C4.79995 19.1104 4.88949 19.2 4.99995 19.2H19C19.1104 19.2 19.2 19.1104 19.2 19V11.4434Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.79995 8L6.79995 11L5.19995 11L5.19995 8C5.19995 6.4536 6.45355 5.2 7.99995 5.2L11 5.2L11 6.8L7.99995 6.8C7.33721 6.8 6.79995 7.33726 6.79995 8ZM17.2 11L17.2 8.5L18.8 8.5L18.8 11L17.2 11Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2376 11C16.6158 11 20.4749 7.69224 20.4749 4.93579C20.4749 3.26121 19.1173 1.62805 17.4428 1.62805C16.6773 1.62805 15.8783 1.91172 15.2363 2.37966C14.5955 1.91172 13.7976 1.62805 13.0321 1.62805C11.3575 1.62805 10 3.26121 10 4.93579C10 7.69224 13.859 11 15.2373 11L15.2374 11L15.2376 11ZM15.2374 9.37746C15.2443 9.3748 15.2517 9.37191 15.2595 9.36876C15.3839 9.31853 15.56 9.22902 15.779 9.08978C16.2169 8.81142 16.7328 8.39425 17.2249 7.88719C18.2866 6.79331 18.8749 5.66781 18.8749 4.93579C18.8749 3.96167 18.061 3.22805 17.4428 3.22805C17.0527 3.22805 16.5782 3.38144 16.1788 3.6726L15.2355 4.36026L14.2927 3.6718C13.8949 3.38125 13.4219 3.22805 13.0321 3.22805C12.4139 3.22805 11.6 3.96167 11.6 4.93579C11.6 5.66781 12.1882 6.79331 13.2499 7.88719C13.7421 8.39425 14.2579 8.81142 14.6958 9.08978C14.9149 9.22902 15.0909 9.31853 15.2154 9.36876C15.2232 9.37191 15.2305 9.3748 15.2374 9.37746Z"
      fill={color}
    />
  </svg>
);
