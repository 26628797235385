import { IconProps } from './type';

export const GoalDisplayIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6423 4.76758C8.67209 5.32794 4 10.3535 4 16.4702C4 22.9621 9.26272 28.2248 15.7546 28.2248C22.2465 28.2248 27.5092 22.9621 27.5092 16.4702C27.5092 15.3657 27.3569 14.2968 27.0721 13.2833C26.86 13.3862 26.5085 13.5456 26.1065 13.6632C25.9258 13.7161 25.7413 13.7596 25.5526 13.7933C25.7851 14.6461 25.9092 15.5437 25.9092 16.4702C25.9092 22.0785 21.3628 26.6248 15.7546 26.6248C10.1464 26.6248 5.6 22.0785 5.6 16.4702C5.6 11.3437 9.39894 7.10445 14.3352 6.41405L14.6423 4.76758ZM13.7006 9.95986C10.9347 10.832 8.92917 13.4176 8.92917 16.4717C8.92917 20.242 11.9856 23.2984 15.7558 23.2984C19.5261 23.2984 22.5825 20.242 22.5825 16.4717C22.5825 15.3789 22.3257 14.3461 21.8692 13.4303C21.3881 13.2851 20.9866 13.1306 20.6676 13.0079L20.6676 13.0078C20.5299 12.9549 20.4076 12.9078 20.3009 12.87C20.1331 12.8104 19.9504 12.749 19.7355 12.692L19.6812 13.0206C20.4912 13.9412 20.9825 15.1491 20.9825 16.4717C20.9825 19.3583 18.6424 21.6984 15.7558 21.6984C12.8692 21.6984 10.5292 19.3583 10.5292 16.4717C10.5292 14.4347 11.6945 12.6698 13.3949 11.8074L13.7006 9.95986Z"
      fill={color}
    />
    <path
      d="M27.4329 10.001L28.4548 3.90674C26.8785 3.78256 26.2286 4.55628 24.8435 4.44716C22.2273 4.24105 22.5759 3.03112 18.2088 3.09953L17.0623 9.24655C21.4295 9.17815 21.75 10.4615 24.3662 10.6676C25.7513 10.7768 25.8566 9.87679 27.4329 10.001Z"
      fill="none"
    />
    <path
      d="M18.2088 3.09953C22.5759 3.03112 22.2273 4.24105 24.8435 4.44716C26.2286 4.55628 26.8785 3.78256 28.4548 3.90674L27.4329 10.001C25.8566 9.87679 25.7513 10.7768 24.3662 10.6676C21.75 10.4615 21.4295 9.17815 17.0623 9.24655M18.2088 3.09953L17.0623 9.24655M18.2088 3.09953L18.4768 1.35721M17.0623 9.24655L15.867 16.4703"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <ellipse cx="15.7557" cy="16.472" rx="2.02667" ry="2.02667" fill={color} />
  </svg>
);
