import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Tooltip } from 'ui/generic';
import { PatronIcon } from 'ui/icons';
import { routes } from 'utils/routes';
import styles from './PatronBadge.module.scss';

type Props = {
  size?: number;
  noLink?: boolean;
};

export const PatronBadgeSmall: React.FC<Props> = ({ size = 16, noLink }) => {
  const router = useRouter();

  if (noLink) {
    return (
      <Tooltip
        text="Patron"
        render={(ref) => (
          <div
            ref={ref}
            className={classNames('mx-1', styles.patronBadgeSmall)}
            style={{ width: size, height: size }}
          >
            <PatronIcon size={size - 4} />
          </div>
        )}
      />
    );
  }

  return (
    <Tooltip
      text="Patron"
      render={(ref) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            router.push(routes.patrons);
          }}
          ref={ref}
          className={classNames('mx-1', styles.patronBadgeSmall)}
          style={{ width: size, height: size }}
        >
          <PatronIcon size={size - 4} />
        </div>
      )}
    />
  );
};
