import { handleProtectedRoute } from 'components/ProtectedRoutes';
import { ReviewModalProps } from 'containers/CreateReviewModal';
import { ReportDataModalProps } from 'containers/modals/ReportDataModal';
import { MomentModalProps } from 'containers/Moment/CreateOrUpdateMomentModal';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { FC, useContext, useEffect, useState } from 'react';
import { BooleanParam, JsonParam, StringParam, useQueryParam } from 'use-query-params';
import { canGoBack, QS } from 'utils/queryStringParams';
import { useAuthContext } from '../useAuth';
import { BookRSParams, ContentFlagParams, LikesModalParam } from './types';

type State = {
  setMomentModal: (val?: MomentModalProps) => void;
  setCreateBookModal: (val?: boolean) => void;
  setPersonalBookDetailsModal: (val?: string) => void;
  setCreateClubWizard: (val?: boolean, updateType?: 'replace') => void;
  setReportDataModal: (val?: ReportDataModalProps) => void;
  setReviewModal: (val?: ReviewModalProps) => void;
  setLikesModal: (val: LikesModalParam) => void;
  setBookRSModal: (val: BookRSParams) => void;
  setSettingsModal: (val: boolean) => void;
  setContentFlagModal: (val?: ContentFlagParams) => void;
};

const LikesModal = dynamic(() => import('containers/modals/LikesModal'));
const RelatedReadingStateModal = dynamic(() => import('containers/modals/RelatedReadingStateModal'));
const SettingsModal = dynamic(() => import('containers/UserSettings/SettingsModal'));
const CreateBookModal = dynamic(() => import('containers/modals/CreateBookModal'));
const PersonalBookDetailsModal = dynamic(() => import('containers/modals/PersonalBookDetailsModal'));
const ContentFlagModal = dynamic(() => import('containers/modals/ContentFlagModal'));
const CreateClubWizard = dynamic(() => import('containers/Clubs/Wizard/CreateClubWizard'));
const CreateReviewModal = dynamic(() => import('containers/CreateReviewModal'));
const ReportDataModal = dynamic(() => import('containers/modals/ReportDataModal'));
const CreateOrUpdateMomentModal = dynamic(() => import('containers/Moment/CreateOrUpdateMomentModal'));

// 522kb => 388 kB

export const GlobalModalContext = React.createContext<State | undefined>(undefined);

export const GlobalModalProvider: FC = ({ children }) => {
  const router = useRouter();
  const { isSignedIn, isLoading } = useAuthContext();
  const [momentModal, setMomentModal] = useState<MomentModalProps>();
  const [createBookModal, setCreateBookModal] = useQueryParam(QS.createBook, BooleanParam);
  const [personalBookDetailsModal, setPersonalBookDetailsModal] = useQueryParam(QS.myBookData, StringParam);
  const [createClubWizard, setCreateClubWizard] = useQueryParam(QS.createClub, BooleanParam);
  const [reportDataModal, setReportDataModal] = useState<ReportDataModalProps>();
  const [reviewModal, setReviewModal] = useState<ReviewModalProps>();
  const [likesModal, setLikesModal] = useQueryParam<LikesModalParam>(QS.likesModal, JsonParam);
  const [bookRSModal, setBookRSModal] = useQueryParam<BookRSParams>(QS.bookReadingStatesModal, JsonParam);
  const [settingsModal, setSettingsModal] = useQueryParam(QS.profileSettings, BooleanParam);
  const [contentFlagModal, setContentFlagModal] = useState<ContentFlagParams>();

  // auth protection for certain url params to ensures that the user gets pushed to the right place
  useEffect(() => {
    if (!isSignedIn && !isLoading && settingsModal) {
      handleProtectedRoute(router);
    }
  }, [isLoading, isSignedIn, settingsModal]);

  return (
    <>
      <GlobalModalContext.Provider
        value={{
          setMomentModal,
          setCreateBookModal,
          setPersonalBookDetailsModal,
          setCreateClubWizard,
          setReportDataModal,
          setReviewModal,
          setLikesModal,
          setBookRSModal,
          setSettingsModal,
          setContentFlagModal,
        }}
      >
        {children}
        {!!momentModal && isSignedIn && <CreateOrUpdateMomentModal {...momentModal} />}
        {!!createBookModal && isSignedIn && <CreateBookModal />}
        {!!personalBookDetailsModal && isSignedIn && (
          <PersonalBookDetailsModal bookId={personalBookDetailsModal} />
        )}
        {!!createClubWizard && isSignedIn && <CreateClubWizard />}
        {!!reportDataModal && isSignedIn && <ReportDataModal {...reportDataModal} />}
        {!!reviewModal && isSignedIn && <CreateReviewModal {...reviewModal} />}
        {!!likesModal && (
          <LikesModal
            {...likesModal}
            close={() => (canGoBack() ? router.back() : setLikesModal(undefined, 'replace'))}
          />
        )}
        {!!bookRSModal && (
          <RelatedReadingStateModal
            {...bookRSModal}
            close={() => (canGoBack() ? router.back() : setBookRSModal(undefined, 'replace'))}
          />
        )}
        {!!settingsModal && isSignedIn && (
          <SettingsModal close={() => setSettingsModal(undefined, 'replace')} />
        )}
        {!!contentFlagModal && isSignedIn && <ContentFlagModal {...contentFlagModal} />}
      </GlobalModalContext.Provider>
    </>
  );
};

export const useGlobalModalContext = (): State => {
  const context = useContext(GlobalModalContext);
  if (context === undefined) {
    throw new Error('useGlobalModalContext must be used within a GlobalModalProvider');
  }
  return context;
};
