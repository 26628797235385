import { IconProps } from './type';

export const ConnectionOnIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 10.5L7 4L11 11L7 13L16 19.5L12.5 12.5L16 10.5Z"
        stroke={color}
        strokeWidth="1.6"
        strokeLinejoin="round"
      />
    </svg>
  );
};
