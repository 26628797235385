import { IconProps } from './type';

export const CameraIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 8.05464C4 7.2513 4.6512 6.6001 5.45455 6.6001H18.5455C19.3488 6.6001 20 7.2513 20 8.05464V16.5741C20 17.3775 19.3488 18.0287 18.5455 18.0287H5.45455C4.6512 18.0287 4 17.3775 4 16.5741V8.05464Z"
      stroke={color}
      strokeWidth="1.6"
    />
    <path
      d="M7 5.90909C7 5.407 7.407 5 7.90909 5H8.09091C8.593 5 9 5.407 9 5.90909V6H7V5.90909Z"
      stroke={color}
      strokeWidth="1.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9997 14.9808C13.4725 14.9808 14.6664 13.787 14.6664 12.3142C14.6664 10.8414 13.4725 9.64746 11.9997 9.64746C10.527 9.64746 9.33301 10.8414 9.33301 12.3142C9.33301 13.787 10.527 14.9808 11.9997 14.9808Z"
      stroke={color}
      strokeWidth="1.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7521 10.4857C17.194 10.4857 17.5521 10.1276 17.5521 9.68574C17.5521 9.24389 17.194 8.88574 16.7521 8.88574C16.3103 8.88574 15.9521 9.24389 15.9521 9.68574C15.9521 10.1276 16.3103 10.4857 16.7521 10.4857Z"
      fill={color}
    />
  </svg>
);
