import SearchInput from 'components/layout/SearchInput';
import { BookPartsFragment, SearchBookV2Document, SearchBookV2Query } from 'generated/graphql';
import useArrowKeys from 'hooks/useArrowKeys';
import { useAuthContext } from 'hooks/useAuth';
import { useCachedAbortiveQuery } from 'hooks/useCachedAbortiveQuery';
import useDebounce from 'hooks/useDebounce';
import useOutsideClick from 'hooks/useOutsideClick';
import { usePersistedState } from 'hooks/usePersistedState';
import { useScreenSize } from 'hooks/useScreenSize';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { ButtonSimple } from 'ui/generic';
import { DeleteIcon, TimeIcon } from 'ui/icons';
import { ActionItem, ActionList } from 'ui/specific/actionList';
import { removeDuplicates } from 'utils/array';
import { isTouchScreen } from 'utils/isTouchScreen';
import { encodeQuery } from 'utils/queryEncoding';
import { changeRouteKeepParams, routes } from 'utils/routes';
import styles from './SearchBar.module.scss';
import SearchSuggestion from './SearchSuggestion';
import SearchSuggestions from './SearchSuggestions';

type Props = {
  id?: string;
  onIsActive?: (active: boolean) => void;
};

const SearchBar = ({ id, onIsActive }: Props): JSX.Element => {
  const { profile, isSignedIn } = useAuthContext();
  const router = useRouter();
  const parentRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState<string>(router.query.query?.toString() || '');
  const debouncedSearchTerm = useDebounce(searchTerm, 100);
  const [suggestions, setSuggestions] = useState<Array<BookPartsFragment>>([]);
  useArrowKeys<HTMLDivElement>({ refObject: parentRef, selectors: '[data-arrow-keys]' });
  const { mdScreen, smScreen } = useScreenSize();

  // suggestions and last search terms
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [lastSearchTerms, setLastSearchTerms] = usePersistedState('last-searched', '');

  const { data: bookSearch, loading } = useCachedAbortiveQuery<SearchBookV2Query>({
    query: SearchBookV2Document,
    variables: { query: debouncedSearchTerm },
    deps: [debouncedSearchTerm],
    skip: !debouncedSearchTerm,
  });

  useEffect(() => {
    if (bookSearch && bookSearch.searchBookV2 && bookSearch.searchBookV2.length > 0) {
      setSuggestions(removeDuplicates(bookSearch.searchBookV2, 'id'));
    }
  }, [bookSearch]);

  // Blur the input on mobile when showing full search results
  useEffect(() => {
    if (isTouchScreen() && router.query.query) {
      (document.activeElement as HTMLElement).blur();
    }
  }, [router.query.query]);

  // Clear the bar when we change route away from search.
  useEffect(() => {
    const handleRouteChange = () => {
      let searches: string[] = [];
      if (lastSearchTerms) {
        const parsedSearches = JSON.parse(lastSearchTerms);
        searches = [...parsedSearches];
      }
      if (!searches.includes(searchTerm) && searchTerm !== '') {
        searches = [searchTerm.trim(), ...searches];
      }
      router.pathname === routes.searchWithQuery && setLastSearchTerms(JSON.stringify(searches.slice(0, 7)));
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, searchTerm]);

  // Suggestions and previous searches
  useEffect(() => {
    if (debouncedSearchTerm.length < 2) {
      setSuggestions([]);
    }
  }, [debouncedSearchTerm]);
  useOutsideClick({
    refObject: parentRef,
    onOutsideClick: () => {
      setShowSuggestions(false);
    },
    isActive: showSuggestions,
  });

  const parsedSearches: string[] = lastSearchTerms ? JSON.parse(lastSearchTerms) : [];

  async function handleSetSearchHistory(searchString: string) {
    let searches: string[] = [];
    if (lastSearchTerms) {
      const parsedSearches = JSON.parse(lastSearchTerms);
      searches = [...parsedSearches];
    }
    if (!searches.includes(searchString) && searchString !== '') {
      searches = [searchString.trim(), ...searches];
    }
    setLastSearchTerms(JSON.stringify(searches.slice(0, 7)));
  }

  function handleRemoveFromSearchHistory(val: string) {
    let searches: string[] = [];
    if (lastSearchTerms) {
      const parsedSearches = JSON.parse(lastSearchTerms).filter((searchItem: string) => searchItem !== val);
      searches = [...parsedSearches];
      setLastSearchTerms(JSON.stringify(searches.slice(0, 7)));
    }
  }

  const handleSubmit = (searchTerm: string) => {
    if (!searchTerm) return;
    handleSetSearchHistory(searchTerm);
    setShowSuggestions(false);
    router.push(
      routes.searchWithQuery,
      changeRouteKeepParams(router.asPath, `/search/${encodeQuery(searchTerm)}`)
    );
  };

  const handleChange = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const loggedOutSmScreen = smScreen && !isSignedIn;

  return (
    <div className={styles.container} ref={parentRef}>
      <SearchInput
        onClear={() => {
          setSearchTerm('');
          setSuggestions([]);
        }}
        onFocus={() => {
          setShowSuggestions(true);
          if (onIsActive) onIsActive(true);
        }}
        onBlur={() => {
          // Hack incoming...
          // Delaying onblur so that onclick events can be handled on search suggestions
          // Better ideas are welcome
          setTimeout(() => {
            if (onIsActive) onIsActive(false);
            // On medium screens, we don't want the x to show because the
            // input is collapsed to a small size
            if (mdScreen || loggedOutSmScreen) {
              setSearchTerm('');
            }
          }, 100);
        }}
        searchTerm={searchTerm}
        onChange={handleChange}
        onSubmit={handleSubmit}
        id={id}
      />
      {!loggedOutSmScreen &&
        showSuggestions &&
        ((suggestions && suggestions.length > 0) || (parsedSearches && parsedSearches.length > 0)) && (
          <SearchSuggestions
            moreHref={routes.searchWithQuery}
            moreAs={`/search/${encodeQuery(searchTerm)}`}
            loading={loading}
            suggestions={
              !suggestions || suggestions.length < 1 ? null : (
                <ActionList maxHeight={window.innerHeight - 120}>
                  {suggestions?.slice(0, 7).map((suggestion: BookPartsFragment) => {
                    return (
                      <SearchSuggestion
                        key={suggestion.id}
                        suggestion={suggestion}
                        onClick={() => {
                          handleSetSearchHistory(suggestion.title);
                          setShowSuggestions(false);
                          router.push(routes.globalBook, `/book/${suggestion.slug}`);
                        }}
                      />
                    );
                  })}
                  <ActionItem item={{ divider: 'full' }} />
                  <ActionItem
                    data-selectable
                    item={{
                      render: (
                        <div
                          onClick={() => handleSubmit(debouncedSearchTerm)}
                          className="my-2 textDiscreet flex w-full justify-center items-center"
                          style={{ color: '' }}
                        >
                          See all search results
                        </div>
                      ),
                    }}
                  />
                </ActionList>
              )
            }
            searchHistory={
              (showSuggestions && suggestions && suggestions.length > 0) ||
              parsedSearches.length < 1 ? null : (
                <>
                  <span className="px-4 py-4" style={{ color: 'var(--uiBase)' }}>
                    Recent searches
                  </span>
                  <ActionList maxHeight={window.innerHeight - 120}>
                    {parsedSearches.map((searchItem: string, i: number) => {
                      return (
                        <ActionItem
                          data-selectable
                          key={i}
                          item={{
                            icon: <TimeIcon />,
                            title: searchItem,
                            onClick: () => {
                              setSearchTerm(searchItem);
                              handleSubmit(searchItem);
                            },
                            subtitle: (
                              <ButtonSimple
                                variant="faded"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemoveFromSearchHistory(searchItem);
                                }}
                                iconOnly
                              >
                                <DeleteIcon />
                              </ButtonSimple>
                            ),
                          }}
                        />
                      );
                    })}
                  </ActionList>
                </>
              )
            }
          />
        )}
    </div>
  );
};

export default SearchBar;
