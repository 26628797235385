import { ErrorMessage } from 'components/basics/Error/ErrorMessage';
import LoadingSpinner from 'components/basics/LoadingSpinner';
import MoreLink from 'components/layout/MoreLink';
import { useNotificationsQuery } from 'generated/graphql';
import { useNotificationsContext } from 'hooks/useNotifications';
import { useSocketsContext } from 'hooks/useSockets';
import { useEffect } from 'react';
import { SocketEvent } from 'types/sockets';
import { sendRnMessage } from 'utils/reactNative';
import { NotificationsListContent } from '../NotificationsListContent';

const NotificationsList = (): JSX.Element => {
  const { refetchNotificationsCount, hasNotifications } = useNotificationsContext();
  const { send } = useSocketsContext();
  const { data, loading, error, refetch } = useNotificationsQuery({
    variables: { limit: 20 },
  });

  useEffect(() => {
    if (data) {
      refetchNotificationsCount();
      sendRnMessage('NOTIFICATIONS_REFRESH');
    }
  }, [data]);

  useEffect(() => {
    if (hasNotifications) {
      send(SocketEvent.REFETCH_NOTIFICATIONS);
    }
  }, []);

  useEffect(() => {
    if (!loading) refetch();
  }, []);

  if (loading || !data) return <LoadingSpinner />;
  if (error) return <ErrorMessage />;

  if (data?.notifications?.length === 0) {
    return <div style={{ padding: 'var(--size-2)' }}>No notifications</div>;
  }

  return (
    <div className="mb-1">
      <NotificationsListContent data={data} limit={5} />
      {data?.notifications?.length > 5 && <MoreLink href="/notifications">Show older</MoreLink>}
    </div>
  );
};

export default NotificationsList;
