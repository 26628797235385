import _dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';
import { enLocale, shortLocale, thresholds } from './locales';

///// Config

_dayjs.extend(utc);
_dayjs.extend(relativeTime, { thresholds });
_dayjs.extend(updateLocale);
_dayjs.extend(dayOfYear);
_dayjs.extend(customParseFormat);
_dayjs.extend(advancedFormat);

///// Locales
_dayjs.updateLocale('en', enLocale);
_dayjs.locale(shortLocale, undefined, true);

///// export dayjs
export const dayjs = _dayjs;
