import { ProfilePartsFragment } from 'generated/graphql';
import Link from 'next/link';
import { forwardRef } from 'react';
import { AssetSize } from '../types';
import styles from './Avatar.module.scss';

type Props =
  | {
      profile: ProfilePartsFragment;
      width?: number;
      avatarSticker?: JSX.Element;
      assetSize?: AssetSize;
      disableLazyLoading?: boolean;
    }
  | {
      image: string;
      width?: number;
      avatarSticker?: JSX.Element;
      assetSize?: AssetSize;
      disableLazyLoading?: boolean;
    };

export const Avatar = ({
  width = 40,
  avatarSticker,
  assetSize = '100',
  disableLazyLoading,
  ...props
}: Props): JSX.Element => {
  const image = 'image' in props ? props.image : (props.profile.image as string);
  const alt = 'profile' in props ? `Photo of ${props.profile.name}` : 'User photo';

  return (
    <div className={styles.container}>
      <img
        style={{ width, height: width }}
        src={`${image}?size=${assetSize}`}
        alt={alt}
        className={styles.avatar}
        loading={disableLazyLoading ? 'eager' : 'lazy'}
        width={width}
        height={width}
        draggable={false}
      />
      {avatarSticker && avatarSticker}
    </div>
  );
};

type LinkProps = {
  profile: ProfilePartsFragment;
  width?: number;
  avatarSticker?: JSX.Element;
  assetSize?: AssetSize;
  disableLazyLoading?: boolean;
};

export const AvatarLink = forwardRef<HTMLAnchorElement, LinkProps>((props: LinkProps, ref): JSX.Element => {
  return (
    <Link href="/[handle]" as={`/${props.profile.handle}`}>
      <a ref={ref} draggable={false} className={styles.link}>
        <Avatar {...props} />
      </a>
    </Link>
  );
});
AvatarLink.displayName = 'AvatarLink';

export const AvatarEmpty = ({ width = 40 }: { width: number }): JSX.Element => {
  return (
    <div className={styles.container}>
      <div style={{ width, height: width, backgroundColor: 'var(--uiBase)' }} className={styles.avatar} />
    </div>
  );
};
