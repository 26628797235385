import { IconProps } from './type';

export const BarCodeIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 7L21 3L17 3" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M17 21L21 21L21 17"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M3 7L3 3L7 3" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7 21L3 21L3 17" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <line x1="7.8" y1="7.8" x2="7.8" y2="17.2" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <line x1="10.8" y1="7.8" x2="10.8" y2="17.2" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <line x1="13.8" y1="7.8" x2="13.8" y2="17.2" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <line x1="16.8" y1="7.8" x2="16.8" y2="17.2" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
  </svg>
);
