import { IconProps } from './type';

export const CalendarIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 10V13L5 18C5 18.5523 5.44772 19 6 19H18C18.5523 19 19 18.5523 19 18V10M5 10L5 7C5 6.44772 5.44772 6 6 6H8.5M5 10L19 10M19 10V7C19 6.44772 18.5523 6 18 6H15.5M8.5 6V4M8.5 6V8M8.5 6L15.5 6M15.5 6V4M15.5 6V8"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
