import { createPopper, Placement } from '@popperjs/core';
import classNames from 'classnames';
import { ReactNode, RefObject, useEffect, useRef } from 'react';
import styles from './Tooltip.module.scss';

const isTouchScreen = (): boolean => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || (navigator as any).msMaxTouchPoints > 0;
};

interface TooltipProps {
  text: string | ReactNode;
  render: (ref: RefObject<any>) => ReactNode;
  positioningStrategy?: 'fixed' | 'absolute';
  disableOnTouch?: boolean;
  enableOnNonHoverDevices?: boolean;
  placement?: Placement;
  heightAuto?: boolean;
  variant?: 'gray' | 'white';
}

export const Tooltip = ({
  text,
  render,
  positioningStrategy = 'absolute',
  disableOnTouch = false,
  enableOnNonHoverDevices = false,
  placement = 'bottom',
  heightAuto = false,
  variant = 'gray',
}: TooltipProps): JSX.Element => {
  const buttonRef = useRef<HTMLElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!tooltipRef.current || !arrowRef.current || !buttonRef.current || (disableOnTouch && isTouchScreen()))
      return;
    const popperInstance = createPopper(buttonRef.current, tooltipRef.current, {
      placement,
      strategy: positioningStrategy,
      onFirstUpdate: (state) => {
        if (state.elements) state.elements.popper.style.opacity = '1';
      },
      modifiers: [
        { name: 'offset', options: { offset: [0, 10] } },
        { name: 'arrow', options: { element: arrowRef.current } },
      ],
    });

    buttonRef.current?.classList.add(styles.object);

    return () => {
      buttonRef.current?.classList.remove(styles.object);
      popperInstance.destroy();
    };
  }, []);

  return (
    <>
      {render(buttonRef)}
      <div
        className={classNames(styles.tooltip, {
          [styles.disableOnNonHoverDevices]: !enableOnNonHoverDevices,
          [styles.enableOnNonHoverDevices]: enableOnNonHoverDevices,
          [styles.heightAuto]: heightAuto,
          [styles.gray]: variant === 'gray',
          [styles.white]: variant === 'white',
        })}
        ref={tooltipRef}
      >
        <div className={styles.arrow} ref={arrowRef} />
        <div className={styles.text}>{text}</div>
      </div>
    </>
  );
};
