import { IconProps } from './type';

export const HighlightDisplayIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.05709 23.938C5.92504 24.1667 5.91438 24.4459 6.02859 24.684C6.14281 24.9221 6.36719 25.0885 6.62822 25.1287L15.1119 26.4344C15.438 26.4846 15.7614 26.3294 15.9264 26.0437L17.9708 22.5027L18.4325 22.7693C18.7595 22.9581 19.1738 22.8926 19.4266 22.6123L27.986 13.1204C28.1477 12.9411 28.2197 12.6983 28.182 12.4598C28.1443 12.2213 28.001 12.0125 27.7918 11.8918L14.1829 4.03471C14.1407 4.01031 14.0971 3.99021 14.0528 3.97426C13.9755 3.90872 13.8844 3.85686 13.782 3.82368C13.3617 3.68746 12.9106 3.91776 12.7744 4.33806L8.83381 16.4966L8.63423 17.1124L9.19483 17.4361L9.65696 17.7029L6.05709 23.938ZM11.1644 16.7256C11.1596 16.7227 11.1547 16.7199 11.1499 16.7171C11.145 16.7143 11.1401 16.7115 11.1352 16.7088L10.5554 16.3741L13.9916 5.77178L26.1356 12.7831L18.6719 21.06L18.088 20.7229C18.0847 20.7209 18.0814 20.719 18.078 20.717C18.0746 20.7151 18.0712 20.7132 18.0679 20.7113L14.6138 18.7171L14.6033 18.7111L11.1644 16.7256ZM11.0426 18.5029L8.02747 23.7252L14.8144 24.7698L16.5851 21.7027L13.8241 20.1087L13.8136 20.1027L11.0426 18.5029Z"
      fill={color}
    />
  </svg>
);
