import { IconProps } from './type';

export const SettingsDisplayIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0094 5.824C14.3265 3.60485 17.5305 3.60485 17.8476 5.824V5.824C18.0667 7.35748 19.9069 8.02728 21.0605 6.99342V6.99342C22.7298 5.49726 25.1843 7.55676 24.0007 9.46055V9.46055C23.1829 10.7761 24.1621 12.4721 25.7103 12.4216V12.4216C27.9508 12.3485 28.5072 15.5039 26.3768 16.2015V16.2015C24.9046 16.6835 24.5646 18.6122 25.783 19.5687V19.5687C27.5463 20.9529 25.9443 23.7276 23.864 22.8926V22.8926C22.4264 22.3156 20.9262 23.5745 21.2447 25.0904V25.0904C21.7058 27.2842 18.695 28.38 17.638 26.4031V26.4031C16.9077 25.0371 14.9493 25.0371 14.2189 26.4031V26.4031C13.1619 28.38 10.1512 27.2842 10.6122 25.0904V25.0904C10.9308 23.5745 9.43057 22.3156 7.99299 22.8926V22.8926C5.9126 23.7276 4.31059 20.9529 6.0739 19.5687V19.5687C7.29236 18.6122 6.9523 16.6835 5.48017 16.2015V16.2015C3.34979 15.5039 3.90616 12.3485 6.14666 12.4216V12.4216C7.69489 12.4721 8.67408 10.7761 7.85623 9.46055V9.46055C6.67269 7.55676 9.1271 5.49726 10.7964 6.99342V6.99342C11.95 8.02728 13.7903 7.35747 14.0094 5.824V5.824Z"
        stroke={color}
        strokeWidth="1.6"
      />
      <circle cx="16.2049" cy="15.9018" r="4.46667" stroke={color} strokeWidth="1.6" />
    </svg>
  );
};
