import { AcceptancePayload } from 'generated/graphql';
import Link from 'next/link';
import { useForm } from 'react-hook-form';
import { Button, ButtonSimple, Checkbox } from 'ui/generic';
import styles from './AcceptTerms.module.scss';

type Props = {
  onSubmit: (data: AcceptancePayload) => void;
  acceptance: AcceptancePayload;
};

export const AcceptTermsForm = ({ acceptance, onSubmit }: Props): JSX.Element => {
  const { register, handleSubmit, watch } = useForm<AcceptancePayload>({
    defaultValues: acceptance,
  });

  const terms = watch('acceptedTerms');

  return (
    <div className={styles.container}>
      <h1 className="displayHeader mb-4">Terms and conditions</h1>
      <p className="body-large mb-4">
        In order to continue you must accept our latest terms and conditions. Also, feel free to subscribe to
        our our email newsletter to keep updated with our latest product news.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="form">
        <Checkbox {...register('acceptedTerms')}>
          <span className={styles.text}>I accept the </span>
          <Link href="/pages/terms-and-conditions">
            <a className={styles.button}>
              <ButtonSimple variant="underline" size="inherit">
                terms and conditions
              </ButtonSimple>
            </a>
          </Link>
          <span className={styles.text}> and </span>
          <Link href="/pages/privacy-policy">
            <a className={styles.button}>
              <ButtonSimple variant="underline" size="inherit">
                privacy policy
              </ButtonSimple>
            </a>
          </Link>
        </Checkbox>

        <Checkbox {...register('acceptedNewsletter')}>I want the amazing newsletter</Checkbox>

        <div className="mt-4">
          <Button type="submit" disabled={!terms}>
            Continue
          </Button>
        </div>
      </form>
    </div>
  );
};
