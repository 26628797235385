import { IconProps } from './type';

export const PublicIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0213 8.80009C11.9894 8.80021 11.9575 8.80051 11.9256 8.80099C10.4137 8.84053 9.20017 10.0786 9.20017 11.6C9.20017 13.1464 10.4538 14.4 12.0002 14.4C13.5466 14.4 14.8002 13.1464 14.8002 11.6C14.8002 10.0606 13.5579 8.81142 12.0213 8.80009ZM12.0235 7.20007C12.0157 7.20003 12.0079 7.20001 12.0002 7.20001C11.9642 7.20001 11.9283 7.20044 11.8925 7.2013C8.95036 7.24762 6.04859 8.54964 2.5489 10.9393C2.18402 11.1885 2.09021 11.6863 2.33936 12.0511C2.58851 12.416 3.08628 12.5098 3.45116 12.2607C5.16123 11.093 6.66265 10.2368 8.04581 9.66823C7.76039 10.2514 7.60017 10.907 7.60017 11.6C7.60017 14.0301 9.57012 16 12.0002 16C14.4302 16 16.4002 14.0301 16.4002 11.6C16.4002 10.8905 16.2322 10.2202 15.934 9.62674C17.3267 10.195 18.8303 11.0639 20.5428 12.2565C20.9054 12.509 21.404 12.4198 21.6565 12.0572C21.909 11.6946 21.8198 11.196 21.4572 10.9435C17.9783 8.52078 15.0759 7.20454 12.0706 7.20004C12.0549 7.20001 12.0392 7.20002 12.0235 7.20007Z"
      fill={color}
    />
  </svg>
);
