import classNames from 'classnames';
import { NotificationPartsFragment } from 'generated/graphql';
import Link from 'next/link';
import { Avatar } from 'ui/generic';
import { RedDotIcon } from 'ui/icons';
import styles from './Notification.module.scss';
import { NotificationTime } from './NotificationTime';

type Props = {
  notification: NotificationPartsFragment;
};

export const GlobalNotification = ({ notification }: Props) => {
  if (!notification.url || !notification.message || !notification.updatedAt) return null;

  return (
    <Link href={notification.url}>
      <a className={styles.container}>
        <div
          className={classNames(styles.avatar, {
            [styles.unseen]: !notification.seen,
          })}
        >
          {notification.image ? (
            <Avatar image={notification.image} />
          ) : (
            <div style={{ width: 40, height: 40 }} />
          )}
          <RedDotIcon />
        </div>
        <div className={styles.content}>
          <span className={styles.text}>{notification.message}</span>
          <NotificationTime time={notification.updatedAt} />
        </div>
      </a>
    </Link>
  );
};
