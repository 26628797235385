import { IconProps } from './type';

export const ShelfIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 14V6" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <path d="M4 17H20" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <path d="M10 14L10 4" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <path d="M18 14L17 5" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <path d="M14 14L14 7" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <path d="M6 20L6 17" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    <path d="M18 20L18 17" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
  </svg>
);
