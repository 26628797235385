import { IconProps } from './type';

export const LaurelLeftIcon = ({ color = '#D9D9D9', size = 37 }: IconProps) => {
  return (
    <svg height={size} viewBox="0 0 18 37" fill={color} xmlns="http://www.w3.org/2000/svg">
      <ellipse
        cx="13.4329"
        cy="3.44427"
        rx="1.38462"
        ry="2.76924"
        transform="rotate(44.5833 13.4329 3.44427)"
      />
      <ellipse cx="10.8483" cy="35.1154" rx="1.38462" ry="2.76924" transform="rotate(-90 10.8483 35.1154)" />
      <ellipse cx="14.6546" cy="31.3076" rx="1.38462" ry="2.76924" />
      <ellipse
        cx="6.65884"
        cy="30.563"
        rx="1.38462"
        ry="2.76924"
        transform="rotate(-73.3933 6.65884 30.563)"
      />
      <ellipse
        cx="11.3923"
        cy="28.0016"
        rx="1.38462"
        ry="2.76924"
        transform="rotate(16.6068 11.3923 28.0016)"
      />
      <ellipse
        cx="3.34999"
        cy="24.3057"
        rx="1.38462"
        ry="2.76924"
        transform="rotate(-47.0649 3.34999 24.3057)"
      />
      <ellipse
        cx="8.73042"
        cy="24.1124"
        rx="1.38462"
        ry="2.76924"
        transform="rotate(42.9351 8.73042 24.1124)"
      />
      <ellipse
        cx="3.13251"
        cy="18.2161"
        rx="1.38462"
        ry="2.76924"
        transform="rotate(-35.6235 3.13251 18.2161)"
      />
      <ellipse
        cx="8.44219"
        cy="19.0931"
        rx="1.38462"
        ry="2.76924"
        transform="rotate(54.3765 8.44219 19.0931)"
      />
      <ellipse
        cx="4.47115"
        cy="11.8252"
        rx="1.38462"
        ry="2.76924"
        transform="rotate(-17.9168 4.47115 11.8252)"
      />
      <ellipse
        cx="9.26806"
        cy="14.2763"
        rx="1.38462"
        ry="2.76924"
        transform="rotate(72.0832 9.26806 14.2763)"
      />
      <ellipse cx="7.73033" cy="5.69234" rx="1.38462" ry="2.76924" />
      <ellipse cx="11.5384" cy="9.5001" rx="1.38462" ry="2.76924" transform="rotate(90 11.5384 9.5001)" />
    </svg>
  );
};
