export const SocialTikTokIcon = (): JSX.Element => (
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M28.628 15.243C31.2003 17.0883 34.3514 18.174 37.7548 18.174V11.6016C37.1107 11.6017 36.4682 11.5343 35.8381 11.4003V16.5738C32.435 16.5738 29.2843 15.488 26.7113 13.6429V27.0553C26.7113 33.7649 21.2915 39.2037 14.6061 39.2037C12.1117 39.2037 9.79316 38.4469 7.86719 37.149C10.0654 39.4046 13.1309 40.8038 16.5224 40.8038C23.2081 40.8038 28.6283 35.365 28.6283 28.6552V15.243H28.628ZM30.9924 8.6122C29.6779 7.17092 28.8148 5.30833 28.628 3.24917V2.40381H26.8117C27.2689 5.02101 28.8283 7.25699 30.9924 8.6122ZM12.0957 32.0002C11.3612 31.0338 10.9643 29.8515 10.9661 28.6359C10.9661 25.5672 13.4451 23.0791 16.5035 23.0791C17.0735 23.0789 17.6401 23.1665 18.1833 23.3395V16.6202C17.5485 16.5328 16.9078 16.4958 16.2674 16.5094V21.7394C15.7238 21.5664 15.157 21.4786 14.5868 21.4791C11.5284 21.4791 9.04954 23.967 9.04954 27.0361C9.04954 29.2062 10.2886 31.085 12.0957 32.0002Z"
      fill="#FF004F"
    />
    <path
      d="M26.7103 13.6427C29.2832 15.4879 32.4339 16.5736 35.837 16.5736V11.4002C33.9374 10.9941 32.2558 9.99788 30.9914 8.6122C28.8271 7.25685 27.2678 5.02087 26.8106 2.40381H22.0397V28.6549C22.0289 31.7152 19.5541 34.1932 16.5022 34.1932C14.7038 34.1932 13.1061 33.3329 12.0942 32.0002C10.2873 31.085 9.04823 29.2061 9.04823 27.0362C9.04823 23.9674 11.5271 21.4792 14.5855 21.4792C15.1715 21.4792 15.7363 21.5708 16.2661 21.7395V16.5095C9.6982 16.6457 4.41602 22.0314 4.41602 28.655C4.41602 31.9615 5.7314 34.959 7.86629 37.1491C9.79226 38.4469 12.1108 39.2039 14.6052 39.2039C21.2907 39.2039 26.7104 33.7648 26.7104 27.0553V13.6427H26.7103Z"
      fill="black"
    />
    <path
      d="M35.8376 11.4003V10.0014C34.1246 10.004 32.4453 9.5226 30.9919 8.61218C32.2785 10.0257 33.9726 11.0004 35.8376 11.4003ZM26.8112 2.40393C26.7676 2.15381 26.7341 1.90204 26.7108 1.64931V0.803955H20.1234V27.0553C20.1128 30.1152 17.6382 32.5932 14.5861 32.5932C13.69 32.5932 12.844 32.3798 12.0947 32.0005C13.1066 33.333 14.7043 34.1932 16.5027 34.1932C19.5543 34.1932 22.0295 31.7155 22.0402 28.6551V2.40393H26.8112ZM16.2669 16.5096V15.0204C15.7165 14.9449 15.1615 14.9071 14.6059 14.9073C7.91986 14.9072 2.5 20.3463 2.5 27.0553C2.5 31.2615 4.6301 34.9684 7.86695 37.1489C5.73206 34.9589 4.41668 31.9613 4.41668 28.655C4.41668 22.0315 9.69873 16.6458 16.2669 16.5096Z"
      fill="#00F2EA"
    />
  </svg>
);
