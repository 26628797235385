import classnames from 'classnames';
import { useScreenSize } from 'hooks/useScreenSize';
import { ReactNode, useEffect } from 'react';
import { RemoveIcon, SearchIcon } from 'ui/icons';
import styles from './SearchInput.module.scss';

type MobileInputContainerProps = {
  children: ReactNode;
  isActive?: boolean;
};

export const MobileInputContainer = ({
  children,
  isActive = true,
}: MobileInputContainerProps): JSX.Element => {
  return (
    <>
      <div className={styles.mobileInput} id="mobileSearch">
        {children}
      </div>
      <div className={classnames(styles.spacer, { [styles.active]: isActive })} />
    </>
  );
};

type Props = {
  searchTerm: string;
  onChange: (string: string) => void;
  onSubmit: (string: string) => void;
  onFocus?: React.FocusEventHandler;
  onBlur?: React.FocusEventHandler;
  onClear?: () => void;
  id?: string;
  placeholder?: string;
  withBorder?: boolean;
  autofocus?: boolean;
};

const SearchInput = ({
  searchTerm,
  onChange,
  onSubmit,
  onFocus,
  onBlur,
  onClear,
  id,
  placeholder,
  autofocus,
  withBorder = false,
}: Props): JSX.Element => {
  const { smScreen } = useScreenSize();

  const defaultPlaceholder = smScreen
    ? 'Search for books, authors, shelves...'
    : 'Search for books, authors, shelves, users...';

  useEffect(() => {
    if (onClear && searchTerm === '') onClear();
  }, [searchTerm]);

  return (
    <form
      className={classnames(styles.container, { [styles.withBorder]: withBorder })}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(searchTerm);
      }}
      action=""
    >
      <span
        className={classnames(styles.icon, {
          [styles.active]: searchTerm && searchTerm.length > 0,
        })}
      >
        <SearchIcon color="var(--textDiscreet)" />
      </span>
      <input
        data-arrow-keys
        id={id || 'searchBox'}
        type="search"
        name="query"
        className={styles.input}
        autoComplete="off"
        placeholder={placeholder || defaultPlaceholder}
        onChange={(e) => onChange(e.target.value)}
        value={searchTerm}
        autoFocus={autofocus}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {searchTerm && (
        <button type="button" className={styles.clear} onClick={() => onChange('')}>
          <RemoveIcon />
        </button>
      )}
    </form>
  );
};

export default SearchInput;
