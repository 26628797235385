import classNames from 'classnames';
import { PatronBadgeSmall } from 'components/badges/PatronBadge';
import OptionsMenu from 'components/book/options/OptionsMenu';
import { FeedbackModal } from 'containers/FeedbackModal';
import { ProfilePartsFragment } from 'generated/graphql';
import { useGlobalModalContext } from 'hooks/modals/useGlobalModal';
import { useAdvancedRoles } from 'hooks/useAdvancedRoles';
import { useAuthContext } from 'hooks/useAuth';
import { useIsSupporter } from 'hooks/useIsSupporter';
import { useState } from 'react';
import { Avatar } from 'ui/generic';
import {
  CommentIcon,
  GoalIcon,
  InviteIcon,
  LibrarianIcon,
  MoreIcon,
  OffIcon,
  PatronIcon,
  SettingsIcon,
  UserIcon,
} from 'ui/icons';
import { ActionItem, ActionList } from 'ui/specific/actionList';
import { routes } from 'utils/routes';
import styles from './ProfileOptionsDropdown.module.scss';

type Props = {
  profile?: ProfilePartsFragment | null;
};

export const ProfileOptionsDropdown = ({ profile }: Props): JSX.Element | null => {
  const [isOpen, setOpen] = useState(false);
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
  const { logout, inviteLinks } = useAuthContext();
  const { setSettingsModal } = useGlobalModalContext();
  const { isLibrarian, getLibrarianUrl } = useAdvancedRoles();

  if (!profile) return null;

  return (
    <>
      <OptionsMenu
        noScroll
        closeOnSelect={true}
        positioningStrategy="fixed"
        isOpen={isOpen}
        setOpen={setOpen}
        button={
          <div>
            <ProfileOptionsButton profile={profile} isActive={isOpen} />
          </div>
        }
      >
        <ActionList>
          <ActionItem
            item={{
              title: 'View profile',
              icon: <UserIcon />,
              href: routes.profile,
              as: `/${profile.handle}`,
            }}
          />
          <ActionItem
            item={{
              title: 'Goals',
              icon: <GoalIcon />,
              href: routes.goals,
              as: routes.goals,
            }}
          />
          {/* <ActionItem
            item={{
              title: 'Blog',
              icon: <BlogIcon />,
              href: routes.blog,
              as: routes.blog,
            }}
          /> */}
          <ActionItem
            item={{
              title: 'Settings',
              icon: <SettingsIcon />,
              onClick: () => setSettingsModal(true),
            }}
          />

          {inviteLinks?.length > 0 && (
            <ActionItem
              item={{
                title: 'Invite',
                icon: <InviteIcon />,
                href: `/invite`,
                as: `/invite`,
              }}
            />
          )}
          <ActionItem item={{ divider: 'full' }} />
          <ActionItem
            item={{
              title: 'Feedback',
              icon: <CommentIcon />,
              onClick: () => {
                setIsFeedbackModalVisible(true);
              },
            }}
          />
          {isLibrarian ? (
            <>
              <ActionItem
                item={{
                  title: 'Librarian mode',
                  icon: <LibrarianIcon />,
                  href: getLibrarianUrl(),
                  as: getLibrarianUrl(),
                }}
              />
            </>
          ) : (
            <ActionItem
              item={{
                title: 'Become a librarian',
                icon: <LibrarianIcon />,
                href: routes.librarianInfo,
                as: routes.librarianInfo,
              }}
            />
          )}

          <ActionItem
            item={{
              title: 'Become a Patron',
              icon: <PatronIcon />,
              href: routes.patrons,
              as: routes.patrons,
            }}
          />

          <ActionItem item={{ divider: 'full' }} />
          <ActionItem
            item={{
              title: 'Log out',
              icon: <OffIcon />,
              onClick: () => {
                logout();
              },
            }}
          />
        </ActionList>
      </OptionsMenu>
      {isFeedbackModalVisible && <FeedbackModal setVisible={setIsFeedbackModalVisible} />}
    </>
  );
};

export const ProfileOptionsButton = ({
  profile,
  isActive,
}: Props & { isActive: boolean }): JSX.Element | null => {
  const isSupporter = useIsSupporter({ profileId: profile?.id });

  if (!profile) return null;

  return (
    <div className={styles.loggedIn} role="settings-menu">
      <span
        className={classNames(styles.handle, {
          [styles.active]: isActive,
        })}
      >
        <MoreIcon />
      </span>

      <div className={styles.avatar}>
        <Avatar
          width={35}
          profile={profile}
          avatarSticker={isSupporter ? <PatronBadgeSmall noLink /> : undefined}
        />
      </div>
    </div>
  );
};
