import { ProfilePartsFragment } from 'generated/graphql';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

export const getTestUsers = (): string[] => {
  const testUserHandles: string[] = publicRuntimeConfig.testUserHandles
    ? publicRuntimeConfig.testUserHandles.split(',')
    : [];

  return testUserHandles;
};

export const isTestUserHandle = (handle: string): boolean => {
  return getTestUsers().includes(handle);
};

export const isNotTestProfile = (profile: ProfilePartsFragment): boolean => {
  return !(
    isTestUserHandle(profile.handle) ||
    profile.name.toLowerCase().includes('goodreads') ||
    profile.handle.toLowerCase().includes('goodreads') ||
    profile.name.toLowerCase().includes('test') ||
    profile.handle.toLowerCase().includes('test') ||
    profile.handle === 'literal'
  );
};
