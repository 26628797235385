import { IconProps } from './type';

export const MyBookDataIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.79463 3.19995H2.99463V3.99995V17V17.8H3.79463H8.40903V16.2H4.59463V4.79995H11.7484C12.4111 4.79995 12.9484 5.33721 12.9484 5.99995V8.47275H14.5484V5.99995C14.5484 4.45356 13.2948 3.19995 11.7484 3.19995H3.79463Z"
      fill={color}
    />
    <line
      x1="10.1214"
      y1="7.8"
      x2="7.42134"
      y2="7.8"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M18.0234 18.1886C18.0234 18.1886 17.3493 18.3683 15.9912 18.3683C14.6331 18.3684 13.915 18.1886 13.915 18.1886"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <rect x="10.809" y="10.8728" width="10.3201" height="10.3201" rx="4.2" stroke={color} strokeWidth="1.6" />
    <rect x="12.8647" y="13.3741" width="1.6" height="3.0034" rx="0.8" fill={color} />
    <rect x="17.4736" y="13.3741" width="1.6" height="3.0034" rx="0.8" fill={color} />
  </svg>
);
