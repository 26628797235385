import { IconProps } from './type';

export const LibrarianIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.36867 4.44222C9.56626 4.8374 9.40608 5.31794 9.0109 5.51553L7.70632 6.16783C7.11909 6.46144 6.69368 7.00198 6.54632 7.64177L5.47417 12.2966C5.94746 12.1002 6.4665 11.9918 7.01086 11.9918C8.85835 11.9918 10.4141 13.2407 10.8799 14.9404L10.947 14.918C11.727 14.658 12.5703 14.658 13.3504 14.918L13.417 14.9402C13.8828 13.2407 15.4386 11.9918 17.286 11.9918C17.8304 11.9918 18.3494 12.1002 18.8227 12.2966L17.7506 7.64177C17.6032 7.00198 17.1778 6.46144 16.5906 6.16783L15.286 5.51553C14.8908 5.31794 14.7306 4.8374 14.9282 4.44222C15.1258 4.04704 15.6063 3.88686 16.0015 4.08445L17.3061 4.73674C18.3204 5.24389 19.0552 6.17755 19.3097 7.28265L20.933 14.3304C21.1669 14.8396 21.2974 15.4061 21.2974 16.0031C21.2974 18.2185 19.5014 20.0145 17.286 20.0145C15.2713 20.0145 13.6034 18.5291 13.3178 16.5937L12.8444 16.4359C12.3928 16.2854 11.9046 16.2854 11.453 16.4359L10.979 16.5939C10.6933 18.5292 9.02555 20.0145 7.01086 20.0145C4.79545 20.0145 2.99951 18.2185 2.99951 16.0031C2.99951 15.4061 3.12993 14.8396 3.36384 14.3304L4.98714 7.28265C5.24168 6.17755 5.97647 5.24389 6.99077 4.73674L8.29536 4.08445C8.69054 3.88686 9.17108 4.04704 9.36867 4.44222ZM14.8749 16.0384C14.8938 17.3539 15.966 18.4145 17.286 18.4145C18.5894 18.4145 19.6513 17.3803 19.6959 16.0877L19.4157 14.8711C19.0102 14.1099 18.2086 13.5918 17.286 13.5918C15.9662 13.5918 14.894 14.6522 14.8749 15.9675C14.876 15.991 14.876 16.0147 14.8749 16.0384ZM7.01086 13.5918C8.33407 13.5918 9.40835 14.6576 9.42207 15.9775C9.42153 15.9944 9.42153 16.0114 9.42208 16.0284C9.40852 17.3485 8.33418 18.4145 7.01086 18.4145C5.70743 18.4145 4.64554 17.3803 4.60097 16.0877L4.88119 14.8711C5.28663 14.1099 6.08823 13.5918 7.01086 13.5918Z"
      fill={color}
    />
  </svg>
);
