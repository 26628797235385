import { IconProps } from './type';

export const LibrarianDisplayIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2527 6.0425C12.4502 6.43768 12.2901 6.91822 11.8949 7.11581L9.73394 8.19629C9.14671 8.4899 8.72131 9.03044 8.57395 9.67023L6.91444 16.8751C7.63702 16.4804 8.46602 16.256 9.34742 16.256C11.7861 16.256 13.8236 17.9738 14.3159 20.2653L14.9962 20.0386C15.7762 19.7785 16.6195 19.7785 17.3995 20.0386L18.0793 20.2651C18.5716 17.9736 20.609 16.256 23.0476 16.256C23.929 16.256 24.758 16.4804 25.4806 16.8751L23.8211 9.67023C23.6737 9.03044 23.2483 8.4899 22.6611 8.19629L20.5002 7.11581C20.105 6.91822 19.9448 6.43768 20.1424 6.0425C20.34 5.64732 20.8205 5.48714 21.2157 5.68473L23.3767 6.7652C24.391 7.27235 25.1258 8.20602 25.3803 9.31111L27.6567 19.1946C27.9601 19.8458 28.1294 20.572 28.1294 21.3378C28.1294 24.1444 25.8542 26.4196 23.0476 26.4196C20.4397 26.4196 18.2905 24.455 17.9994 21.9251L16.8936 21.5564C16.442 21.4059 15.9538 21.4059 15.5022 21.5564L14.3956 21.9253C14.1044 24.4551 11.9553 26.4196 9.34742 26.4196C6.54082 26.4196 4.26562 24.1444 4.26562 21.3378C4.26562 20.572 4.43499 19.8458 4.73831 19.1946L7.01477 9.31111C7.2693 8.20602 8.0041 7.27235 9.0184 6.7652L11.1793 5.68473C11.5745 5.48714 12.0551 5.64732 12.2527 6.0425ZM19.5661 21.379C19.5882 23.2829 21.1385 24.8196 23.0476 24.8196C24.9417 24.8196 26.4825 23.3072 26.5284 21.4241L26.1393 19.7347C25.5593 18.6185 24.3925 17.856 23.0476 17.856C21.1386 17.856 19.5884 19.3924 19.5661 21.2961C19.5675 21.3236 19.5675 21.3512 19.5661 21.379ZM9.34742 17.856C11.2606 17.856 12.8134 19.3991 12.8291 21.3086C12.8284 21.3278 12.8284 21.3471 12.8291 21.3665C12.8137 23.2762 11.2608 24.8196 9.34742 24.8196C7.45336 24.8196 5.91253 23.3072 5.86668 21.4241L6.2558 19.7347C6.83578 18.6185 8.00256 17.856 9.34742 17.856Z"
      fill={color}
    />
  </svg>
);
