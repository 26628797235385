import classNames from 'classnames';
import { ComponentProps, ComponentPropsWithRef, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';
import TextareaAutosize, { TextareaAutosizeProps } from 'react-textarea-autosize';
import styles from './FormFields.module.scss';

type Size = 'small' | 'medium' | 'large';

type Props = {
  name: string;
  id?: string;
  placeholder: string;
  disabled?: boolean;
  label?: string;
  value?: string;
  setValue?: (newVal: string) => void;
  sizing?: Size; // todo: implement all the different sizes when they're defined
  containerClassName?: string | undefined;
  error?: FieldError;
};

const noop = (): void => {};

const Input = forwardRef<HTMLInputElement, Props & ComponentProps<'input'>>(
  (
    {
      placeholder,
      name,
      id,
      label,
      disabled = false,
      value,
      setValue = noop,
      sizing = 'medium',
      containerClassName,
      error,
      ...rest
    },
    ref
  ) => {
    return (
      <div className={classNames(styles.inputContainer, containerClassName)}>
        {error ? <Error>{error.message}</Error> : <>{label && <Label>{label}</Label>}</>}
        <input
          id={id}
          name={name}
          placeholder={placeholder}
          ref={ref}
          className={classNames('uiBase', styles.input, styles[sizing], { [styles.inputError]: !!error })}
          disabled={disabled}
          {...(value !== undefined && {
            onChange: (e) => {
              setValue(e.target.value);
            },
            value,
          })}
          {...rest}
        />
      </div>
    );
  }
);

Input.displayName = 'Input';

const TextArea = forwardRef<HTMLTextAreaElement, Props & ComponentProps<'textarea'>>(
  (
    {
      placeholder,
      name,
      id,
      label,
      disabled = false,
      value,
      setValue = noop,
      error,
      containerClassName,
      ...rest
    },
    ref
  ) => {
    return (
      <div className={classNames(styles.inputContainer, containerClassName)}>
        {error ? <Error>{error.message}</Error> : <>{label && <Label>{label}</Label>}</>}
        <textarea
          id={id}
          name={name}
          placeholder={placeholder}
          ref={ref}
          className={classNames('uiBase', styles.input, styles.textarea, { [styles.inputError]: !!error })}
          disabled={disabled}
          {...(value !== undefined && {
            onChange: (e) => {
              setValue(e.target.value);
            },
            value,
          })}
          {...rest}
        />
      </div>
    );
  }
);

TextArea.displayName = 'TextArea';

const TextAreaAutoExpand = forwardRef<
  HTMLTextAreaElement,
  Props & TextareaAutosizeProps & ComponentPropsWithRef<'textarea'>
>(({ placeholder, name, id, label, disabled = false, value, setValue = noop, error, ...rest }, ref) => {
  return (
    <div className={styles.inputContainer}>
      {error ? <Error>{error.message}</Error> : <>{label && <Label>{label}</Label>}</>}
      <TextareaAutosize
        id={id}
        name={name}
        placeholder={placeholder}
        ref={ref}
        className={classNames('uiBase', styles.input, styles.textarea, styles.autoexpand, {
          [styles.inputError]: !!error,
        })}
        disabled={disabled}
        {...(value !== undefined && {
          onChange: (e) => {
            setValue(e.target.value);
          },
          value,
        })}
        {...rest}
      />
    </div>
  );
});

TextAreaAutoExpand.displayName = 'TextAreaAutoExpand';

const Label = ({ id, children, ...rest }: ComponentProps<'label'>): JSX.Element => {
  return (
    <label className={styles.label} htmlFor={id} {...rest}>
      {children}
    </label>
  );
};

const Error = ({ id, children, ...rest }: ComponentProps<'label'>): JSX.Element => {
  return (
    <span className={classNames(styles.label, styles.errorLabel)} htmlFor={id} {...rest}>
      {children}
    </span>
  );
};

export { Input, TextArea, TextAreaAutoExpand, Label };
