import { LeftIcon } from 'ui/icons';

import styles from './ModalWizard.module.scss';

export type ModalWizardConfig = {
  currentStep: number;
  totalSteps: number;
  goBack: () => void;
  goNext: () => void;
};

type Props = {
  wizardConfig: ModalWizardConfig;
};

const ModalWizard = ({ wizardConfig }: Props): JSX.Element => {
  const { currentStep, totalSteps, goBack } = wizardConfig;

  const stroke = 2;
  const radius = 23;
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const progress = (100 / totalSteps) * currentStep;

  const strokeDashoffset = circumference - (progress / 100) * circumference;
  return (
    <div className={styles.container}>
      <div className={styles.progress}>
        <svg height={radius * 2} width={radius * 2}>
          <circle
            className={styles.circle}
            stroke="var(--uiInput)"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={circumference + ' ' + circumference}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <circle
            className={styles.circle}
            stroke="var(--accentPrimary)"
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={circumference + ' ' + circumference}
            style={{ strokeDashoffset }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
        </svg>
        <span className={styles.stepCount}>
          {currentStep}/{totalSteps}
        </span>
      </div>
      {wizardConfig.currentStep > 1 && (
        <button className={styles.back} onClick={goBack}>
          <LeftIcon />
          Back
        </button>
      )}
    </div>
  );
};

export default ModalWizard;
