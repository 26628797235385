import { IconProps } from './type';

export const SortIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 7L7.5 20M7.5 20L3 15.8889M7.5 20L12 15.8889"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M16.5 17L16.5 4M16.5 4L21 8.11111M16.5 4L12 8.11111"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </svg>
);
