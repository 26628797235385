/*
 * This file contains all functions that use the mixpanel api
 */
import { ProfilePartsFragment, ReadingStatus } from 'generated/graphql';
import { useDeviceDetectContext } from 'hooks/useDeviceDetect';
import mixpanel from 'mixpanel-browser';
import getConfig from 'next/config';
import { isTestUserHandle } from 'utils/testUsers';

const { publicRuntimeConfig } = getConfig();
const MIXPANEL_PROJECT_TOKEN = 'fb9ea4bc99e7f5926f41d8d8e911c94e';
const MIXPANEL_PROXY_DOMAIN = 'https://scout.literal.club';

let isTestUser = false;
let isInitialized = false;

export const readableReadingStatus = {
  [ReadingStatus.WantsToRead]: 'Want to read',
  [ReadingStatus.IsReading]: 'Reading',
  [ReadingStatus.Finished]: 'Read',
  [ReadingStatus.Dropped]: 'Did not finish',
  [ReadingStatus.None]: 'Cleared',
};

type Props = Record<string, unknown>;

const useMixpanel = () => {
  const { isMobileAppV1, isMobileApp } = useDeviceDetectContext();

  const identifyUser = (profile: ProfilePartsFragment, additionalProfileProps?: Props): void => {
    // No mixpanel should be used
    if (!publicRuntimeConfig.mixpanelToggle || !profile) {
      return;
    }

    // Skip for test users
    if (isTestUserHandle(profile.handle)) {
      isTestUser = true;
      return;
    }

    // Opt-in
    isInitialized = true;
    mixpanel.init(MIXPANEL_PROJECT_TOKEN, { api_host: MIXPANEL_PROXY_DOMAIN, batch_requests: true });
    mixpanel.register({ Platform: isMobileApp || isMobileAppV1 ? 'Mobile' : 'Web' });
    mixpanel.identify(profile.handle);
    if (!mixpanel.has_opted_in_tracking()) {
      mixpanel.opt_in_tracking();
    }

    setProfileProperties(profile.handle, {
      name: profile.name,
      $avatar: profile.image,
      ...(additionalProfileProps || {}),
    });
  };

  const setProfileProperties = (handle: string, props: Props) => {
    if (!mixpanel || !mixpanel.people || !isInitialized || isTestUser) return;
    const profileProps: Props = {
      USER_ID: handle,
      ...props,
    };
    mixpanel.people.set(profileProps);
  };

  function trackEvent(eventName: string, props?: Props) {
    if (!mixpanel || !mixpanel.track || !isInitialized || isTestUser) return;
    mixpanel.track(eventName, props);
  }

  return {
    trackEvent,
    identifyUser,
    setProfileProperties,
  };
};

export default useMixpanel;
