import { IconProps } from './type';

export const BuyIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.91379 10.4052C4.96267 9.89195 5.39373 9.5 5.90929 9.5H18.0907C18.6063 9.5 19.0373 9.89196 19.0862 10.4052L19.8957 18.9052C19.9516 19.4923 19.49 20 18.9002 20H5.09976C4.51002 20 4.04835 19.4923 4.10427 18.9052L4.91379 10.4052Z"
      stroke={color}
      strokeWidth="1.6"
    />
    <path
      d="M8.57535 12.5C8.57535 12.9418 8.93352 13.3 9.37535 13.3C9.81718 13.3 10.1754 12.9418 10.1754 12.5H8.57535ZM13.8254 12.5C13.8254 12.9418 14.1835 13.3 14.6254 13.3C15.0672 13.3 15.4254 12.9418 15.4254 12.5H13.8254ZM10.1754 12.5C10.1754 11.4656 10.1518 10.5731 10.1636 9.72986C10.1751 8.89778 10.2217 8.21621 10.3433 7.67561C10.4646 7.13666 10.643 6.8186 10.8561 6.62918C11.0569 6.45069 11.3861 6.3 12.0004 6.3V4.7C11.1146 4.7 10.3657 4.92431 9.79308 5.43332C9.23275 5.9314 8.94235 6.61334 8.78236 7.32439C8.62275 8.03379 8.57559 8.85222 8.56371 9.70764C8.55198 10.5519 8.57535 11.5344 8.57535 12.5H10.1754ZM12.0004 6.3C12.6146 6.3 12.9438 6.45069 13.1446 6.62918C13.3577 6.8186 13.5361 7.13666 13.6574 7.67561C13.779 8.21621 13.8256 8.89778 13.8371 9.72986C13.8489 10.5731 13.8254 11.4656 13.8254 12.5H15.4254C15.4254 11.5344 15.4487 10.5519 15.437 9.70764C15.4251 8.85221 15.378 8.03379 15.2183 7.32439C15.0584 6.61334 14.768 5.9314 14.2076 5.43332C13.635 4.92431 12.8861 4.7 12.0004 4.7V6.3Z"
      fill={color}
    />
  </svg>
);
