import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from './Tag.module.scss';

type Props = {
  children?: ReactNode;
  icon?: ReactNode;
  noHover?: boolean;
  variant?: 'solid' | 'outline' | 'solid-alt';
  discreet?: boolean;
};

export const Tag = ({ children, icon, noHover = false, variant = 'solid', discreet }: Props): JSX.Element => {
  return (
    <div
      className={classNames(styles.tag, styles[variant], {
        [styles.hover]: !noHover,
        [styles.iconOnly]: !children,
        [styles.textOnly]: !icon,
        [styles.discreet]: discreet,
      })}
    >
      {icon && icon}
      {children && <div className={styles.text}>{children}</div>}
    </div>
  );
};
