import { ReactNode, useEffect, useState } from 'react';
import { Portal } from 'react-portal';

type Props = {
  elementId: string;
  children: ReactNode;
};
export const Slot = ({ elementId, children }: Props): JSX.Element | null => {
  const [, forceRerender] = useState(false);
  useEffect(() => {
    if (!!document.getElementById(elementId)) {
      forceRerender(true);
    }
  });
  // ssr pages need to check for undefined document first
  if (typeof document === 'undefined') {
    return null;
  } else {
    return <Portal node={document && document.getElementById(elementId)}>{children}</Portal>;
  }
};

export default Slot;
