export const CrownIcon = ({ color = 'currentColor' }): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.40174 9.18822C4.2546 8.30537 5.25246 7.68866 5.97631 8.21509L7.9943 9.68272C8.47311 10.0309 9.14794 9.88925 9.44625 9.37786L11.1362 6.48076C11.5221 5.81926 12.4779 5.81926 12.8638 6.48076L14.5538 9.37786C14.8521 9.88925 15.5269 10.0309 16.0057 9.68272L18.0237 8.21509C18.7475 7.68866 19.7454 8.30537 19.5983 9.18823L18.3615 16.6088C18.2811 17.091 17.8639 17.4444 17.3751 17.4444H6.6249C6.13606 17.4444 5.71888 17.091 5.63851 16.6088L4.40174 9.18822Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinejoin="round"
    />
  </svg>
);
