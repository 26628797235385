import classNames from 'classnames';
import { ReactNode } from 'react';
import styles from './LoadingContainer.module.scss';

type Props = {
  children: ReactNode;
  loading?: boolean;
};

const LoadingContainer = ({ children, loading }: Props): JSX.Element => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.loading]: loading,
      })}
    >
      {children}
    </div>
  );
};

export default LoadingContainer;
