import { useState } from 'react';
import { isBrowser } from 'utils/device';

type CookieOptions = {
  days: number;
  path: string;
};

const optionsDefaults: CookieOptions = {
  days: 2,
  path: '/',
};

export function getCookie(
  name: string,
  initialValue: string | undefined,
  options: CookieOptions
): string | undefined {
  if (isBrowser()) {
    const cookie = document.cookie.split('; ').reduce((r, v) => {
      const parts = v.split('=');
      return parts[0] === name ? decodeURIComponent(parts[1]) : r;
    }, '');
    if (cookie) {
      return cookie;
    }

    if (initialValue) {
      setCookie(name, initialValue, options);
      return initialValue;
    }
  }
}

export function setCookie(name: string, value: string, options: CookieOptions): void {
  if (!isBrowser()) return;
  const expires = new Date(Date.now() + options.days * 864e5).toUTCString();
  const result = encodeURIComponent(value);
  document.cookie = `${name}=${result}; expires=${expires}; path=${options.path}; SameSite=Lax`;
}

export function deleteCookie(name: string, options: CookieOptions = optionsDefaults): void {
  if (!isBrowser()) return;
  const expires = 'Thu, 01 Jan 1970 00:00:00 GMT';
  document.cookie = `${name}= ; expires=${expires}; path=${options.path}; SameSite=Lax`;
}

export function useCookie(
  key: string,
  initialValue: string | undefined = undefined,
  options = optionsDefaults
): [string | undefined, (value: string) => void, () => void] {
  const [item, setItem] = useState<string | undefined>(() => {
    return getCookie(key, initialValue, options);
  });

  const updateItem = (value: string) => {
    setItem(value);
    setCookie(key, value, options);
  };

  const removeItem = () => {
    setItem(undefined);
    deleteCookie(key, options);
  };

  return [item, updateItem, removeItem];
}
