import useGetBook from 'containers/BookModals/bookModalHooks';
import { useGoalParticipations } from 'containers/BookModals/useGoalParticipations';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { FC, useContext, useEffect, useRef } from 'react';
import Modal from 'ui/specific/modal/Modal';
import { ModalWizardConfig } from 'ui/specific/modal/ModalWizard';
import { BooleanParam, createEnumParam, StringParam, useQueryParams } from 'use-query-params';
import { routes } from 'utils/routes';
import { useAuthContext } from '../useAuth';

const GoalsModal = dynamic(() => import('containers/BookModals/GoalsModal'));
const ReviewModal = dynamic(() => import('containers/BookModals/ReviewModal'));
const ShareModal = dynamic(() => import('containers/BookModals/ShareModal'));

export enum MODALS {
  review = 'review',
  bookGoals = 'book-goals',
  bookShare = 'book-share',
}

const ModalParam = createEnumParam(Object.values(MODALS));

export const MODAL_ORDER = [MODALS.review, MODALS.bookGoals, MODALS.bookShare];

type State = {
  startBookFinishedWizard: (bookId: string) => void;
  setReviewModal: (val: boolean, bookId: string) => void;
  setBookGoalsModal: (val: boolean, bookId: string) => void;
  setBookShareModal: (val: boolean, bookId: string) => void;
};

export const ModalContext = React.createContext<State | undefined>(undefined);

export const BookModalProvider: FC = ({ children }) => {
  const router = useRouter();
  const [params, setParams] = useQueryParams({ m: ModalParam, finished: BooleanParam, id: StringParam });
  const activeModal = params.m;
  const isWizardMode = params.finished;
  const bookId = params.id;
  const book = useGetBook(bookId);

  const { isSignedIn, profile } = useAuthContext();

  // We preload the goals, so we can skip the modal if needed
  const now = useRef(new Date().toISOString());
  const { participations } = useGoalParticipations(profile?.handle, {
    earliestEndDate: now.current,
  });

  useEffect(() => {
    // ATTENTION: This will only check the first page of goals (see PAGE_SIZE)
    if (participations?.length === 0 || participations?.every((goal) => goal.goal.autoUpdate)) {
      const goalIndex = MODAL_ORDER.indexOf(MODALS.bookGoals);
      if (goalIndex !== -1) {
        MODAL_ORDER.splice(MODAL_ORDER.indexOf(MODALS.bookGoals), 1);
      }
    }
  }, [participations]);

  const close = () => {
    setParams({ m: undefined, id: undefined, finished: undefined }, 'replaceIn');
  };
  const protectedClose = () => {
    const areYouSure = confirm('Are you sure you want to leave?');
    if (areYouSure) close();
  };
  const startBookFinishedWizard = (bookId: string) => {
    setParams({ finished: true, id: bookId, m: MODAL_ORDER[0] }, 'replaceIn');
  };
  const setReviewModal = (val: boolean, bookId: string) =>
    setParams({ m: val ? MODALS.review : undefined, id: bookId });
  const setBookGoalsModal = (val: boolean, bookId: string) =>
    setParams({ m: val ? MODALS.bookGoals : undefined, id: bookId });
  const setBookShareModal = (val: boolean, bookId: string) =>
    setParams({ m: val ? MODALS.bookShare : undefined, id: bookId });

  // Wizard stuff:
  const indexOfActiveModal = activeModal ? MODAL_ORDER.indexOf(activeModal) : -1;
  const indexOfNextModal = Math.min(indexOfActiveModal + 1, MODAL_ORDER.length);
  const indexOfPrevModal = Math.max(indexOfActiveModal - 1, 0);
  const isLastModal = indexOfActiveModal === MODAL_ORDER.length - 1;
  const nextModal = MODAL_ORDER[indexOfNextModal];
  const prevModal = MODAL_ORDER[indexOfPrevModal];
  const finishWizard = () => {
    if (book) {
      // We redirect the user to the correct book page, so edition changes are reflected correctly
      // This is required so read dates and audiobook tag are shown correctly across editions
      router.replace(routes.userOwnedBook, `/${profile?.handle}/book/${book?.slug}`);
    }
  };

  const wizardConfig: ModalWizardConfig | undefined = isWizardMode
    ? {
        currentStep: activeModal ? MODAL_ORDER.indexOf(activeModal) + 1 : 0,
        totalSteps: MODAL_ORDER.length,
        goBack: () => setParams({ m: prevModal }),
        goNext: () => {
          if (isLastModal) {
            finishWizard();
          } else {
            setParams({ m: nextModal });
          }
        },
      }
    : undefined;

  return (
    <>
      <ModalContext.Provider
        value={{
          startBookFinishedWizard,
          setReviewModal,
          setBookGoalsModal,
          setBookShareModal,
        }}
      >
        {children}

        {activeModal && isSignedIn && book && (
          <Modal close={protectedClose} wizardConfig={wizardConfig} maxWidth={640}>
            {activeModal === MODALS.review && (
              <ReviewModal
                book={book}
                setBookId={(val: string) => setParams({ id: val })}
                wizardConfig={wizardConfig}
                close={close}
              />
            )}
            {activeModal === MODALS.bookGoals && (
              <GoalsModal book={book} wizardConfig={wizardConfig} close={close} />
            )}
            {activeModal === MODALS.bookShare && (
              <ShareModal book={book} wizardConfig={wizardConfig} close={close} />
            )}
          </Modal>
        )}
      </ModalContext.Provider>
    </>
  );
};

export const useBookModalContext = (): State => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useBookModalContext must be used within a ModalContext');
  }
  return context;
};
