import constate from 'constate';
import { useEffect, useState } from 'react';
import {
  isDesktop,
  isMobileApp,
  isMobileAppAndroid,
  isMobileAppIOs,
  isMobileAppV1,
  isShareable,
  isSsr,
} from 'utils/device';

type State = {
  isDeviceDetected: boolean;
  isAndroid: boolean;
  isIos: boolean;
  isOpera: boolean;
  isWindows: boolean;
  isSSR: boolean;
  /**
   * @deprecated
   */
  isMobileAppV1: boolean;
  isMobileApp: boolean;
  isMobileAppAndroid: boolean;
  isMobileAppIOs: boolean;
  isDesktop: boolean;
  isShareable: boolean;
};

const useDeviceDetect = (): State => {
  const [state, setState] = useState<State>({
    isDeviceDetected: false,
    isAndroid: false,
    isIos: false,
    isOpera: false,
    isWindows: false,
    isSSR: false,
    isMobileAppV1: false,
    isMobileApp: false,
    isMobileAppAndroid: false,
    isMobileAppIOs: false,
    isDesktop: false,
    isShareable: isShareable(),
  });

  useEffect(() => {
    setTimeout(() => {
      setState({
        ...state,
        isDeviceDetected: true,
        isSSR: isSsr(),
        isMobileAppV1: isMobileAppV1(),
        isMobileApp: isMobileApp(),
        isMobileAppAndroid: isMobileAppAndroid(),
        isMobileAppIOs: isMobileAppIOs(),
        isDesktop: isDesktop(),
        isShareable: isShareable(),
      });
    });
  }, []);

  return state;
};

export const [DeviceDetectProvider, useDeviceDetectContext] = constate(useDeviceDetect);
