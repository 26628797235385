import {
  ActivityDocument,
  ActivityQuery,
  ActivityQueryVariables,
  AuthorBySlugOrIdDocument,
  AuthorBySlugOrIdQuery,
  AuthorBySlugOrIdQueryVariables,
  AuthorPartsFragment,
  BookByRandomDocument,
  BookByRandomQuery,
  BookByRandomQueryVariables,
  BookInClubActivityFullFragment,
  BookOnShelfActivityFullFragment,
  BookPartsFragment,
  BookReviewActivityFullFragment,
  BooksByAuthorDocument,
  BooksByAuthorQuery,
  BooksByAuthorQueryVariables,
  BooksByShelfDocument,
  BooksByShelfQuery,
  BooksByShelfQueryVariables,
  ClubDocument,
  ClubPartsFragment,
  ClubPostActivityFullFragment,
  ClubPreviewDocument,
  ClubPreviewFragment,
  ClubPreviewQuery,
  ClubPreviewQueryVariables,
  ClubQuery,
  ClubQueryVariables,
  CompleteGoalActivityFullFragment,
  CreateGoalActivityFullFragment,
  GetBookDocument,
  GetBookQuery,
  GetBookQueryVariables,
  GetClubBooksCountDocument,
  GetClubBooksCountQuery,
  GetClubBooksCountQueryVariables,
  GetClubMembershipCountDocument,
  GetClubMembershipCountQuery,
  GetClubMembershipCountQueryVariables,
  GetMomentDocument,
  GetMomentQuery,
  GetMomentQueryVariables,
  GetMomentsByProfileAndBookDocument,
  GetMomentsByProfileAndBookQuery,
  GetMomentsByProfileAndBookQueryVariables,
  GetMyProfileDocument,
  GetMyProfileQuery,
  GetMyProfileQueryVariables,
  GetProfileByHandleDocument,
  GetProfileByHandleQuery,
  GetProfileByHandleQueryVariables,
  GetProfileByIdDocument,
  GetProfileByIdQuery,
  GetProfileByIdQueryVariables,
  GetReviewByIdDocument,
  GetReviewByIdQuery,
  GetReviewByIdQueryVariables,
  GetReviewQuery,
  GetReviewQueryVariables,
  GetShelfBySlugDocument,
  GetShelfBySlugQuery,
  GetShelfBySlugQueryVariables,
  GoalBySlugDocument,
  GoalBySlugQuery,
  GoalBySlugQueryVariables,
  InviteLinkDocument,
  InviteLinkQuery,
  InviteLinkQueryVariables,
  MomentActivityFullFragment,
  MyProfilePartsFragment,
  PostDocument,
  PostQuery,
  PostQueryVariables,
  ProfilePartsFragment,
  ReadingStateActivityFullFragment,
  RecBooksCurrentlyReadDocument,
  RecBooksCurrentlyReadQuery,
  RecBooksCurrentlyReadQueryVariables,
  RecentBooksByProfileIdDocument,
  RecentBooksByProfileIdQuery,
  RecentBooksByProfileIdQueryVariables,
  RecShelvesRecentlyActiveDocument,
  RecShelvesRecentlyActiveQuery,
  RecShelvesRecentlyActiveQueryVariables,
  ShelfFullFragment,
  SupportersDocument,
  SupportersQuery,
  SupportersQueryVariables,
  YearRewind2022Document,
  YearRewind2022Query,
  YearRewind2022QueryVariables,
} from 'generated/graphql';
import { NextRequest } from 'types/next';
import getApolloClient from './getApolloClient';
import { notEmpty } from './typeGuards';

export async function getBookBySlug(slug: string): Promise<BookPartsFragment | null> {
  const client = getApolloClient();

  const { data } = await client.query<GetBookQuery, GetBookQueryVariables>({
    query: GetBookDocument,
    variables: { slug },
  });
  return data.book || null;
}

export async function getAuthorBySlugOrId(slugOrId: string): Promise<AuthorPartsFragment | null> {
  const client = getApolloClient();

  const { data } = await client.query<AuthorBySlugOrIdQuery, AuthorBySlugOrIdQueryVariables>({
    query: AuthorBySlugOrIdDocument,
    variables: { slugOrId },
  });
  return data.authorBySlugOrId || null;
}

export async function getBooksByAuthor(authorId: string): Promise<BookPartsFragment[]> {
  const client = getApolloClient();

  const { data } = await client.query<BooksByAuthorQuery, BooksByAuthorQueryVariables>({
    query: BooksByAuthorDocument,
    variables: { authorId, limit: 20, offset: 0 },
  });
  return data.booksByAuthor;
}

export async function getBookByRandom(): Promise<BookPartsFragment | null> {
  const client = getApolloClient();

  const { data } = await client.query<BookByRandomQuery, BookByRandomQueryVariables>({
    query: BookByRandomDocument,
  });
  return data.bookByRandom || null;
}

export async function getProfileByHandle(handle: string): Promise<ProfilePartsFragment | null> {
  const client = getApolloClient();

  const res = await client
    .query<GetProfileByHandleQuery, GetProfileByHandleQueryVariables>({
      query: GetProfileByHandleDocument,
      variables: { handle },
    })
    .catch((err) => console.log(err));
  return res?.data?.profileByHandle || null;
}

export async function getProfileById(profileId: string): Promise<ProfilePartsFragment | null> {
  const client = getApolloClient();

  const { data } = await client.query<GetProfileByIdQuery, GetProfileByIdQueryVariables>({
    query: GetProfileByIdDocument,
    variables: { id: profileId },
  });
  return data.profileById || null;
}

export async function getShelfBySlug(shelfSlug: string): Promise<ShelfFullFragment | null> {
  const client = getApolloClient();
  const { data } = await client.query<GetShelfBySlugQuery, GetShelfBySlugQueryVariables>({
    query: GetShelfBySlugDocument,
    variables: { shelfSlug },
  });
  return data.shelf || null;
}

export async function getShelfBooksBySlug(shelfSlug: string): Promise<BookPartsFragment[] | null> {
  const client = getApolloClient();
  const { data } = await client.query<BooksByShelfQuery, BooksByShelfQueryVariables>({
    query: BooksByShelfDocument,
    variables: { shelfSlug, limit: 6, offset: 0 },
  });
  return data.booksByShelf || null;
}

export async function getBooksByProfileId(profileId: string, limit: number): Promise<BookPartsFragment[]> {
  const client = getApolloClient();

  const { data } = await client.query<RecentBooksByProfileIdQuery, RecentBooksByProfileIdQueryVariables>({
    query: RecentBooksByProfileIdDocument,
    variables: {
      profileId,
      limit,
    },
  });

  return data.recentBooksByProfileId || [];
}

export async function getInvite(inviteId: string) {
  const client = getApolloClient();

  const { data } = await client.query<InviteLinkQuery, InviteLinkQueryVariables>({
    query: InviteLinkDocument,
    variables: {
      id: inviteId,
    },
  });

  return data.inviteLink || null;
}

export async function getClubByHandle(handle: string, req?: NextRequest): Promise<ClubPartsFragment | null> {
  const client = getApolloClient(req);

  const { data } = await client.query<ClubQuery, ClubQueryVariables>({
    query: ClubDocument,
    variables: { handle },
  });

  return data.clubByHandle || null;
}

export async function getClubPreviewByHandle(handle: string): Promise<ClubPreviewFragment | null> {
  const client = getApolloClient();

  const { data } = await client.query<ClubPreviewQuery, ClubPreviewQueryVariables>({
    query: ClubPreviewDocument,
    variables: { handle },
  });

  return data.clubByHandle || null;
}

export async function getClubCountsById(clubId: string): Promise<{ memberCount: number; bookCount: number }> {
  const client = getApolloClient();
  const memberCount = await client.query<GetClubMembershipCountQuery, GetClubMembershipCountQueryVariables>({
    query: GetClubMembershipCountDocument,
    variables: { clubId },
  });
  const bookCount = await client.query<GetClubBooksCountQuery, GetClubBooksCountQueryVariables>({
    query: GetClubBooksCountDocument,
    variables: { clubId },
  });

  return {
    memberCount: memberCount.data.getClubMembershipCount,
    bookCount: bookCount.data.getClubBooksCount,
  };
}

export async function getActivity(
  activityId: string
): Promise<
  | MomentActivityFullFragment
  | ReadingStateActivityFullFragment
  | BookReviewActivityFullFragment
  | BookOnShelfActivityFullFragment
  | BookInClubActivityFullFragment
  | ClubPostActivityFullFragment
  | CreateGoalActivityFullFragment
  | CompleteGoalActivityFullFragment
  | null
> {
  const client = getApolloClient();

  const { data } = await client.query<ActivityQuery, ActivityQueryVariables>({
    query: ActivityDocument,

    variables: { activityId },
  });

  return data.activity || null;
}

export async function getMomentById(momentId: string) {
  const client = getApolloClient();

  const { data } = await client.query<GetMomentQuery, GetMomentQueryVariables>({
    query: GetMomentDocument,
    variables: { momentId },
  });

  return data.moment || null;
}

export async function getAllMoments(profileId: string, bookId: string) {
  const client = getApolloClient();

  const { data } = await client.query<
    GetMomentsByProfileAndBookQuery,
    GetMomentsByProfileAndBookQueryVariables
  >({
    query: GetMomentsByProfileAndBookDocument,
    variables: {
      profileId,
      bookId,
    },
  });

  return data.moments.filter(notEmpty) || [];
}

export async function getReview(reviewId: string) {
  const client = getApolloClient();

  const { data } = await client.query<GetReviewByIdQuery, GetReviewByIdQueryVariables>({
    query: GetReviewByIdDocument,
    variables: { reviewId },
  });

  return data.getReviewById || null;
}

export async function getReviewByBookIdAndProfileId(bookId: string, profileId: string) {
  const client = getApolloClient();

  const { data } = await client.query<GetReviewQuery, GetReviewQueryVariables>({
    query: GetReviewByIdDocument,
    variables: { bookId, profileId },
  });

  return data.review || null;
}

export async function getGoalBySlug(slug: string, handle: string) {
  const client = getApolloClient();
  const { data } = await client.query<GoalBySlugQuery, GoalBySlugQueryVariables>({
    query: GoalBySlugDocument,
    variables: { slug, handle },
  });

  return data.goalBySlug || null;
}

export async function getClubPost(req: NextRequest, postId: string) {
  const client = getApolloClient(req);

  const { data } = await client.query<PostQuery, PostQueryVariables>({
    query: PostDocument,
    variables: { postId },
  });

  return data.post || null;
}

export async function recBooksCurrentlyRead() {
  const client = getApolloClient();

  const { data } = await client.query<RecBooksCurrentlyReadQuery, RecBooksCurrentlyReadQueryVariables>({
    query: RecBooksCurrentlyReadDocument,
  });

  return data.recBooksCurrentlyRead;
}

export async function recShelvesRecentlyActive() {
  const client = getApolloClient();

  const { data } = await client.query<RecShelvesRecentlyActiveQuery, RecShelvesRecentlyActiveQueryVariables>({
    query: RecShelvesRecentlyActiveDocument,
  });

  return data.recShelvesRecentlyActive;
}

export async function getSupporters() {
  const client = getApolloClient();

  const { data } = await client.query<SupportersQuery, SupportersQueryVariables>({
    query: SupportersDocument,
  });

  return data.supporters;
}

export async function getMyProfile(req?: NextRequest): Promise<MyProfilePartsFragment | null> {
  if (!req?.cookies.token) return null;
  const client = getApolloClient(req);

  const { data } = await client.query<GetMyProfileQuery, GetMyProfileQueryVariables>({
    query: GetMyProfileDocument,
  });
  return data.me || null;
}

export async function getRewindByProfileId(profileId: string) {
  const client = getApolloClient();

  const { data } = await client.query<YearRewind2022Query, YearRewind2022QueryVariables>({
    query: YearRewind2022Document,
    variables: { profileId },
  });

  return data.yearRewind2022 || null;
}
