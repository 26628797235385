import classNames from 'classnames';
import { ReactNode } from 'react';
import { ActionItem } from './ActionItem';
import styles from './ActionList.module.scss';
import { ActionItemType } from './types';

type ActionListBase = { role?: string };

// Define input data OR children
type ActionListProps =
  | ({ items: ActionItemType[]; maxHeight?: number } & ActionListBase)
  | ({ children: ReactNode; maxHeight?: number } & ActionListBase);

export const ActionList = (props: ActionListProps): JSX.Element => {
  if ('items' in props) {
    return (
      <div
        role={props.role}
        style={props.maxHeight ? { maxHeight: props.maxHeight } : {}}
        className={classNames(styles.container, { scrollingY: props.maxHeight })}
      >
        {props.items.map((item, index) => (
          <ActionItem key={index} item={item} />
        ))}
      </div>
    );
  } else {
    return (
      <div
        role={props.role}
        style={props.maxHeight ? { maxHeight: props.maxHeight } : {}}
        className={classNames(styles.container, { scrollingY: props.maxHeight })}
      >
        {props.children}
      </div>
    );
  }
};
