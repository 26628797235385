import { useApolloClient } from '@apollo/client';
import constate from 'constate';
import {
  MyProfilePartsFragment,
  useGetMyProfileQuery,
  useLoginAppleMutation,
  useLoginMutation,
  useRegisterAppleMutation,
  useRegisterMutation,
} from 'generated/graphql';
import useMixpanel from 'hooks/useMixpanel';
import jwtDecode from 'jwt-decode';
import { useEffect, useMemo, useState } from 'react';
import { Auth, RegisterAppleRequest, RegisterRequest, TokenDecoded } from 'types/auth';
import { sendRnMessage } from 'utils/reactNative';
import { useCookie } from './useCookie';

const TOKEN_COOKIE_NAME = 'token';

export type AuthState = {
  myProfile: MyProfilePartsFragment | null;
};
type Props = {
  initialAuthState: AuthState;
};

function useAuth({ initialAuthState }: Props): Auth {
  const mixpanel = useMixpanel();

  // State
  const [token, setToken, destroyToken] = useCookie(TOKEN_COOKIE_NAME, undefined, { days: 182, path: '/' });
  const [firstSignIn, setFirstSignIn] = useState(false);
  const [isLoading, setIsLoading] = useState(!!token);
  const [myProfile, setMyProfile] = useState<MyProfilePartsFragment | null>(initialAuthState.myProfile);

  const email = useMemo(() => myProfile?.email || null, [myProfile]);
  const profile = useMemo(() => myProfile?.profile || null, [myProfile]);
  const languages = useMemo(() => myProfile?.languages || [], [myProfile]);
  const inviteLinks = useMemo(() => myProfile?.inviteLinks || [], [myProfile]);

  const tokenDecoded = useMemo(() => {
    if (!token) return;
    return jwtDecode<TokenDecoded>(token);
  }, [token]);

  // Data fetching
  const client = useApolloClient();
  const {
    data: myProfileData,
    refetch: refetchProfile,
    loading: profileLoading,
  } = useGetMyProfileQuery({
    skip: !token || !!profile,
  });

  // Mutations
  const [loginMutation] = useLoginMutation();
  const [loginAppleMutation] = useLoginAppleMutation();
  const [registerMutation] = useRegisterMutation();
  const [registerAppleMutation] = useRegisterAppleMutation();

  useEffect(() => {
    if (myProfileData?.me) {
      setMyProfile(myProfileData.me);
      setToken(myProfileData.me.token);
    }
    setIsLoading(profileLoading);
  }, [myProfileData, profileLoading]);

  // Refresh the token the first time the page is loaded
  useEffect(() => {
    if (initialAuthState.myProfile?.token) {
      setToken(initialAuthState.myProfile.token);
    }
  }, [initialAuthState]);

  // Tracking
  useEffect(() => {
    if (profile) {
      mixpanel.identifyUser(profile);
    }
  }, [profile]);

  /**
   * Authennticates a user against the backend.
   * It also saves the jwt token in a cookie.
   */
  const authenticate = async (email: string, password: string) => {
    setIsLoading(true);
    try {
      const { data, errors } = await loginMutation({ variables: { email, password: password } });
      if (!data?.login) throw errors;

      setToken(data.login.token);
      setMyProfile(data.login);

      return { isSuccessful: !!data.login.token, handle: data.login.profile.handle };
    } catch (error) {
      console.error(error);
      return { isSuccessful: false, handle: null };
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Authennticates a user against the backend using apple id
   * It also saves the jwt token in a cookie.
   */
  const authenticateApple = async (appleIdToken: string) => {
    setIsLoading(true);
    try {
      const { data, errors } = await loginAppleMutation({ variables: { appleIdToken } });
      if (!data?.loginApple) throw errors;

      setToken(data.loginApple.token);
      setMyProfile(data.loginApple);

      return { isSuccessful: !!data.loginApple.token, handle: data.loginApple.profile.handle };
    } catch (error) {
      console.error(error);
      return { isSuccessful: false, handle: null };
    } finally {
      setIsLoading(false);
    }
  };

  /**
   * Logs the user out in the frontend app.
   * Removes the cookie from client document.
   */
  const cleanup = async () => {
    setMyProfile(null);
    destroyToken();

    await client.clearStore();
    // invalidate apollo cache without
    // https://www.apollographql.com/docs/react/networking/authentication/#reset-store-on-logout
  };

  const logout = async () => {
    await cleanup();
    sendRnMessage('LOGOUT');
  };

  /**
   * Register a new account
   * And receive a token to sign in the user
   */
  const register = async ({
    email,
    password,
    handle,
    name,
    acceptedTerms,
    acceptedNewsletter,
    languages,
    inviteLinkId,
    invitedByProfileId,
    inviteTrackingKey,
  }: RegisterRequest) => {
    setIsLoading(true);

    // First we cleanup the user
    await cleanup();

    try {
      const { data } = await registerMutation({
        variables: {
          email,
          password,
          handle,
          name,
          acceptedTerms,
          acceptedNewsletter,
          languages,
          inviteLinkId,
        },
      });

      if (!data?.register) {
        throw new Error('Something went wrong while registering the new profile.');
      }

      setMyProfile(data.register);
      setToken(data.register.token);
      setFirstSignIn(true);

      mixpanel.identifyUser(data.register.profile, { $created: new Date().toISOString() });
      mixpanel.trackEvent('account_created', {
        source: inviteLinkId ? 'invitation' : 'direct',
        invitedByProfileId,
        inviteLinkId,
        inviteTrackingKey,
      });
      if (window.plausible) {
        window.plausible('Signup');
      }

      return { isSuccessful: !!data.register.token, error: '' };
    } catch (error: any) {
      console.error(error);
      let myMessage = 'Error occured';
      if (error['graphQLErrors'] && error['graphQLErrors'][0]) {
        const message = error['graphQLErrors'][0].message;
        myMessage = message;
      } else if (error?.message) {
        myMessage = error.message;
      }

      if (myMessage === 'Failed to fetch') {
        myMessage = 'Submit failed: Please make sure you are online and try again';
      }

      return Promise.resolve({ isSuccessful: false, error: myMessage });
    } finally {
      setIsLoading(false);
    }
  };

  const registerApple = async ({
    appleIdToken,
    handle,
    name,
    acceptedTerms,
    acceptedNewsletter,
    languages,
    inviteLinkId,
    invitedByProfileId,
    inviteTrackingKey,
  }: RegisterAppleRequest) => {
    setIsLoading(true);

    // First we cleanup the user
    await cleanup();

    try {
      const { data } = await registerAppleMutation({
        variables: {
          appleIdToken,
          handle,
          name,
          acceptedTerms,
          acceptedNewsletter,
          languages,
          inviteLinkId,
        },
      });

      if (!data?.registerApple) {
        throw new Error('Something went wrong while registering the new profile.');
      }

      setMyProfile(data.registerApple);
      setToken(data.registerApple.token);
      setFirstSignIn(true);

      mixpanel.identifyUser(data.registerApple.profile, { $created: new Date().toISOString() });
      mixpanel.trackEvent('account_created', {
        source: inviteLinkId ? 'invitation' : 'direct',
        invitedByProfileId,
        inviteLinkId,
        inviteTrackingKey,
      });

      return { isSuccessful: !!data.registerApple.token, error: '' };
    } catch (error: any) {
      console.error(error);
      let myMessage = 'Error occured';
      if (error['graphQLErrors'] && error['graphQLErrors'][0]) {
        const message = error['graphQLErrors'][0].message;
        myMessage = message;
      } else if (error?.message) {
        myMessage = error.message;
      }

      if (myMessage === 'Failed to fetch') {
        myMessage = 'Submit failed: Please make sure you are online and try again';
      }

      return Promise.resolve({ isSuccessful: false, error: myMessage });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    email,
    languages,
    authenticate,
    authenticateApple,
    logout,
    register,
    registerApple,
    refetchProfile,
    token,
    inviteLinks,
    firstSignIn,
    setFirstSignIn,
    ...(profile
      ? {
          isSignedIn: true,
          profile,
          tokenDecoded,
        }
      : {
          isSignedIn: false,
          profile: null,
          tokenDecoded: null,
        }),
  };
}

const [AuthProvider, useAuthContext] = constate(useAuth);

export { AuthProvider, useAuthContext };
