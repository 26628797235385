import { IconProps } from './type';

export const StarHalfIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="2" width="18" height="18">
      <path
        d="M11.8098 3.58541C11.8697 3.40115 12.1303 3.40115 12.1902 3.58541L13.9757 9.08065C14.0025 9.16306 14.0793 9.21885 14.1659 9.21885H19.944C20.1377 9.21885 20.2183 9.46677 20.0615 9.58065L15.387 12.9769C15.3169 13.0278 15.2876 13.1181 15.3143 13.2005L17.0999 18.6957C17.1597 18.88 16.9488 19.0332 16.7921 18.9193L12.1176 15.5231C12.0475 15.4722 11.9525 15.4722 11.8824 15.5231L7.20791 18.9193C7.05117 19.0332 6.84027 18.88 6.90014 18.6957L8.68566 13.2005C8.71243 13.1181 8.6831 13.0278 8.613 12.9769L3.93847 9.58065C3.78173 9.46677 3.86228 9.21885 4.05603 9.21885H9.83407C9.92071 9.21885 9.9975 9.16306 10.0243 9.08065L11.8098 3.58541Z"
        fill={color}
        stroke={color}
        strokeWidth="1.6"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect x="4.63647" y="1.36365" width="7.36364" height="19.6364" fill={color} />
      <path
        d="M11.8098 3.58541C11.8697 3.40115 12.1303 3.40115 12.1902 3.58541L13.9757 9.08065C14.0025 9.16306 14.0793 9.21885 14.1659 9.21885H19.944C20.1377 9.21885 20.2183 9.46677 20.0615 9.58065L15.387 12.9769C15.3169 13.0278 15.2876 13.1181 15.3143 13.2005L17.0999 18.6957C17.1597 18.88 16.9488 19.0332 16.7921 18.9193L12.1176 15.5231C12.0475 15.4722 11.9525 15.4722 11.8824 15.5231L7.20791 18.9193C7.05117 19.0332 6.84027 18.88 6.90014 18.6957L8.68566 13.2005C8.71243 13.1181 8.6831 13.0278 8.613 12.9769L3.93847 9.58065C3.78173 9.46677 3.86228 9.21885 4.05603 9.21885H9.83407C9.92071 9.21885 9.9975 9.16306 10.0243 9.08065L11.8098 3.58541Z"
        stroke={color}
        strokeWidth="1.6"
      />
    </g>
  </svg>
);
