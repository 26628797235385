import classNames from 'classnames';
import Link from 'next/link';
import { ButtonHTMLAttributes, forwardRef } from 'react';
import styles from './ButtonSimple.module.scss';

export type ButtonSimpleVariant =
  | 'underline'
  | 'bold'
  | 'faded'
  | 'accent'
  | 'basic'
  | 'fadedUnderline'
  | 'none';
type Size = 'mini' | 'normal' | 'inherit';
interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonSimpleVariant;
  size?: Size;
  // icon only removes all margin/padding from svg
  iconOnly?: boolean;
}

export const ButtonSimple = forwardRef<HTMLButtonElement, Props>(
  ({ variant = 'underline', size = 'normal', className, iconOnly, children, ...rest }: Props, ref) => {
    if (rest.onClick) {
      return (
        <button
          type="button"
          onClick={rest.onClick}
          className={classNames(className, styles.button, styles[variant], styles[size], {
            [styles.iconOnly]: iconOnly,
          })}
          ref={ref}
          {...rest}
        >
          {children}
        </button>
      );
    }
    return (
      <span
        className={classNames(className, styles.button, styles[variant], styles[size], {
          [styles.iconOnly]: iconOnly,
        })}
        ref={ref}
        {...rest}
      >
        {children}
      </span>
    );
  }
);

ButtonSimple.displayName = 'Button';

export default ButtonSimple;

interface LinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
  as?: string;
  variant?: ButtonSimpleVariant;
  size?: Size;
  className?: string;
  target?: string;
  asHtmlAnchor?: boolean; // if true, will render a <a> tag instead of <Link>
  rel?: string;
}

export const LinkSimple = ({
  variant = 'underline',
  size = 'normal',
  href,
  as,
  children,
  className,
  target,
  asHtmlAnchor = false,
  rel,
}: LinkProps): JSX.Element => {
  if (asHtmlAnchor) {
    return (
      <a
        href={href}
        target={target}
        rel={rel}
        className={classNames(className, styles.button, styles[variant], styles[size])}
      >
        {children}
      </a>
    );
  }
  return (
    <Link href={href} as={as}>
      <a
        target={target}
        rel={rel}
        className={classNames(className, styles.button, styles[variant], styles[size])}
      >
        {children}
      </a>
    </Link>
  );
};
