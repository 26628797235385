import classNames from 'classnames';

import styles from './Divider.module.scss';

type Props = {
  variant?: 'full' | 'semi';
  spacing?: 'small' | 'none' | 'large';
};

export const Divider = ({ variant = 'full', spacing = 'small' }: Props): JSX.Element => (
  <hr
    className={classNames(styles.divider, {
      [styles.semi]: variant === 'semi',
      [styles.spacingSmall]: spacing === 'small',
      [styles.spacingLarge]: spacing === 'large',
    })}
  />
);
