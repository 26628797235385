import { RefObject, useEffect } from 'react';

type Props<T> = {
  refObject: RefObject<T>;
  isActive: boolean;
  onOutsideClick: () => void;
  alwaysClose?: boolean;
};

const useOutsideClick = <T>({ refObject, isActive, onOutsideClick, alwaysClose = false }: Props<T>): void => {
  const onClickOutside = (e: MouseEvent) => {
    if (
      alwaysClose ||
      (refObject.current &&
        refObject.current instanceof HTMLElement &&
        !refObject.current.contains(e.target as Node))
    ) {
      onOutsideClick();
    }
  };

  useEffect(() => {
    if (isActive) document.addEventListener('click', onClickOutside);
    return () => document.removeEventListener('click', onClickOutside);
  }, [refObject.current, isActive, onClickOutside, alwaysClose]);
};

export default useOutsideClick;
