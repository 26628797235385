import { UserRole } from 'generated/graphql';
import getConfig from 'next/config';
import { useAuthContext } from './useAuth';

const { publicRuntimeConfig } = getConfig();

export function useAdvancedRoles() {
  const { token, tokenDecoded } = useAuthContext();

  const isLibrarian: boolean =
    tokenDecoded?.roles?.includes(UserRole.Admin) ||
    tokenDecoded?.roles?.includes(UserRole.Moderator) ||
    tokenDecoded?.roles?.includes(UserRole.Librarian) ||
    false;

  const isAdmin: boolean = tokenDecoded?.roles?.includes(UserRole.Admin) || false;

  function getLibrarianUrl(path?: string) {
    const base = `${publicRuntimeConfig.cmsUrl}/login/${token}`;
    if (!path) return base;

    const encodedPath = encodeURIComponent(path);
    return `${base}?redirect=${encodedPath}`;
  }

  return { isLibrarian, isAdmin, getLibrarianUrl };
}
