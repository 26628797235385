import { IconProps } from './type';

export const SearchIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10.5625" cy="10.5625" r="5.7625" strokeWidth="1.6" />
    <line x1="15.4439" y1="15.25" x2="20.3125" y2="20.1186" strokeWidth="1.6" strokeLinecap="round" />
  </svg>
);
