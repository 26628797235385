export const Logo = ({ color = 'var(--uiBlack)' }): JSX.Element => (
  <svg width="97" height="36" viewBox="0 0 97 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M85.2627 13.9496H89.2037L87.0964 17.398L89.0122 18.5748L91.2016 14.9622L93.3911 18.5748L95.3069 17.398L93.2269 13.9496H97.1132V11.7054H93.1722L95.2795 8.22958L93.3637 7.05273L91.2016 10.638L89.0395 7.05273L87.1237 8.22958L89.2311 11.7054H85.2627V13.9496Z"
      fill={color}
    />
    <path d="M0 31.3162H97.3684V34.6162H0V31.3162Z" fill={color} />
    <path d="M0 25.4736H10.5642V22.2989H3.17474V6.31575H0V25.4736Z" fill={color} />
    <path d="M13.9271 25.4736H16.965V11.4589H13.9271V25.4736Z" fill={color} />
    <path
      d="M26.1377 25.4736H28.5735V22.4905H27.0409C26.2745 22.4905 25.6724 22.1621 25.6724 21.1494V14.3894H29.0935V11.4063H25.6724V6.23364L22.6619 7.54733V11.4063H19.7609V14.3894H22.6619V22.1073C22.6619 24.4884 24.1672 25.4736 26.1377 25.4736Z"
      fill={color}
    />
    <path
      d="M38.3412 25.6926C40.4212 25.6926 42.2002 24.9263 43.3223 23.6947L41.3791 21.5326C40.7497 22.2442 39.7097 22.7915 38.2865 22.7915C36.3707 22.7915 34.6191 21.341 34.3728 19.4526H44.3623V18.0568C44.3623 14.3894 41.9539 11.1326 37.8486 11.1326C34.2907 11.1326 31.2802 14.1705 31.2802 18.3579C31.2802 22.7642 34.4002 25.6926 38.3412 25.6926ZM41.297 16.7979H34.3728C34.7012 15.0463 36.1518 13.8968 37.8212 13.8968C39.956 13.8968 41.0781 15.2105 41.297 16.7979Z"
      fill={color}
    />
    <path
      d="M47.6181 25.4736H50.6286V17.8105C50.9844 15.4568 52.517 14.4715 54.1317 14.4715C54.816 14.4715 55.3907 14.6357 55.856 14.9642L57.2517 12.1726C56.4033 11.5157 55.5823 11.2694 54.3781 11.2694C52.736 11.2694 51.2033 12.0905 50.6286 13.2947V11.4063H47.6181V25.4736Z"
      fill={color}
    />
    <path
      d="M64.9776 25.7473C66.647 25.7473 67.7418 25.2 68.3165 24.4063V25.4736H71.327V11.4063H68.3165V12.4189C67.6597 11.5705 66.5376 11.16 65.0597 11.16C61.7481 11.16 58.4091 14.0063 58.4091 18.4673C58.4091 22.8736 61.5018 25.7473 64.9776 25.7473ZM65.306 22.8463C63.2533 22.8463 61.4744 21.0947 61.4744 18.4673C61.4744 15.5663 63.4997 14.061 65.2786 14.061C66.7839 14.061 67.7691 14.6905 68.3165 15.5936V21.2042C67.7691 22.2989 66.647 22.8463 65.306 22.8463Z"
      fill={color}
    />
    <path d="M75.5488 25.4736H78.6141V6.31575H75.5488V25.4736Z" fill={color} />
    <path
      d="M17.1998 8.01035C17.1998 8.93762 16.4374 9.70005 15.4895 9.70005C14.5622 9.70005 13.7998 8.93762 13.7998 8.01035C13.7998 7.04187 14.5622 6.30005 15.4895 6.30005C16.4374 6.30005 17.1998 7.04187 17.1998 8.01035Z"
      fill={color}
    />
  </svg>
);

export const Mark = ({ color = 'var(--uiBlack)' }): JSX.Element => (
  <svg width="22" height="30" viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 20.0359H10.7529V16.8045H3.23143V0.535889H0V20.0359Z" fill={color} />
    <path
      d="M9 7.02H13.0114L10.8664 10.53L12.8164 11.7279L15.045 8.05072L17.2736 11.7279L19.2236 10.53L17.1064 7.02H21.0621V4.73571H17.0507L19.1957 1.19786L17.2457 0L15.045 3.64929L12.8443 0L10.8943 1.19786L13.0393 4.73571H9V7.02Z"
      fill={color}
    />
    <path d="M0 26H21V29.3H0V26Z" fill={color} />
  </svg>
);

export const BrandMark = ({ color = 'var(--uiBlack)' }): JSX.Element => (
  <svg width="24" height="31" viewBox="0 0 24 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8679 7.6303L14.8901 7.91156L12.4932 11.2806L14.3645 12.6184L16.8568 9.08762L18.8336 12.9309L20.8728 11.8665L18.9961 8.19868L22.9624 8.47603L23.1225 6.18562L19.1004 5.90437L21.4992 2.50742L19.6279 1.16963L17.1654 4.6744L15.2147 0.861031L13.1755 1.92538L15.0782 5.62311L11.0281 5.3399L10.8679 7.6303Z"
      fill={color}
    />
    <path
      d="M0.688711 19.7525L11.3915 21.2567L11.8436 18.0403L4.35714 16.9882L6.6329 0.795296L3.4165 0.343262L0.688711 19.7525Z"
      fill={color}
    />
    <path d="M23.1817 27.9938L2.3285 30.9245L1.87646 27.7081L22.7297 24.7774L23.1817 27.9938Z" fill={color} />
  </svg>
);
