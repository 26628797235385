import { IconProps } from './type';

export const DuplicateIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="10" height="10" rx="1" stroke={color} strokeWidth="1.6" />
    <path
      d="M14 10H19C19.5523 10 20 10.4477 20 11V19C20 19.5523 19.5523 20 19 20H11C10.4477 20 10 19.5523 10 19V14"
      stroke={color}
      strokeWidth="1.6"
    />
  </svg>
);
