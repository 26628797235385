import React from 'react';

type State = [string, React.Dispatch<React.SetStateAction<string>>];

export const usePersistedState = (name: string, defaultValue: string): State => {
  const [value, setValue] = React.useState<string>(defaultValue);
  const nameRef = React.useRef(name);

  React.useEffect(() => {
    try {
      const storedValue = localStorage.getItem(name);
      if (storedValue !== null) setValue(storedValue);
      else localStorage.setItem(name, defaultValue);
    } catch {
      setValue(defaultValue);
    }
  }, []);

  React.useEffect(() => {
    try {
      localStorage.setItem(nameRef.current, value);
      // eslint-disable-next-line no-empty
    } catch {}
  }, [value]);

  React.useEffect(() => {
    const lastName = nameRef.current;
    if (name !== lastName) {
      try {
        localStorage.setItem(name, value);
        nameRef.current = name;
        localStorage.removeItem(lastName);
        // eslint-disable-next-line no-empty
      } catch {}
    }
  }, [name]);

  return [value, setValue];
};
