import { IconProps } from './type';

export const LaurelRightIcon = ({ color = '#D9D9D9', size = 37 }: IconProps) => {
  return (
    <svg height={size} viewBox="0 0 18 37" fill={color} xmlns="http://www.w3.org/2000/svg">
      <ellipse
        rx="1.38462"
        ry="2.76924"
        transform="matrix(-0.712231 0.701945 0.701945 0.712231 3.78191 3.44427)"
      />
      <ellipse rx="1.38462" ry="2.76924" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 6.3665 35.1154)" />
      <ellipse rx="1.38462" ry="2.76924" transform="matrix(-1 1.74846e-07 1.74846e-07 1 2.5602 31.3076)" />
      <ellipse
        rx="1.38462"
        ry="2.76924"
        transform="matrix(-0.285801 -0.958289 -0.958289 0.285801 10.556 30.563)"
      />
      <ellipse
        rx="1.38462"
        ry="2.76924"
        transform="matrix(-0.958289 0.285801 0.285801 0.958289 5.8225 28.0016)"
      />
      <ellipse
        rx="1.38462"
        ry="2.76924"
        transform="matrix(-0.68117 -0.732125 -0.732125 0.68117 13.8649 24.3057)"
      />
      <ellipse
        rx="1.38462"
        ry="2.76924"
        transform="matrix(-0.732125 0.68117 0.68117 0.732125 8.48443 24.1124)"
      />
      <ellipse
        rx="1.38462"
        ry="2.76924"
        transform="matrix(-0.812862 -0.582456 -0.582456 0.812862 14.0823 18.2161)"
      />
      <ellipse
        rx="1.38462"
        ry="2.76924"
        transform="matrix(-0.582456 0.812862 0.812862 0.582456 8.77266 19.0931)"
      />
      <ellipse
        rx="1.38462"
        ry="2.76924"
        transform="matrix(-0.951504 -0.307635 -0.307635 0.951504 12.7437 11.8252)"
      />
      <ellipse
        rx="1.38462"
        ry="2.76924"
        transform="matrix(-0.307635 0.951504 0.951504 0.307635 7.94678 14.2763)"
      />
      <ellipse rx="1.38462" ry="2.76924" transform="matrix(-1 0 0 1 9.48452 5.69234)" />
      <ellipse rx="1.38462" ry="2.76924" transform="matrix(2.18557e-07 1 1 -2.18557e-07 5.67647 9.5001)" />
    </svg>
  );
};
