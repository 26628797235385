import classNames from 'classnames';
import Link from 'next/link';
import { forwardRef, MouseEvent, ReactNode } from 'react';
import styles from './TabBar.module.scss';

export type TabAlign = 'center' | 'left' | 'right' | 'full-width-left';
type TabsBarProps = {
  children: ReactNode;
  align?: TabAlign;
  sticky?: boolean;
  actionButton?: ReactNode;
};

// 'full-width-left' adds padding to left of the tabs on mobile
// 'left' has no padding to left of the tab bar, use when parent has padding/margin on mobile

export const TabBar = ({ children, align = 'center', sticky, actionButton }: TabsBarProps): JSX.Element => {
  return (
    <div className={classNames(styles.track, { [styles.sticky]: sticky })}>
      <div className={classNames(styles.tabBar, styles[align])}>
        {actionButton && align === 'right' && <span className={styles.buttonLeft}>{actionButton}</span>}
        {children}
        {actionButton && align === 'center' && <>{actionButton}</>}
        {actionButton && ['left', 'full-width-left'].includes(align) && (
          <span className={styles.buttonRight}>{actionButton}</span>
        )}
      </div>
    </div>
  );
};

type TabProps =
  | {
      children: ReactNode;
      isActive?: boolean;
      onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    }
  | {
      children: ReactNode;
      isActive?: boolean;
      as: string;
      href: string;
    };

export const Tab = forwardRef<HTMLButtonElement, TabProps>(
  ({ children, isActive = false, ...props }: TabProps, ref): JSX.Element => {
    if ('href' in props) {
      return (
        <Link href={props.href} as={props.as} shallow scroll={false} replace>
          <button className={classNames(styles.tab, { [styles.active]: isActive })} ref={ref}>
            {children}
          </button>
        </Link>
      );
    }

    return (
      <button
        onClick={props.onClick}
        className={classNames(styles.tab, { [styles.active]: isActive })}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);

type TabContentProps = {
  children: ReactNode;
};

export const TabContent = forwardRef<HTMLDivElement, TabContentProps>(
  ({ children }: TabContentProps, ref): JSX.Element => {
    return (
      <div ref={ref} className={styles.tabContent}>
        {children}
      </div>
    );
  }
);
TabContent.displayName = 'TabContent';
