import { IconProps } from './type';

export const HelpIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="8.20236" transform="rotate(45 12 12)" stroke={color} strokeWidth="1.6" />
    <circle cx="12" cy="12" r="4.2" transform="rotate(45 12 12)" stroke={color} strokeWidth="1.6" />
    <path
      d="M9.66992 3.59448L10.665 8.09448M14.33 3.59448L13.3347 8.09448"
      stroke={color}
      strokeWidth="1.6"
    />
    <path
      d="M9.66992 20.4055L10.665 15.9055M14.33 20.4055L13.3347 15.9055"
      stroke={color}
      strokeWidth="1.6"
    />
    <path
      d="M20.4055 9.66992L15.9055 10.665M20.4055 14.33L15.9055 13.3347"
      stroke={color}
      strokeWidth="1.6"
    />
    <path
      d="M3.59448 9.66992L8.09448 10.665M3.59448 14.33L8.09448 13.3347"
      stroke={color}
      strokeWidth="1.6"
    />
  </svg>
);
