import { createPopper, Placement } from '@popperjs/core';
import classNames from 'classnames';
import MobileDropdown from 'components/basics/Dropdowns/MobileDropdown';
import Portal from 'components/layout/Portal';
import { ReadingStatus } from 'generated/graphql';
import useOutsideClick from 'hooks/useOutsideClick';
import { useScreenSize } from 'hooks/useScreenSize';
import React, { cloneElement, ReactElement, ReactNode, Ref, useEffect, useRef, useState } from 'react';
import { noop } from 'utils/noop';
import styles from './OptionsMenu.module.scss';

const isHTMLElement = (elem: ReactElement | null) => elem && typeof elem.type === 'string';

type ButtonProps = {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  ref: Ref<HTMLElement>;
  noScroll?: boolean;
  status?: ReadingStatus;
  isActive?: boolean;
};

type Props = {
  children: ReactNode;
  button: ReactElement<ButtonProps> | null;
  positioningStrategy?: 'fixed' | 'absolute';
  size?: 'M' | 'L';
  status?: ReadingStatus;
  noScroll?: boolean;
  closeOnSelect?: boolean;
  zLayer?: 'Dropdown' | 'Header' | 'Modal';
  onClose?: () => void;

  // These are used to override the menu's built-in open state
  // Useful if this state should be controlled by the parent component
  isOpen?: boolean;
  setOpen?: (newVal: boolean) => void;
  progressArrowColor?: boolean;
};

const OptionsMenu = ({
  children,
  button,
  positioningStrategy,
  status,
  noScroll,
  size = 'L',
  closeOnSelect = false,
  zLayer,
  onClose = noop,
  progressArrowColor,
  isOpen: isOpenOuter,
  setOpen: setOpenOuter,
}: Props): JSX.Element => {
  const [isOpenInner, setOpenInner] = useState(false);
  const buttonRef = useRef<HTMLElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const arrowRef = useRef<HTMLDivElement>(null);
  const isOpen = isOpenOuter || isOpenInner;
  const setOpen = setOpenOuter || setOpenInner;

  useOutsideClick({
    refObject: menuRef,
    isActive: isOpen,
    onOutsideClick: () => {
      onClose();
      setOpen(false);
    },
    alwaysClose: closeOnSelect,
  });

  const { smScreen } = useScreenSize();

  useEffect(() => {
    if (!isOpenInner) onClose();
  }, [isOpenInner]);

  useEffect(() => {
    if (isOpenOuter === false) onClose();
  }, [isOpenOuter]);

  useEffect(() => {
    if (!buttonRef.current || !menuRef.current || (!arrowRef.current && !smScreen)) {
      return;
    }
    const popperInstance = createPopper(buttonRef.current, menuRef.current, {
      strategy: positioningStrategy,
      placement: 'bottom-start',
      onFirstUpdate: (state) => {
        if (state.elements) state.elements.popper.style.opacity = '1';
      },
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: ({ placement }: { placement: Placement }) =>
              placement === 'bottom-end' ? [20, 10] : [-22, 10],
          },
        },
        {
          name: 'arrow',
          options: {
            element: arrowRef.current,
            padding: { left: -22, right: 10 },
          },
        },
      ],
    });
    return () => {
      popperInstance.destroy();
    };
  });

  function handleToggle(event: React.MouseEvent<HTMLElement>) {
    onClose();
    if (children) {
      event.preventDefault();
      event.stopPropagation();
      setOpen(!isOpen);
    }
  }

  const buttonProps = isHTMLElement(button)
    ? {}
    : {
        status: status,
        isActive: isOpenInner,
      };

  return (
    <>
      {button &&
        cloneElement(button, {
          onClick: handleToggle,
          ref: buttonRef,
          ...buttonProps,
        })}
      {!smScreen && isOpen && children && positioningStrategy !== 'fixed' && (
        <div
          className={classNames(styles.menu, {
            [styles.sizeL]: size === 'L',
          })}
          ref={menuRef}
        >
          <div
            className={classNames(styles.arrow, { [styles.progressArrowColor]: progressArrowColor })}
            ref={arrowRef}
          />
          <div className={styles.content}>{children}</div>
        </div>
      )}
      {!smScreen && isOpen && children && positioningStrategy === 'fixed' && (
        <Portal>
          <div
            className={classNames(styles.menu, styles[`z${zLayer}`], {
              [styles.sizeL]: size === 'L',
            })}
            ref={menuRef}
          >
            <div
              className={classNames(styles.arrow, { [styles.progressArrowColor]: progressArrowColor })}
              ref={arrowRef}
            />
            <div className={styles.content}>{children}</div>
          </div>
        </Portal>
      )}
      {smScreen && isOpen && children && (
        <MobileDropdown noScroll={noScroll} isOpen={isOpen} closeMe={() => setOpen(false)}>
          {children}
        </MobileDropdown>
      )}
    </>
  );
};

export default OptionsMenu;
