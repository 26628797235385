import { IconProps } from './type';

export const ConfirmedIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="8.2" stroke={color} strokeWidth="1.6" />
      <path d="M9 12L11 14L15 10" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
    </svg>
  );
};
