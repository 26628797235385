import { dayjs, shortLocale } from 'date-utils';
import { forwardRef } from 'react';
import styles from './Date.module.scss';

type DateProps = {
  date: Date | string;
};

type DateLinkProps = DateProps & {
  href?: string; // Href is passed from next/link
  onClick?: () => void; // Href is passed from next/link
};

// Don't forget to wrap the link with next/link with passHref
export const DateLink = forwardRef<HTMLAnchorElement, DateLinkProps>(({ date, onClick, href }, ref) => (
  <a className={styles.date} ref={ref} href={href} onClick={onClick}>
    {dayjs(date).format('MMM D, YYYY')}
  </a>
));

DateLink.displayName = 'DateLink';

// Don't forget to wrap the link with next/link with passHref
export const RelativeDateLink = forwardRef<HTMLAnchorElement, DateLinkProps>(
  ({ date, onClick, href }, ref) => (
    <a className={styles.date} ref={ref} href={href} onClick={onClick}>
      {dayjs(date).fromNow()}
    </a>
  )
);

RelativeDateLink.displayName = 'RelativeDateLink';

export const RelativeDate = ({ date }: DateProps): JSX.Element => (
  <span className={styles.date}>{dayjs(date).fromNow()}</span>
);

export const RelativeDateShort = ({ date }: DateProps): JSX.Element => {
  const today = dayjs().toISOString();
  const dateOfThing = dayjs(date);
  const diff = dateOfThing.diff(today, 'month');
  if (diff < 0 && diff > -11) return <span className={styles.date}>{dayjs(date).format('MMM D')}</span>;
  else if (diff < -10) return <span className={styles.date}>{dayjs(date).format('MMM D, YYYY')}</span>;
  else return <span className={styles.date}>{dayjs(date).locale(shortLocale).fromNow(true)}</span>;
};

export const Date = ({ date }: DateProps): JSX.Element => (
  <span className={styles.date}>{dayjs(date).format('MMM D, YYYY')}</span>
);

export const Time = ({ date }: DateProps): JSX.Element => (
  <span className={styles.date}>{dayjs(date).format('h:mm A')}</span>
);
