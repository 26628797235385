import { IconProps } from './type';

export const ClubIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.5" cy="8.5" r="5.7" stroke={color} strokeWidth="1.6" />
    <circle cx="18.5" cy="15.5" r="3.7" stroke={color} strokeWidth="1.6" />
    <circle cx="10.5" cy="18.5" r="1.7" stroke={color} strokeWidth="1.6" />
  </svg>
);

export const ClubIconDisplay = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.9875" y="3.46663" width="14.4" height="14.4" rx="4.2" stroke={color} strokeWidth="1.6" />
    <rect
      x="19.9875"
      y="15.4666"
      width="9.06667"
      height="9.06667"
      rx="2.2"
      stroke={color}
      strokeWidth="1.6"
    />
    <rect x="10.6516" y="20.8" width="6.4" height="6.4" rx="1.7" stroke={color} strokeWidth="1.6" />
  </svg>
);
