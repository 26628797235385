export const SocialFacebookIcon = (): JSX.Element => (
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_19098_284594)">
      <path
        d="M40 20.804C40 9.75825 31.0457 0.803955 20 0.803955C8.9543 0.803955 0 9.75825 0 20.804C0 30.7864 7.31367 39.0606 16.875 40.561V26.5852H11.7969V20.804H16.875V16.3977C16.875 11.3852 19.8609 8.61646 24.4293 8.61646C26.6168 8.61646 28.9062 9.00708 28.9062 9.00708V13.929H26.3844C23.9 13.929 23.125 15.4708 23.125 17.054V20.804H28.6719L27.7852 26.5852H23.125V40.561C32.6863 39.0606 40 30.7864 40 20.804Z"
        fill="#1877F2"
      />
      <path
        d="M27.7852 26.5852L28.6719 20.804H23.125V17.054C23.125 15.4723 23.9 13.929 26.3844 13.929H28.9062V9.00708C28.9062 9.00708 26.6176 8.61646 24.4293 8.61646C19.8609 8.61646 16.875 11.3852 16.875 16.3977V20.804H11.7969V26.5852H16.875V40.561C18.9457 40.8849 21.0543 40.8849 23.125 40.561V26.5852H27.7852Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_19098_284594">
        <rect width="40" height="40" fill="white" transform="translate(0 0.803955)" />
      </clipPath>
    </defs>
  </svg>
);
