import { IconProps } from './type';

export const AddIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="8.2" stroke={color} strokeWidth="1.6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8 7.75736C12.8 7.31553 12.4418 6.95736 12 6.95736C11.5582 6.95736 11.2 7.31553 11.2 7.75736L11.2 11.2L7.75735 11.2C7.31552 11.2 6.95735 11.5582 6.95735 12C6.95735 12.4418 7.31552 12.8 7.75735 12.8L11.2 12.8L11.2 16.2426C11.2 16.6845 11.5582 17.0426 12 17.0426C12.4418 17.0426 12.8 16.6845 12.8 16.2426L12.8 12.8H16.2426C16.6845 12.8 17.0426 12.4418 17.0426 12C17.0426 11.5582 16.6845 11.2 16.2426 11.2H12.8L12.8 7.75736Z"
      fill={color}
    />
  </svg>
);
