import { ReactNode } from 'react';
import Link from 'next/link';
import styles from './MoreLink.module.scss';

type Props = {
  children: ReactNode;
  href: string;
};

// Don't forget to wrap the link with next/link with passHref
export const MoreLink = ({ href, children }: Props): JSX.Element => {
  return (
    <Link href="/notifications" passHref>
      <a href={href} className={styles.link}>
        {children}
      </a>
    </Link>
  );
};

MoreLink.displayName = 'MoreLink';

export default MoreLink;
