import classNames from 'classnames';
import { forwardRef, ButtonHTMLAttributes } from 'react';
import styles from './Button.module.scss';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  mini?: boolean;
  type?: 'button' | 'submit' | 'reset'; // shouldnt this be part of HTMLButtonElement?
  variant?: 'primary' | 'focus' | 'secondary' | 'tertiary' | 'focus-outline';
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
  stretch?: boolean;
  align?: 'center' | 'left';
}

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      mini = false,
      className,
      variant = 'primary',
      icon,
      iconRight,
      stretch,
      align = 'center',
      ...rest
    }: Props,
    ref
  ) => {
    return (
      <button
        className={classNames(
          styles[variant],
          {
            [styles.mini]: mini,
            [styles.hasIcon]: !!icon || !!iconRight,
            [styles.stretch]: stretch,
            [styles.alignLeft]: align === 'left',
            [styles.alignCenter]: align === 'center',
          },
          styles.button,
          className
        )}
        ref={ref}
        {...rest}
      >
        {icon && <div className={styles.icon}>{icon}</div>}
        {children && <div className={styles.text}>{children}</div>}
        {iconRight && <div className={styles.icon}>{iconRight}</div>}
      </button>
    );
  }
);

Button.displayName = 'Button';

export default Button;
