import { IconProps } from './type';

export const ShareIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 15L12 4" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M8 7L12 3L16 7" stroke={color} strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M17.5714 11H6.42857C6.19188 11 6 11.1599 6 11.3571V20.6429C6 20.8401 6.19188 21 6.42857 21H17.5714C17.8081 21 18 20.8401 18 20.6429V11.3571C18 11.1599 17.8081 11 17.5714 11Z"
      stroke={color}
      strokeWidth="1.6"
    />
  </svg>
);
