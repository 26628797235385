import classNames from 'classnames';
import Link from 'next/link';
import { Children, ReactNode } from 'react';
import styles from './Breadcrumbs.module.scss';

type ChildrenProps = {
  children: ReactNode;
};
type Props = {
  handle: string;
  children: ReactNode;
  extras?: ReactNode;
  showMobile?: boolean;
};
type LinkCrumbProps = {
  children: ReactNode;
  href: string;
  as: string;
};

export const LinkCrumb = ({ children, href, as }: LinkCrumbProps): JSX.Element => {
  return (
    <Link href={href} as={as}>
      <a className={styles.link}>{children}</a>
    </Link>
  );
};

export const TextCrumb = ({ children }: ChildrenProps): JSX.Element => {
  return <span className={styles.text}>{children}</span>;
};

const Crumbs = ({ children }: ChildrenProps): JSX.Element => {
  const count = Children.count(children);
  return (
    <div className={styles.crumbs}>
      {Children.map(children, (child, index) => (
        <>
          {child}
          {index < count - 1 && <span className={styles.divider}>/</span>}
        </>
      ))}
    </div>
  );
};

export const Breadcrumbs = ({ handle, children, extras, showMobile }: Props): JSX.Element => {
  return (
    <div
      className={classNames(styles.container, {
        [styles.showMobile]: showMobile,
      })}
    >
      <Crumbs>
        <LinkCrumb href="/[handle]" as={`/${handle}`}>
          @{handle}
        </LinkCrumb>
        {children}
      </Crumbs>

      {extras && <div className={styles.extras}>{extras}</div>}
    </div>
  );
};
