import { forwardRef } from 'react';
import { IconProps } from './type';

export const QuestionMarkIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ color = 'currentColor' }: IconProps, ref): JSX.Element => (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke={color}
      xmlns="http://www.w3.org/2000/svg"
      ref={ref}
    >
      <circle cx="12" cy="12" r="8.2" strokeWidth="1.6" fill="none" />
      <path
        d="M11.0521 13.8776C11.0521 13.9328 11.0969 13.9776 11.1521 13.9776H12.1725C12.2277 13.9776 12.2725 13.9328 12.2725 13.8776V13.8273C12.2809 13.3077 12.3523 12.9479 12.5051 12.6631C12.6572 12.3796 12.8962 12.1593 13.2624 11.9304C14.0361 11.4598 14.5004 10.7748 14.5004 9.79592C14.5004 9.10742 14.2483 8.50651 13.7975 8.07803C13.347 7.64977 12.7046 7.4 11.9344 7.4C11.2253 7.4 10.5834 7.61896 10.1092 8.03033C9.63394 8.44264 9.33351 9.04292 9.30049 9.79151C9.29929 9.81877 9.30927 9.84534 9.32814 9.86505C9.347 9.88477 9.3731 9.89592 9.40039 9.89592H10.4718C10.5246 9.89592 10.5683 9.85492 10.5716 9.80226C10.602 9.32453 10.7827 8.99827 11.0299 8.7904C11.2789 8.58099 11.6044 8.48435 11.9344 8.48435C12.6884 8.48435 13.28 8.9762 13.28 9.7449C13.28 10.3765 12.9204 10.8327 12.4438 11.122L12.4437 11.122C12.0232 11.3774 11.673 11.6376 11.4301 12.0484C11.1874 12.4586 11.0585 13.0068 11.0521 13.8257V13.8265V13.8776ZM11.6963 16.4946C12.1725 16.4946 12.5616 16.1054 12.5616 15.6293C12.5616 15.1531 12.1725 14.7639 11.6963 14.7639C11.2202 14.7639 10.831 15.1531 10.831 15.6293C10.831 16.1054 11.2202 16.4946 11.6963 16.4946Z"
        fill={color}
        strokeWidth="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
);

QuestionMarkIcon.displayName = 'QuestionMarkIcon';
