import dynamic from 'next/dynamic';
import React, { FC, useContext, useEffect, useMemo } from 'react';
import Modal from 'ui/specific/modal/Modal';
import { ModalWizardConfig } from 'ui/specific/modal/ModalWizard';
import { BooleanParam, createEnumParam, useQueryParam } from 'use-query-params';
import { QS } from 'utils/queryStringParams';
import { useAuthContext } from '../useAuth';
import { ModalConfig, ModalState } from './types';
import { createModalContext } from './utils';

// Setup
type ModalParamValue = 'pi' | 'sl' | 'da';
type ModalName = 'ProfileInfo' | 'StartLibrary' | 'Finish';
type Config = ModalConfig<ModalName, ModalParamValue>;
type State = ModalState<ModalName>;

const ModalMap: Config[] = [
  {
    name: 'ProfileInfo',
    url: 'pi',
    component: dynamic(() => import('containers/OnboardingModals/ProfileInfo')),
  },
  {
    name: 'StartLibrary',
    url: 'sl',
    component: dynamic(() => import('containers/OnboardingModals/StartLibrary')),
  },
  { name: 'Finish', url: 'da', component: dynamic(() => import('containers/OnboardingModals/Finish')) },
];
const ModalContext = createModalContext<ModalName>();

// Wrapper component
export const OnboardingModalProvider: FC = ({ children }) => {
  const { setFirstSignIn, isSignedIn } = useAuthContext();

  const ModalParam = useMemo(() => createEnumParam(ModalMap.map((m) => m.url)), [ModalMap]);
  const [isWizard, setIsWizard] = useQueryParam(QS.onboardingWizard, BooleanParam);
  const [activeModalUrl, setActiveModalUrl] = useQueryParam(QS.onboardingModal, ModalParam);

  // Enable wizard mode if relevant
  useEffect(() => {
    if (isWizard && !activeModalUrl) {
      setActiveModalUrl(ModalMap[0].url);
    }
  }, [isWizard]);

  const close = () => {
    setFirstSignIn(false);
    setIsWizard(undefined, 'push');
    setActiveModalUrl(undefined, 'push');
    window.scrollTo(0, 0); // Make sure we are at the top after the onboarding closes
  };

  const protectedClose = () => {
    const areYouSure = confirm('Are you sure you want to leave?');
    if (areYouSure) close();
  };

  const startWizard = () => setIsWizard(true);

  const setModal = (name: ModalName) => {
    const url = ModalMap.find((m) => m.name === name)?.url || ModalMap[0].url;
    setActiveModalUrl(url);
  };

  // Wizard stuff:
  const { activeModal, wizardConfig } = useMemo(() => {
    const indexOfActiveModal = activeModalUrl ? ModalMap.findIndex((x) => x.url === activeModalUrl) : -1;
    const indexOfPrevModal = Math.max(indexOfActiveModal - 1, 0);
    const indexOfNextModal = Math.min(indexOfActiveModal + 1, ModalMap.length);
    const activeModal = ModalMap[indexOfActiveModal];

    const wizardConfig: ModalWizardConfig | undefined = isWizard
      ? {
          currentStep: activeModalUrl ? indexOfActiveModal + 1 : 0,
          totalSteps: ModalMap.length,
          goBack: () => setActiveModalUrl(ModalMap[indexOfPrevModal].url),
          goNext: () => setActiveModalUrl(ModalMap[indexOfNextModal].url),
        }
      : undefined;

    return { activeModal, wizardConfig };
  }, [ModalMap, activeModalUrl, isWizard]);

  return (
    <>
      <ModalContext.Provider value={{ startWizard, setModal }}>
        {children}
        {activeModal && isSignedIn && (
          <Modal hideCloseButton close={protectedClose} wizardConfig={wizardConfig}>
            {React.createElement(activeModal.component, { close, wizardConfig })}
          </Modal>
        )}
      </ModalContext.Provider>
    </>
  );
};

export const useOnboardingModalContext = (): State => {
  return useContext(ModalContext);
};
