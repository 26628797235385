import classNames from 'classnames';
import { forwardRef, ReactNode } from 'react';
import { ChangeHandler } from 'react-hook-form';
import styles from './Checkbox.module.scss';

const CheckedIcon = () => (
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 5L5.16667 9L12.5 1" stroke="var(--textPrimary)" strokeWidth="1.6" strokeLinecap="round" />
  </svg>
);

type VisualCheckboxProps = {
  isChecked: boolean;
};
export const VisualCheckbox = ({ isChecked }: VisualCheckboxProps): JSX.Element => {
  return (
    <span className={styles.checkbox}>
      <input type="checkbox" checked={isChecked} readOnly />
      <span className={styles.box}>
        <CheckedIcon />
      </span>
    </span>
  );
};

type Props = {
  children?: ReactNode;
  name?: string;
  checked?: boolean;
  setChecked?: (newVal: boolean) => void;
  onChange?: ChangeHandler;
  disabled?: boolean;
};

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ children, name, checked, setChecked, onChange, disabled }: Props, ref) => {
    return (
      <label className={styles.checkbox}>
        <input
          type="checkbox"
          name={name}
          ref={ref}
          {...(checked !== undefined && { checked })}
          {...(setChecked !== undefined && {
            onChange: (e) => setChecked(e.target.checked),
          })}
          {...(onChange !== undefined && { onChange })}
          disabled={disabled}
        />
        <span className={styles.box}>
          <CheckedIcon />
        </span>
        {children && <span className={classNames(styles.text)}>{children}</span>}
      </label>
    );
  }
);

Checkbox.displayName = 'Checkbox';
