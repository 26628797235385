import constate from 'constate';
import { GoodReadsImportStatus, useUnseenNotificationsCountQuery } from 'generated/graphql';
import { useMemo } from 'react';
import { useAuthContext } from './useAuth';
import { usePrevious } from './usePrevious';

function useNotifications() {
  const { profile } = useAuthContext();

  const { data, refetch: refetchNotificationsCount } = useUnseenNotificationsCountQuery({
    skip: !profile,
    fetchPolicy: 'network-only',
  });

  const hasNotifications: boolean = useMemo(() => {
    if (data?.unseenNotificationsCount?.count && data.unseenNotificationsCount.count > 0) {
      return true;
    }
    return false;
  }, [data]);

  const hasNotificationsPrev = usePrevious(hasNotifications);

  const notificationsCount: number = useMemo(() => {
    if (data?.unseenNotificationsCount?.count && data.unseenNotificationsCount.count > 0) {
      return data.unseenNotificationsCount.count;
    }
    return 0;
  }, [data]);

  const goodReadsImportStatus: GoodReadsImportStatus | null = useMemo(() => {
    if (!data?.unseenNotificationsCount?.goodReadsImportStatus) {
      return null;
    }
    return data?.unseenNotificationsCount?.goodReadsImportStatus;
  }, []);

  return {
    refetchNotificationsCount,
    hasNotifications,
    notificationsCount,
    hasNotificationsPrev,
    goodReadsImportStatus,
  };
}

const [NotificationsProvider, useNotificationsContext] = constate(useNotifications);

export { NotificationsProvider, useNotificationsContext };
