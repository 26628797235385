import { IconProps } from './type';

export const NotificationIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.18219 9.70753C6.81995 6.80963 9.07955 4.25 12 4.25V4.25C14.9205 4.25 17.18 6.80963 16.8178 9.70753L16.5187 12.1005C16.5066 12.1974 16.5273 12.2955 16.5775 12.3792L18.5913 15.7355C18.9912 16.402 18.5111 17.25 17.7338 17.25H6.26619C5.4889 17.25 5.00878 16.402 5.4087 15.7355L7.42248 12.3792C7.4727 12.2955 7.49342 12.1974 7.48131 12.1005L7.18219 9.70753Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M14.5 17.25C14.5 18.6307 13.3807 19.75 12 19.75C10.6193 19.75 9.5 18.6307 9.5 17.25"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </svg>
);

export const NotificationDisplayIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.57625 12.9434C9.09327 9.0795 12.1061 5.66666 16 5.66666V5.66666C19.8939 5.66666 22.9067 9.0795 22.4237 12.9434L22.0249 16.134C22.0088 16.2631 22.0364 16.394 22.1034 16.5056L25.0913 21.4855C25.4912 22.152 25.0111 23 24.2338 23H7.76619C6.98889 23 6.50878 22.152 6.9087 21.4855L9.89663 16.5056C9.96361 16.394 9.99123 16.2631 9.97508 16.134L9.57625 12.9434Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M19.3334 23C19.3334 24.8409 17.841 26.3333 16.0001 26.3333C14.1591 26.3333 12.6667 24.8409 12.6667 23"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </svg>
);
