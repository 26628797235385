import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import classNames from 'classnames';
import { useScreenSize } from 'hooks/useScreenSize';
import { useRouter } from 'next/router';
import { ReactNode, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import { CSSTransition } from 'react-transition-group';
import { ButtonSimple } from 'ui/generic';
import styles from './MobileDropdown.module.scss';
import MobileDropdownContent from './MobileDropdownContent';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  closeMe: () => void;
  noScroll?: boolean;
  contentClass?: string;
};

type Size = {
  width: number | null;
  height: number | null;
};

const MobileDropdown = ({ children, isOpen, closeMe, noScroll, contentClass }: Props): JSX.Element => {
  const [showDropdown, setShowDropdown] = useState(false);
  const { smScreen } = useScreenSize();
  const [contentHeight, setContentHeight] = useState<number | null>(null);
  const element = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const menuRef = useRef<HTMLDivElement>(null);

  const onClickOutside = useCallback((e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (menuRef.current && !menuRef.current.contains(e.target as Node)) {
      setShowDropdown(false);
    }
  }, []);

  useLayoutEffect(() => (!showDropdown && isOpen ? setShowDropdown(true) : setShowDropdown(false)), [isOpen]);

  useEffect(() => {
    router.events.on('routeChangeComplete', closeMe);
    return () => router.events.off('routeChangeComplete', closeMe);
  }, [closeMe, router.events]);

  useEffect(() => {
    if (isOpen) document.addEventListener('click', onClickOutside);
    else document.removeEventListener('click', onClickOutside);
    return () => document.removeEventListener('click', onClickOutside);
  }, [isOpen, onClickOutside]);

  useLayoutEffect(() => {
    if (!element.current) return;
    disableBodyScroll(element.current, { reserveScrollBarGap: true });
    return () => clearAllBodyScrollLocks();
  }, [isOpen]);

  const onSize = (size: Size) => {
    if (contentHeight) return;
    setContentHeight(size.height);
  };

  if (smScreen && isOpen && children) {
    return (
      <Portal>
        <CSSTransition
          in={showDropdown}
          timeout={200}
          classNames={{
            enter: styles.backdropEnter,
            enterActive: styles.backdropEnterActive,
            exit: styles.backdropExit,
            exitActive: styles.backdropExitActive,
          }}
        >
          <div className={styles.backdrop} />
        </CSSTransition>
        <CSSTransition
          in={showDropdown}
          timeout={200}
          classNames={{
            enter: styles.optionsEnter,
            enterActive: styles.optionsEnterActive,
            exit: styles.optionsExit,
            exitActive: styles.optionsExitActive,
          }}
          onExited={() => {
            closeMe();
          }}
        >
          <div
            className={classNames(styles.container, {
              [styles.noScroll]: (contentHeight && contentHeight < 300) || noScroll,
            })}
            onClick={(e) => {
              e.stopPropagation();
              setShowDropdown(false);
            }}
            role="mobile-dropdown"
          >
            <div className={styles.inner} ref={element}>
              <MobileDropdownContent contentClass={contentClass} onSize={onSize}>
                {children}
              </MobileDropdownContent>
            </div>
            <div
              className={classNames(styles.close, {
                [styles.noScroll]: (contentHeight && contentHeight < 300) || noScroll,
              })}
            >
              <ButtonSimple variant="faded" onClick={() => setShowDropdown(false)}>
                Close
              </ButtonSimple>
            </div>
          </div>
        </CSSTransition>
      </Portal>
    );
  }
  return <></>;
};

export default MobileDropdown;
