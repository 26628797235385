import { IconProps } from './type';

export const LockIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="5" y="9.64093" width="14" height="9.625" rx="2" stroke={color} strokeWidth="1.6" />
    <path
      d="M12 13L12.0002 15.8689"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8077 9.38462V7.76923C15.8077 5.68754 14.1202 4 12.0385 4V4C9.95683 4 8.26929 5.68754 8.26929 7.76923V9.38462"
      stroke={color}
      strokeWidth="1.6"
    />
  </svg>
);
