import { IconProps } from './type';

export const ChevronDownIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.5 9L12 15.5L18.5 9" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
  </svg>
);

export const ChevronRightIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.75 18.75L15.25 12.25L8.75 5.75" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
  </svg>
);
