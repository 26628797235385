import classNames from 'classnames';
import Link from 'next/link';
import { Divider } from 'ui/generic';
import styles from './ActionItem.module.scss';
import { ActionItemClickable, ActionItemLink, ActionItemLinkExternal, ActionItemType } from './types';

type Props = {
  item: ActionItemType;
};

export const ActionItem = ({ item }: Props): JSX.Element | null => {
  let itemKey = '';
  if ('variant' in item && item.variant !== undefined) {
    itemKey = item.variant;
  }
  if ('render' in item && 'onClick' in item) {
    return (
      <div className={styles.container}>
        <button
          data-arrow-keys
          className={classNames(styles.inner, { [styles.selected]: item.selected })}
          {...(item.onClick ? { onClick: item.onClick } : {})}
        >
          {item.render}
        </button>
      </div>
    );
  } else if ('href' in item && 'render' in item) {
    return (
      <div className={styles.container}>
        <Link as={item.as} href={item.href}>
          <a
            data-arrow-keys
            className={classNames(styles.inner, styles[itemKey], { [styles.selected]: item.selected })}
          >
            {item.render}
          </a>
        </Link>
      </div>
    );
  } else if ('render' in item) {
    return <div className={styles.container}>{item.render}</div>;
  } else if ('onClick' in item) {
    return (
      <div className={classNames(styles.container)}>
        <button
          data-arrow-keys
          className={classNames(styles.inner, styles[itemKey], { [styles.selected]: item.selected })}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            item.onClick(item.value, e);
          }}
        >
          <ActionItemContent item={item} />
        </button>
      </div>
    );
  } else if ('href' in item && 'target' in item && !('as' in item)) {
    return (
      <div className={styles.container}>
        <a
          data-arrow-keys
          className={classNames(styles.inner, styles[itemKey], { [styles.selected]: item.selected })}
          href={item.href}
          target={item.target}
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          <ActionItemContent item={item} />
        </a>
      </div>
    );
  } else if ('href' in item) {
    return (
      <div className={styles.container}>
        <Link as={item.as} href={item.href}>
          <a
            data-arrow-keys
            className={classNames(styles.inner, styles[itemKey], { [styles.selected]: item.selected })}
          >
            <ActionItemContent item={item} />
          </a>
        </Link>
      </div>
    );
  } else if ('divider' in item) {
    return <Divider variant={item.divider} spacing={item.spacing} />;
  } else return null;
};

const ActionItemContent = ({
  item,
}: {
  item: ActionItemLink | ActionItemLinkExternal | ActionItemClickable<unknown>;
}): JSX.Element => {
  return (
    <>
      <div className={styles.left}>
        {item.icon && <div className={styles.icon}>{item.icon}</div>}{' '}
        <div
          className={classNames(styles.title, { [styles.noIconSpacer]: !item.icon })}
          {...(typeof item.title === 'string' ? { title: item.title } : {})}
        >
          {item.title}
        </div>
      </div>
      {item.subtitle !== null && item.subtitle !== undefined && (
        <div className={styles.right}>{item.subtitle}</div>
      )}
    </>
  );
};
