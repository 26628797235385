import { IconProps } from './type';

export const PrivateIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3849 7.28588C12.9473 7.22965 12.5095 7.20088 12.0696 7.20022L12.0225 7.20026L11.9992 7.2002C11.9632 7.2002 11.9273 7.20063 11.8915 7.20149C8.94938 7.2478 6.04761 8.54982 2.54792 10.9395C2.18305 11.1887 2.08923 11.6864 2.33838 12.0513C2.58753 12.4162 3.0853 12.51 3.45018 12.2609C5.16026 11.0932 6.66167 10.237 8.04484 9.66841C7.75942 10.2516 7.5992 10.9072 7.5992 11.6002C7.5992 12.046 7.66551 12.4764 7.78882 12.882L9.20224 11.4685C9.26897 10.0269 10.4259 8.86996 11.8675 8.80324L13.3849 7.28588ZM11.9283 14.3993C11.9519 14.3999 11.9755 14.4002 11.9992 14.4002C13.5456 14.4002 14.7992 13.1466 14.7992 11.6002C14.7992 11.5765 14.7989 11.5529 14.7983 11.5293L16.1605 10.1671C16.3152 10.6163 16.3992 11.0984 16.3992 11.6002C16.3992 14.0302 14.4293 16.0002 11.9992 16.0002C11.4975 16.0002 11.0153 15.9162 10.5661 15.7615L11.9283 14.3993ZM16.4695 9.85816C17.7112 10.4212 19.0469 11.2156 20.5419 12.2567C20.9044 12.5092 21.403 12.4199 21.6555 12.0574C21.908 11.6948 21.8188 11.1962 21.4562 10.9437C20.1225 10.0149 18.8735 9.24869 17.6709 8.65669L16.4695 9.85816Z"
      fill={color}
    />
    <path
      d="M19.499 4L4.49902 19"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
