import { IconProps } from './type';

export const LinkIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8038 15.6707L9.15416 17.3204C8.47023 18.0043 7.36266 18.0043 6.67931 17.3205C5.99583 16.637 5.99583 15.5293 6.67918 14.846L9.97912 11.5461C10.6625 10.8627 11.7702 10.8627 12.4535 11.5461C12.6813 11.7738 13.0506 11.7738 13.2785 11.5461C13.5063 11.3183 13.5063 10.9489 13.2785 10.7211C12.1395 9.58209 10.2931 9.58209 9.15416 10.7211L5.85424 14.021C4.71525 15.16 4.71525 17.0063 5.85424 18.1453C6.99309 19.2849 8.83956 19.2849 9.97915 18.1453L11.6288 16.4956C11.8566 16.2678 11.8566 15.8985 11.6288 15.6706C11.401 15.4428 11.0317 15.4429 10.8038 15.6707Z"
      fill={color}
    />
    <path
      d="M18.1454 5.85424C17.0064 4.71525 15.1595 4.71525 14.0205 5.85424L12.0412 7.8335C11.8134 8.0613 11.8134 8.43066 12.0412 8.65847C12.269 8.88627 12.6384 8.88627 12.8662 8.65847L14.8454 6.6792C15.5288 5.99583 16.6371 5.99583 17.3204 6.6792C18.0038 7.36255 18.0038 8.47023 17.3204 9.15358L13.6909 12.7831C13.0075 13.4665 11.8999 13.4665 11.2165 12.7831C10.9887 12.5553 10.6194 12.5553 10.3916 12.7831C10.1638 13.0109 10.1638 13.3803 10.3916 13.6081C11.5305 14.7471 13.3769 14.7471 14.5159 13.6081L18.1454 9.97858C19.2844 8.83959 19.2844 6.99322 18.1454 5.85424Z"
      fill={color}
    />
    <path
      d="M10.8038 15.6707L9.15416 17.3204C8.47023 18.0043 7.36266 18.0043 6.67931 17.3205C5.99583 16.637 5.99583 15.5293 6.67918 14.846L9.97912 11.5461C10.6625 10.8627 11.7702 10.8627 12.4535 11.5461C12.6813 11.7738 13.0506 11.7738 13.2785 11.5461C13.5063 11.3183 13.5063 10.9489 13.2785 10.7211C12.1395 9.58209 10.2931 9.58209 9.15416 10.7211L5.85424 14.021C4.71525 15.16 4.71525 17.0063 5.85424 18.1453C6.99309 19.2849 8.83956 19.2849 9.97915 18.1453L11.6288 16.4956C11.8566 16.2678 11.8566 15.8985 11.6288 15.6706C11.401 15.4428 11.0317 15.4429 10.8038 15.6707Z"
      stroke={color}
      strokeWidth="0.4"
    />
    <path
      d="M18.1454 5.85424C17.0064 4.71525 15.1595 4.71525 14.0205 5.85424L12.0412 7.8335C11.8134 8.0613 11.8134 8.43066 12.0412 8.65847C12.269 8.88627 12.6384 8.88627 12.8662 8.65847L14.8454 6.6792C15.5288 5.99583 16.6371 5.99583 17.3204 6.6792C18.0038 7.36255 18.0038 8.47023 17.3204 9.15358L13.6909 12.7831C13.0075 13.4665 11.8999 13.4665 11.2165 12.7831C10.9887 12.5553 10.6194 12.5553 10.3916 12.7831C10.1638 13.0109 10.1638 13.3803 10.3916 13.6081C11.5305 14.7471 13.3769 14.7471 14.5159 13.6081L18.1454 9.97858C19.2844 8.83959 19.2844 6.99322 18.1454 5.85424Z"
      stroke={color}
      strokeWidth="0.4"
    />
  </svg>
);
