import { useSupportersQuery } from 'generated/graphql';

type Props = {
  profileId?: string;
};

export function useIsSupporter({ profileId }: Props) {
  const { data } = useSupportersQuery();

  if (!profileId) return false;
  if (!data?.supporters) return false;

  return data.supporters.some((p) => p.id === profileId);
}
