import { IconProps } from './type';

export const UserIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 16C16 16 14.7274 17 12.0001 17C9.27282 17 8 16 8 16"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M3.8 10.3636C3.8 6.73864 6.73864 3.8 10.3636 3.8H13.6364C17.2614 3.8 20.2 6.73864 20.2 10.3636V13.6364C20.2 17.2614 17.2614 20.2 13.6364 20.2H10.3636C6.73864 20.2 3.8 17.2614 3.8 13.6364V10.3636Z"
        stroke={color}
        strokeWidth="1.6"
      />
      <rect x="7" y="8" width="1.6" height="5" rx="0.8" fill={color} />
      <rect x="15.3999" y="8" width="1.6" height="5" rx="0.8" fill={color} />
    </svg>
  );
};
