/* eslint-disable @typescript-eslint/no-explicit-any */
export type CameraStatus = 'PENDING_AUTHORIZATION' | 'READY' | 'NOT_AUTHORIZED';

type ShareMessages =
  // GENERIC
  | { type: 'SHARE_IMAGE_BY_URL_GENERIC'; value: string }
  | { type: 'SHARE_IMAGE_BY_URL_GENERIC_RESPONSE'; value: boolean }

  // INSTAGRAM
  | { type: 'SHARE_IMAGE_BY_URL_INSTAGRAM'; value: string }
  | { type: 'SHARE_IMAGE_BY_URL_INSTAGRAM_RESPONSE'; value: boolean }

  // INSTAGRAM STORY
  | { type: 'SHARE_IMAGE_BY_URL_INSTAGRAM_STORY'; value: string }
  | { type: 'SHARE_IMAGE_BY_URL_INSTAGRAM_STORY_RESPONSE'; value: boolean }

  // CAMERA ROLL
  | { type: 'SAVE_IMAGE'; value: string }
  | { type: 'SAVE_IMAGE_RESPONSE'; value: boolean }

  // FALLBACK URL SHARING
  | { type: 'SHARE_FALLBACK'; value: ShareData }
  | { type: 'SHARE_FALLBACK_ACK'; value: boolean };

type GenericMessages =
  | 'LOGOUT'
  | 'MUTATION'
  | 'NOTIFICATIONS_REFRESH'
  | 'OPEN_MOMENT_CAMERA'
  | 'CLOSE_MOMENT_CAMERA'
  | 'CLOSE_MOMENT_SCREEN'
  | 'OPEN_BARCODE_CAMERA'
  | 'CLOSE_BARCODE_CAMERA'
  | 'HAPTIC_IMPACT_MEDIUM'
  | { type: 'TOKEN'; value: string }
  | { type: 'PROFILE'; value: { id: string; handle: string; name: string } }
  | { type: 'REDIRECT'; value: string }
  | { type: 'MOMENT_TEXT'; value: string }
  | { type: 'ISBN_CODE'; value: string }
  | { type: 'CAMERA_STATUS'; value: CameraStatus }
  | { type: 'REACT_NATIVE_TOKEN'; value: string }
  | { type: 'MIXPANEL_EVENT'; value: { name: string; properties?: Record<string, string> } }
  | { type: 'WEB_APP_FEATURES'; value: string[] }
  | { type: 'MOBILE_APP_FEATURES'; value: string[] };

export type RNMessage = ShareMessages | GenericMessages;

// Used by the mobile app webview
export function sendRnMessage(message: RNMessage): void {
  if (typeof window !== 'undefined' && window.ReactNativeWebView) {
    if (typeof message === 'string') {
      window.ReactNativeWebView.postMessage(message);
    } else {
      window.ReactNativeWebView.postMessage(JSON.stringify(message));
    }
  }
}

export function parseMessage(message: string): RNMessage {
  try {
    const result = JSON.parse(message);
    return result;
  } catch (error) {
    return message as RNMessage;
  }
}

type InternalEvent =
  | 'MOMENT_TEXT'
  | 'CLOSE_MOMENT_CAMERA'
  | 'CLOSE_MOMENT_SCREEN'
  | 'ISBN_CODE'
  | 'SHARE_IMAGE_BY_URL_GENERIC_RESPONSE'
  | 'SHARE_IMAGE_BY_URL_INSTAGRAM_RESPONSE'
  | 'SHARE_IMAGE_BY_URL_INSTAGRAM_STORY_RESPONSE'
  | 'SAVE_IMAGE_RESPONSE'
  | 'SHARE_FALLBACK_RESPONSE';

class _EventBus {
  private bus: Record<string, any>;

  constructor() {
    this.bus = {};
  }

  $off(id: string) {
    delete this.bus[id];
  }

  $on(id: InternalEvent, callback: (params: any) => void) {
    this.bus[id] = callback;
  }

  $emit(id: InternalEvent, ...params: unknown[]) {
    if (this.bus[id]) this.bus[id](...params);
  }
}

/** 
* @deprecated
deprecating this event bus in favor of Mitt https://www.npmjs.com/package/mitt
**/

export const EventBus = new _EventBus();
