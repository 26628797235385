import { IconProps } from './type';

export const ReplyIcon = ({ color = 'currentColor', size = 24 }: IconProps): JSX.Element => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 14L15.3889 16.5M17.5 14L15.3889 11.5M17.5 14C17.5 14 16 14 14 14C9.78111 14 8.5 11.5 8.5 9"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </svg>
);
