import DataLoader from 'dataloader';
import {
  UnseenChatNotificationsByRoomsDocument,
  UnseenChatNotificationsByRoomsQuery,
  UnseenChatNotificationsByRoomsQueryVariables,
  UnseenChatNotificationsByThreadsDocument,
  UnseenChatNotificationsByThreadsQuery,
  UnseenChatNotificationsByThreadsQueryVariables,
  UnseenChatNotificationsDocument,
  UnseenChatNotificationsQuery,
  UnseenChatNotificationsQueryVariables,
} from 'generated/graphql';
import getApolloClient from 'utils/getApolloClient';
import create from 'zustand';

type State = {
  unseen: number;
  fetchUnseen: () => void;
  roomUnseen: Record<string, number>;
  fetchRoomUnseen: (roomId: string) => void;
  threadUnseen: Record<string, number>;
  fetchThreadUnseen: (threadId: string) => void;
};

export const useNotificationCount = create<State>((set) => ({
  // General
  unseen: 0,
  fetchUnseen: async () => {
    getUnseen().then((unseen) => set({ unseen }));
  },

  // By room
  roomUnseen: {},
  fetchRoomUnseen: async (roomId: string) => {
    roomDataLoader.clear(roomId);
    const unseen = await roomDataLoader.load(roomId);
    set((state) => ({ roomUnseen: { ...state.roomUnseen, [roomId]: unseen } }));
    getUnseen().then((unseen) => set({ unseen }));
  },

  // By thread
  threadUnseen: {},
  fetchThreadUnseen: async (threadId: string) => {
    threadDataLoader.clear(threadId);
    const unseen = await threadDataLoader.load(threadId);
    set((state) => ({ threadUnseen: { ...state.threadUnseen, [threadId]: unseen } }));
    getUnseen().then((unseen) => set({ unseen }));
  },
}));

async function getUnseen() {
  const result = await getApolloClient().query<
    UnseenChatNotificationsQuery,
    UnseenChatNotificationsQueryVariables
  >({
    query: UnseenChatNotificationsDocument,
    fetchPolicy: 'network-only',
  });
  return result.data.unseenChatNotifications;
}

const roomDataLoader = new DataLoader(
  async (keys: readonly string[]) => {
    const result = await getApolloClient().query<
      UnseenChatNotificationsByRoomsQuery,
      UnseenChatNotificationsByRoomsQueryVariables
    >({
      query: UnseenChatNotificationsByRoomsDocument,
      variables: { chatRoomIds: [...keys] },
      fetchPolicy: 'network-only',
    });
    return result.data.unseenChatNotificationsByRooms;
  },
  {
    cache: true,
    cacheKeyFn: (id) => `getUnseenByRooms:${id}`,
  }
);

const threadDataLoader = new DataLoader(
  async (keys: readonly string[]) => {
    const result = await getApolloClient().query<
      UnseenChatNotificationsByThreadsQuery,
      UnseenChatNotificationsByThreadsQueryVariables
    >({
      query: UnseenChatNotificationsByThreadsDocument,
      variables: { chatMessageIds: [...keys] },
      fetchPolicy: 'network-only',
    });
    return result.data.unseenChatNotificationsByThreads;
  },
  {
    cache: true,
    cacheKeyFn: (id) => `getUnseenByThreads:${id}`,
  }
);
