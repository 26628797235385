import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

export const Portal: React.FC = ({ children }) => {
  const [portalContainer] = useState(document?.createElement('div') || ({} as HTMLElement));
  useEffect(() => {
    if (typeof document === 'undefined') return;
    let portalRoot = document.getElementById('portal-root');
    if (!portalRoot) {
      const tempEl = document.createElement('div');
      tempEl.id = 'portal-root';
      document.querySelector('body')?.appendChild(tempEl);
      portalRoot = tempEl;
    }
    portalRoot.appendChild(portalContainer);
    return function cleanup() {
      portalRoot?.removeChild(portalContainer);
    };
  }, [portalContainer]);
  return ReactDOM.createPortal(children, portalContainer);
};

export default Portal;
