import { IconProps } from './type';

export const SearchDisplayIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14.0833" cy="14.0833" r="7.95" stroke={color} strokeWidth="1.6" />
    <line
      x1="20.2146"
      y1="20.3333"
      x2="27.0833"
      y2="27.202"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </svg>
);
