import classNames from 'classnames';
import { ReactNode } from 'react';
import { withSize } from 'react-sizeme';
import styles from './MobileDropdown.module.scss';

type ContentProps = {
  children: ReactNode;
  contentClass?: string;
};

const MobileDropdownContent = ({ children, contentClass }: ContentProps): JSX.Element => {
  return <div className={classNames(styles.content, contentClass)}>{children}</div>;
};

export default withSize({ monitorHeight: true })(MobileDropdownContent);
