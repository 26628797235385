import { useGoalParticipationsByHandleQuery } from 'generated/graphql';

const PAGE_SIZE = 20;

export const useGoalParticipations = (
  profileHandle?: string,
  filters?: { earliestEndDate?: string; latestEndDate?: string }
) => {
  const { data, loading, fetchMore } = useGoalParticipationsByHandleQuery({
    variables: {
      handle: profileHandle || '',
      ...filters,
      limit: PAGE_SIZE,
      offset: 0,
    },
    skip: !profileHandle,
  });
  const participations = data?.goalParticipations || undefined;

  return { participations, loading, fetchMore, PAGE_SIZE };
};
