import { useMemo, useRef } from 'react';

import styles from './Star.module.scss';

interface Props {
  value: null | number;
  index: number;
  width: number;
  height: number;
  [props: string]: React.ReactNode;
}

export const Star = ({ value = null, index = 0, width = 40, height = 40 }: Props) => {
  const randomId = useRef(Math.random().toString(36).substr(2, 9));

  const offset = useMemo(() => {
    if (!value) return 0;
    if (value >= index && value < index + 1) {
      return value - index;
    }
    if (value >= index) return 1;
    if (value < index) return 0;
  }, [value]);

  return (
    <svg width={width} height={height} viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.3084 7.56332C19.5475 6.82542 20.5915 6.82541 20.8306 7.56332L23.2001 14.8772C23.307 15.2071 23.6143 15.4306 23.9612 15.4306L31.6335 15.4309C32.4079 15.4309 32.7305 16.4213 32.1047 16.8774L25.8936 21.4039C25.6141 21.6076 25.4972 21.9679 25.6037 22.2969L27.975 29.6177C28.2138 30.3551 27.3693 30.9673 26.7428 30.5108L20.5406 25.9914C20.2599 25.7869 19.8791 25.7869 19.5984 25.9914L13.3962 30.5108C12.7697 30.9673 11.9252 30.3551 12.164 29.6177L14.5353 22.2969C14.6418 21.9679 14.5249 21.6076 14.2454 21.4039L8.03433 16.8774C7.40853 16.4213 7.73111 15.4309 8.50548 15.4309L16.1778 15.4306C16.5247 15.4306 16.832 15.2071 16.9389 14.8772L19.3084 7.56332Z"
        strokeWidth="1.6"
        style={{ fill: `url(#${randomId.current})` }}
        stroke="var(--uiBlack)"
      />
      <defs>
        <linearGradient y2="0%" x2="100%" y1="0%" x1="0%" id={`${randomId.current}`}>
          <stop className={styles.part1} offset={offset} />
          <stop className={styles.part2} offset="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
