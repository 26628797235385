import classNames from 'classnames';
import styles from './LoadingSpinner.module.scss';

type Props = { color?: string };

const SVG = ({ color = '#dddddd' }: Props) => (
  <svg
    version="1.1"
    id="loader-1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="40px"
    height="40px"
    viewBox="0 0 50 50"
    xmlSpace="preserve"
  >
    <path
      fill={color}
      d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z"
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 25 25"
        to="360 25 25"
        dur="0.6s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);

const LoadingSpinner = ({ color }: Props): JSX.Element => {
  return (
    <div className={styles.loader}>
      <SVG color={color} />
    </div>
  );
};

export const SmallLoadingSpinner = ({ color }: Props): JSX.Element => {
  return (
    <div className={`${styles.loader} ${styles.small}`}>
      <SVG color={color} />
    </div>
  );
};

export const HalfPageLoadingSpinner = (): JSX.Element => {
  return (
    <div className={styles.halfPage}>
      <LoadingSpinner />
    </div>
  );
};

export const FullPageLoadingSpinner = (): JSX.Element => {
  return (
    <div className={styles.fullPage} role="loading-full-page">
      <LoadingSpinner />
    </div>
  );
};

export const FullWidthLoadingSpinner = (): JSX.Element => {
  return (
    <div className={styles.fullWidth}>
      <LoadingSpinner />
    </div>
  );
};

export const FadeLoader = ({ loading }: { loading: boolean }): JSX.Element => {
  return (
    <div
      className={classNames(styles.fadeLoader, {
        [styles.fadeLoaderVisible]: loading,
      })}
    >
      <FullWidthLoadingSpinner />
    </div>
  );
};

export default LoadingSpinner;
