import { dayjs } from './init-dayjs';

export const formatDate = (date: string, template: string): string => {
  return dayjs(date).format(template);
};

export function isDateOlderThanHours(date: string | Date, numberOfHours: number): boolean {
  return dayjs.utc(date).isBefore(dayjs().subtract(numberOfHours, 'hour'));
}

export function utcToLocal(date: string | Date): string {
  return dayjs.utc(date).local().toISOString();
}

export function isSameYear(fromDate: Date, toDate: Date): boolean {
  const fromYear = dayjs(fromDate).year();
  const toYear = dayjs(toDate).year();
  const isSameYear = fromYear === toYear;
  return isSameYear;
}

export function isSameMonth(fromDate: Date, toDate: Date): boolean {
  const fromMonth = dayjs(fromDate).month();
  const toMonth = dayjs(toDate).month();
  const isSameYear = fromMonth === toMonth;
  return isSameYear;
}

export function isSameDay(fromDate: Date, toDate: Date): boolean {
  const fromDay = dayjs(fromDate).date();
  const toDay = dayjs(toDate).date();
  const isSameDay = fromDay === toDay;
  return isSameDay;
}
export function isSameMinute(fromDate: Date, toDate: Date): boolean {
  const fromMinute = dayjs(fromDate).minute();
  const toMinute = dayjs(toDate).minute();
  const isSameMinute = fromMinute === toMinute;
  return isSameMinute;
}

export function formatDay(date: Date | string) {
  // if it's today, show "Today"
  if (isSameDay(dayjs(date).toDate(), new Date())) {
    return 'Today';
  }
  // if it's yesterday, show "Yesterday"
  if (isSameDay(dayjs(date).toDate(), dayjs().subtract(1, 'day').toDate())) {
    return 'Yesterday';
  }
  // if it's in the same year, show "Jan 1"
  if (isSameYear(dayjs(date).toDate(), new Date())) {
    return dayjs(date).format('MMM D');
  }
  // otherwise, show "Jan 1, 2020"
  return dayjs(date).format('MMM D, YYYY');
}
