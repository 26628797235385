import { IconProps } from './type';

export const TimeIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12" r="8.2" stroke={color} strokeWidth="1.6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 6.20002C12.4419 6.20002 12.8 6.55819 12.8 7.00002L12.8 12C12.8 12.4419 12.4419 12.8 12 12.8C11.5582 12.8 11.2 12.4419 11.2 12L11.2 7.00002C11.2 6.55819 11.5582 6.20002 12 6.20002Z"
      fill={color}
    />
    <path d="M12 12L15 13" stroke={color} strokeWidth="1.6" strokeLinecap="round" />
  </svg>
);
