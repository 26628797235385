import Head from 'next/head';

export const DocumentHead = () => {
  const title = 'Literal';
  const description =
    "Literal is the best platform to collectively explore the ideas of the world's greatest authors.";
  const image = 'https://literal.club/img/opengraphfallback.png';
  return (
    <Head>
      <title key="title">Literal</title>
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png?v=eEYRRdggA3" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png?v=eEYRRdggA3" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png?v=eEYRRdggA3" />
      <link rel="manifest" href="/site.webmanifest?v=eEYRRdggA4" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg?v=eEYRRdggA3" color="#000000" />
      <link rel="shortcut icon" href="/favicon.ico?v=eEYRRdggA3" />
      <meta name="apple-mobile-web-app-title" content="Literal" />
      <meta name="application-name" content="Literal" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1,maximum-scale=1,viewport-fit=cover,user-scalable=no"
      />
      <meta name="apple-mobile-web-app-status-bar-style" content="white" />

      <meta name="description" property="og:description" key="ogdescription" content={description} />
      <meta property="og:image" key="ogimage" content={image} />
      <meta property="og:title" key="ogtitle" content={title} />
      <meta property="twitter:title" key="twittertitle" content={title} />
      <meta property="twitter:image" key="twitterimage" content={image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:image:height" key="ogimageheight" content="314" />
      <meta property="og:image:width" key="ogimagewidth" content="600" />
    </Head>
  );
};
