import classnames from 'classnames';
import { useDeviceDetectContext } from 'hooks/useDeviceDetect';
import { useRouter } from 'next/router';
import { ReactNode, useMemo } from 'react';
import { ButtonSimple } from 'ui/generic';
import { ArrowLeftIcon, CloseIcon, HelpIcon } from 'ui/icons';
import Slot from 'ui/Slot';
import { routes } from 'utils/routes';
import styles from './MobileTopBar.module.scss';

type Props = {
  title?: string;
  onBack?: () => void;
  backIcon?: 'arrow' | 'close';
  customButton?: ReactNode;
  noBottomBorder?: boolean;
  variant?: 'underline' | 'bold' | 'faded' | 'accent' | 'basic';
};

export const MobileTopBar = ({
  title,
  onBack,
  backIcon,
  noBottomBorder,
  variant = 'basic',
}: Props): JSX.Element | null => {
  const router = useRouter();
  const { isShareable } = useDeviceDetectContext();

  const aditionalButtonConfig: Array<{ routes: string[]; component: ReactNode }> = [
    {
      routes: [routes.appPublicSignin, routes.appPublicWaitlist, routes.appPublicWaiting],
      component: (
        <a href="mailto:support@literal.club" target="_blank" rel="noreferrer" className={styles.supportLink}>
          <HelpIcon />
        </a>
      ),
    },
  ];

  const shareButton = useMemo(
    () => aditionalButtonConfig.find((config) => config.routes.includes(router.pathname))?.component,
    [router.pathname]
  );

  return (
    <>
      <div className={styles.pushdown}></div>
      <div className={classnames(styles.container, { [styles.noBottomBorder]: noBottomBorder })}>
        <div className={styles.buttonLeft}>
          <ButtonSimple
            variant={variant}
            iconOnly
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (onBack) {
                onBack();
              } else {
                const prev = window.location.pathname;
                const prevQuery = window.location.search;
                router.back();
                setTimeout(() => {
                  if (prev === window.location.pathname && prevQuery === window.location.search) {
                    router.push(routes.landing);
                  }
                }, 1000);
              }
            }}
          >
            {backIcon === 'close' ? <CloseIcon /> : <ArrowLeftIcon />}
          </ButtonSimple>
        </div>
        <div className={styles.title}>
          {title && <h1 className="uiBase center">{title}</h1>}
          {!title && <div id="top-bar-center-slot" className={styles.buttonSlot} />}
        </div>

        <div className={styles.buttonsRight}>
          <div id="top-bar-button-slot" className={styles.buttonSlot} />
          {isShareable && shareButton && <span className={styles.shareButton}>{shareButton}</span>}
        </div>
      </div>
    </>
  );
};

type ButtonSlotProps = {
  children: ReactNode;
};

export const MobileTopBarButtonSlot = ({ children }: ButtonSlotProps): JSX.Element => {
  return <Slot elementId="top-bar-button-slot">{children}</Slot>;
};

export const MobileTopBarCenterSlot = ({ children }: ButtonSlotProps): JSX.Element => {
  return <Slot elementId="top-bar-center-slot">{children}</Slot>;
};
