import * as Sentry from '@sentry/node';
import { useAuthContext } from 'hooks/useAuth';
import { useRouter } from 'next/router';
import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { isMobileApp, isMobileAppAndroid, isMobileAppIOs } from 'utils/device';
import { CameraStatus, EventBus, parseMessage, sendRnMessage } from 'utils/reactNative';
import useMixpanel from './useMixpanel';

type Props = {
  children: ReactNode;
};

type State = {
  cameraStatus: CameraStatus;
  mobileAppFeatures: string[];
  canUse: {
    barcodeScanner: boolean;
    goodreadsImport: boolean;
    imageSharing: boolean;
  };
};

export const Context = React.createContext<State>({
  cameraStatus: 'PENDING_AUTHORIZATION',
  mobileAppFeatures: [],
  canUse: { barcodeScanner: false, goodreadsImport: false, imageSharing: false },
});

export const ReactNativeContextProvider = ({ children }: Props): JSX.Element => {
  const router = useRouter();
  const { trackEvent } = useMixpanel();
  const { token, isLoading, profile } = useAuthContext();
  const [cameraStatus, setCameraStatus] = useState<CameraStatus>('PENDING_AUTHORIZATION');

  // Advertize which features are supported by 1) react-native app, and 2) webapp
  // If one or the other does not advertize back, or the features are not mutually supported,
  // then they will both know to not a potentially broken feature.
  const [mobileAppFeatures, setMobileAppFeatures] = useState<string[]>([]);
  const webAppFeatures = ['BARCODE_SCANNER', 'GOODREADS_IMPORT', 'IMAGE_SHARING'];

  const canUse = useMemo(() => {
    return {
      barcodeScanner: mobileAppFeatures.includes('BARCODE_SCANNER'),
      goodreadsImport: mobileAppFeatures.includes('GOODREADS_IMPORT'),
      imageSharing: mobileAppFeatures.includes('IMAGE_SHARING'),
    };
  }, [mobileAppFeatures]);

  // Send some user info to the react native app
  useEffect(() => {
    if (!isMobileApp() || isLoading) {
      return;
    }

    if (token) sendRnMessage({ type: 'TOKEN', value: token });
    if (profile) sendRnMessage({ type: 'PROFILE', value: profile });
  }, [token, isLoading, profile]);

  // On mount
  useEffect(() => {
    if (!isMobileApp()) {
      return;
    }

    const handleMessage = (e: MessageEvent) => {
      const message = parseMessage(e.data);

      if (typeof message === 'string') {
        if (message === 'CLOSE_MOMENT_SCREEN') {
          EventBus.$emit('CLOSE_MOMENT_SCREEN');
        }
      } else if (typeof message === 'object') {
        if (message.type === 'REDIRECT') {
          router.push(message.value);
        } else if (message.type === 'MOMENT_TEXT') {
          EventBus.$emit('MOMENT_TEXT', message.value);
        } else if (message.type === 'ISBN_CODE') {
          EventBus.$emit('ISBN_CODE', message.value);
        } else if (message.type === 'CAMERA_STATUS') {
          setCameraStatus(message.value);
        } else if (message.type === 'REACT_NATIVE_TOKEN') {
          if (!token) {
            Sentry.setExtras({
              REACT_NATIVE_TOKEN: message.value,
              useAgent: navigator.userAgent,
            });
            Sentry.captureException(new Error('React Native token is set while no webview token is present'));
          }
        } else if (message.type === 'MIXPANEL_EVENT') {
          trackEvent(message.value.name, message.value.properties);
        } else if (message.type === 'MOBILE_APP_FEATURES') {
          setMobileAppFeatures(message.value);
        } else if (message.type === 'SHARE_IMAGE_BY_URL_GENERIC_RESPONSE') {
          EventBus.$emit('SHARE_IMAGE_BY_URL_GENERIC_RESPONSE', message.value);
        } else if (message.type === 'SAVE_IMAGE_RESPONSE') {
          EventBus.$emit('SAVE_IMAGE_RESPONSE', message.value);
        }
      }
    };

    if (isMobileAppIOs()) {
      window.addEventListener('message', handleMessage);
      return () => {
        window.removeEventListener('message', handleMessage);
      };
    } else if (isMobileAppAndroid()) {
      // Android bug https://github.com/react-native-webview/react-native-webview/issues/1688#issuecomment-735434550
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.addEventListener('message', handleMessage);
      return () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.removeEventListener('message', handleMessage);
      };
    }
  }, []);

  // Advertize the "special" features supported by the webapp
  // Expecting the react-native app to advertize back with the same features
  useEffect(() => {
    if (!isMobileApp()) return;

    sendRnMessage({ type: 'WEB_APP_FEATURES', value: webAppFeatures });
  }, []);

  // Fix issues with sharing on android mobile devices where navigator.share is not available
  // Using the following approach as fallback:
  // https://github.com/react-native-webview/react-native-webview/issues/1262#issuecomment-933315821
  useEffect(() => {
    if (!isMobileApp()) return;
    if (!!window.navigator.share) return;

    window.navigator.share = (data: ShareData): Promise<void> => {
      return new Promise((resolve) => {
        sendRnMessage({ type: 'SHARE_FALLBACK', value: data });
        resolve();
      });
    };
  }, []);

  return <Context.Provider value={{ cameraStatus, mobileAppFeatures, canUse }}>{children}</Context.Provider>;
};

export const useReactNativeContext = (): State => {
  return useContext(Context);
};
