import classNames from 'classnames';
import Footer from 'components/layout/Footer';
import { useAuthContext } from 'hooks/useAuth';
import { useDeviceDetectContext } from 'hooks/useDeviceDetect';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { routes } from 'utils/routes';
import styles from './Layout.module.scss';

type Props = {
  children: ReactNode;
  header: ReactNode;
  fullWidth: boolean;
  withoutFooter?: boolean;
};

const Layout = ({ children, header, fullWidth, withoutFooter }: Props): JSX.Element => {
  // TODO: get the hook out of the components folder
  const { isSignedIn } = useAuthContext();
  const router = useRouter();
  const { isMobileApp } = useDeviceDetectContext();
  const shouldHideFooter = withoutFooter || isMobileApp || (router.pathname === routes.landing && isSignedIn);

  return (
    <div className={classNames(styles.layout, 'darkmode')}>
      {header}
      <div className={classNames(styles.container, { [styles.fullContainer]: fullWidth })}>
        <main className={styles.content} role="content-area">
          {children}
        </main>
      </div>
      {!shouldHideFooter && <Footer />}
    </div>
  );
};

export default Layout;
