import { useDeviceDetectContext } from './useDeviceDetect';
import { useSessionStorage } from './useSessionStorage';

type NotificationArgs = {
  title: string;
  body: string;
  tag: string;
  onClick: () => void;
};

const icon = 'https://literal.club/apple-touch-icon.png';

export function useBrowserNotification() {
  const { isDesktop } = useDeviceDetectContext();

  const [requestedPermission, setRequestedPermission] = useSessionStorage(
    'browserNotificationRequestedPermission',
    false
  );

  function notify({ title, onClick, body, tag }: NotificationArgs) {
    if (Notification.permission === 'granted') {
      const notification = new Notification(title, { body, tag, icon });
      notification.onclick = onClick;
    }
  }

  function requestNotificationPermission() {
    // We only care about desktop
    if (!isDesktop) return;
    // If we've already asked for permisson we don't want to ask again in this session
    if (requestedPermission) return;
    // If API is not available
    if (!('Notification' in window)) return;

    if (Notification.permission === 'granted') return;

    if (Notification.permission !== 'denied') {
      Notification.requestPermission().then(() => {
        setRequestedPermission(true);
      });
    }
  }

  return { notify, requestNotificationPermission };
}
