import { IconProps } from './type';

export const PatronDisplayIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.58824 5L3 11.7452M7.58824 5H16M7.58824 5L11.0294 11.7452M24.4118 5L29 11.7452M24.4118 5H16M24.4118 5L20.9706 11.7452M29 11.7452L16 28M29 11.7452H20.9706M3 11.7452L16 28M3 11.7452H11.0294M11.0294 11.7452H20.9706M11.0294 11.7452L16 28M11.0294 11.7452L16 5M16 28L20.9706 11.7452M20.9706 11.7452L16 5"
      stroke={color}
      strokeWidth="1.6"
      strokeLinejoin="round"
    />
  </svg>
);
