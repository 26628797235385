import { IconProps } from './type';

export const RotateLeftIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.55981 5.57458L11.6709 3.07458M9.55981 5.57458L11.6709 8.07458M9.55981 5.57458C9.55981 5.57458 11.0598 5.57458 13.0598 5.57458C19.5598 5.57458 20.5598 8.57458 20.5598 14.5746"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M3.44043 19.6179V19.6179C3.44043 20.34 4.0258 20.9254 4.7479 20.9254H15.0599C16.1645 20.9254 17.0599 20.03 17.0599 18.9254V16.9884M3.44043 19.6179V12.5746C3.44043 11.47 4.33586 10.5746 5.44043 10.5746H15.0599C16.1645 10.5746 17.0599 11.47 17.0599 12.5746V16.9884M3.44043 19.6179L6.0008 16.493C6.54047 15.8343 7.51433 15.7436 8.16635 16.2913V16.2913C8.73971 16.7729 9.57757 16.7683 10.1456 16.2803L11.7414 14.9094C12.4596 14.2925 13.512 14.2636 14.2629 14.8403L17.0599 16.9884"
      stroke={color}
      strokeWidth="1.6"
    />
  </svg>
);
