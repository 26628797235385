import { IconProps } from './type';

export const NotificationOnIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.75307 11.0326C5.65314 8.32716 7.17326 5.26993 9.9942 4.51406V4.51406C12.8151 3.75819 15.6602 5.64577 16.0604 8.53868L16.3908 10.9275C16.4041 11.0242 16.4496 11.1137 16.5197 11.1815L19.3336 13.9022C19.8924 14.4426 19.6481 15.3859 18.8973 15.5871L7.82041 18.5551C7.0696 18.7563 6.38638 18.0615 6.60016 17.3142L7.67664 13.551C7.70349 13.4572 7.6981 13.357 7.66133 13.2666L6.75307 11.0326Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M14.3247 16.8123C14.6821 18.146 13.8906 19.5168 12.557 19.8742C11.2233 20.2315 9.85247 19.44 9.49512 18.1064"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </svg>
);
