/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  ActivityGroupPartsFragment,
  BookInClubActivityFullFragment,
  BookInClubPartsFragment,
  BookOnShelfActivityFullFragment,
  BookOnShelfPartsFragment,
  BookPartsFragment,
  BookReviewActivity,
  BookReviewActivityFullFragment,
  ClubPartsFragment,
  ClubPostActivityFullFragment,
  ClubPostPartsFragment,
  CompleteGoalActivityFullFragment,
  CreateGoalActivityFullFragment,
  GoalParticipantPartsFragment,
  MomentActivity,
  MomentActivityFullFragment,
  MomentPartsFragment,
  ProfilePartsFragment,
  ReadingStateActivity,
  ReadingStateActivityFullFragment,
  ReadingStatePartsFragment,
  ReviewPartsFragment,
  ShelfFullFragment,
  ShelfPartsFragment,
} from 'generated/graphql';
import isPlainObject from 'lodash.isplainobject';
import { MentionProfile } from 'ui/specific/richText/MentionTypes';

const _isObject = (data?: unknown): data is Record<string, unknown> => {
  return !!data && isPlainObject(data);
};

const _isBook = (data?: unknown): boolean => _isObject(data) && data.__typename === 'Book';
const _isProfile = (data?: unknown): boolean => _isObject(data) && data.__typename === 'Profile';
const _isShelf = (data?: unknown): boolean => _isObject(data) && data.__typename === 'Shelf';
const _isMoment = (data?: unknown): boolean => _isObject(data) && data.__typename === 'Moment';
const _isReadingState = (data?: unknown): boolean => _isObject(data) && data.__typename === 'ReadingState';
const _isBookReview = (data?: unknown): boolean => _isObject(data) && data.__typename === 'Review';
const _isClub = (data?: unknown): boolean => _isObject(data) && data.__typename === 'Club';
const _isBookInClub = (data?: unknown): boolean => _isObject(data) && data.__typename === 'BookInClub';
const _isClubPost = (data?: unknown): boolean => _isObject(data) && data.__typename === 'ClubPost';
const _isGoalParticipant = (data?: unknown): boolean =>
  _isObject(data) && data.__typename === 'GoalParticipant';

export const isBook = (data?: Record<string, unknown>): data is BookPartsFragment => _isBook(data);

export const isProfile = (data?: Record<string, unknown>): data is ProfilePartsFragment => _isProfile(data);

export const isShelf = (data?: Record<string, unknown>): data is ShelfFullFragment =>
  !!data && _isShelf(data) && 'owner' in data;

export const isMoment = (
  data?: Record<string, unknown>
): data is MomentPartsFragment & { book: BookPartsFragment } => _isMoment(data);

export const isReadingState = (
  data?: Record<string, unknown>
): data is ReadingStatePartsFragment & { book: BookPartsFragment } => _isReadingState(data);

export const isBookReview = (
  data?: Record<string, unknown>
): data is ReviewPartsFragment & { book: BookPartsFragment } => _isBookReview(data);

export const isBookOnShelf = (
  data?: Record<string, unknown>
): data is BookOnShelfPartsFragment & { book: BookPartsFragment; shelf: ShelfPartsFragment } =>
  _isObject(data) && _isShelf(data.shelf) && _isBook(data.book);

export const isBookInClub = (
  data?: Record<string, unknown>
): data is BookInClubPartsFragment & {
  book: BookPartsFragment;
  profile: ProfilePartsFragment;
  club: ClubPartsFragment;
} => _isObject(data) && _isBookInClub(data) && _isBook(data.book) && _isClub(data.club);

export const isClubPost = (
  data?: Record<string, unknown>
): data is ClubPostPartsFragment & {
  club: ClubPartsFragment;
} => _isObject(data) && _isClubPost(data) && _isClub(data.club);

export const isMomentActivity = (
  data?: Record<string, unknown>
): data is MomentActivity & {
  book: BookPartsFragment;
  profile: ProfilePartsFragment;
} => {
  const enrichedData = data?.data as Record<string, unknown>;
  return _isMoment(enrichedData) && !!enrichedData.profile && !!enrichedData.book;
};

export const isReadingStateActivity = (
  data?: Record<string, unknown>
): data is ReadingStateActivity & {
  book: BookPartsFragment;
  profile: ProfilePartsFragment;
} => {
  const enrichedData = data?.data as Record<string, unknown>;
  return _isReadingState(enrichedData) && !!enrichedData.profile && !!enrichedData.book;
};

export const isBookReviewActivity = (
  data?: Record<string, unknown>
): data is BookReviewActivity & {
  book: BookPartsFragment;
  profile: ProfilePartsFragment;
} => {
  const enrichedData = data?.data as Record<string, unknown>;
  return _isBookReview(enrichedData) && !!enrichedData.profile && !!enrichedData.book;
};

export const isBookOnShelfActivity = (data: any): data is BookOnShelfActivityFullFragment =>
  data.__typename === 'BookOnShelfActivity';

export const isBookInClubActivity = (data: any): data is BookInClubActivityFullFragment =>
  data.__typename === 'BookInClubActivity';

export const isClubPostActivity = (data: any): data is ClubPostActivityFullFragment =>
  data.__typename === 'ClubPostActivity';

export const isCreateGoalActivity = (data: any): data is CreateGoalActivityFullFragment =>
  data.__typename === 'CreateGoalActivity';

export const isCompleteGoalActivity = (data: any): data is CompleteGoalActivityFullFragment =>
  data.__typename === 'CompleteGoalActivity';

export const isGoalParticipant = (data: any): data is GoalParticipantPartsFragment =>
  !!data && _isGoalParticipant(data);

export const isMomentGroup = (
  data: any
): data is ActivityGroupPartsFragment & {
  activities?: Array<MomentActivityFullFragment>;
} => data.group?.includes('create_moment');

export const isReadingStateGroup = (
  data: any
): data is ActivityGroupPartsFragment & {
  activities?: Array<ReadingStateActivityFullFragment>;
} => data.group?.includes('update_readingState');

export const isBookReviewGroup = (
  data: any
): data is ActivityGroupPartsFragment & {
  activities?: Array<BookReviewActivityFullFragment>;
} => data.group?.includes('create_bookReview');

export const isBookOnShelfGroup = (
  data: any
): data is ActivityGroupPartsFragment & {
  activities?: Array<BookOnShelfActivityFullFragment>;
} => data.group?.includes('add_bookOnShelf');

export const isBookInClubGroup = (
  data: any
): data is ActivityGroupPartsFragment & {
  activities?: Array<BookInClubActivityFullFragment>;
} => data.group?.includes('add_bookInClub');

export const isClubPostGroup = (
  data: any
): data is ActivityGroupPartsFragment & {
  activities?: Array<ClubPostActivityFullFragment>;
} => data.group?.includes('post_clubPost');

export const isCreateGoalGroup = (
  data: any
): data is ActivityGroupPartsFragment & {
  activities?: Array<CreateGoalActivityFullFragment>;
} => data.group?.includes('create_goal');

export const isCompleteGoalGroup = (
  data: any
): data is ActivityGroupPartsFragment & {
  activities?: Array<CompleteGoalActivityFullFragment>;
} => data.group?.includes('complete_goal');

export const isArrayOfMomentActivities = (
  data?: Record<string, unknown>[]
): data is Array<
  MomentActivity & {
    book: BookPartsFragment;
    profile: ProfilePartsFragment;
  }
> => {
  return !!data && Array.isArray(data) && data.every(isMomentActivity);
};

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function isProfileMention(json: any): json is MentionProfile {
  if (
    typeof json === 'object' &&
    'type' in json &&
    json.type === 'Profile' &&
    'handle' in json &&
    'label' in json
  ) {
    return true;
  }
  return false;
}

export const isHtmlElement = (el: any): el is HTMLElement => {
  return el instanceof HTMLElement;
};
