import { IconProps } from './type';

export const UserDisplayIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.3335 21.3333C21.3335 21.3333 19.6366 22.6667 16.0003 22.6667C12.3639 22.6667 10.6668 21.3333 10.6668 21.3333"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
      />
      <path
        d="M13.8182 4.8H18.1818C23.1624 4.8 27.2 8.83758 27.2 13.8182V18.1818C27.2 23.1624 23.1624 27.2 18.1818 27.2H13.8182C8.83758 27.2 4.8 23.1624 4.8 18.1818V13.8182C4.8 8.83758 8.83758 4.8 13.8182 4.8Z"
        stroke={color}
        strokeWidth="1.6"
      />
      <rect x="9.33325" y="10.6667" width="2.13333" height="6.66667" rx="1.06667" fill={color} />
      <rect x="20.5332" y="10.6667" width="2.13333" height="6.66667" rx="1.06667" fill={color} />
    </svg>
  );
};
