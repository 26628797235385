import { IconProps } from './type';

export const DownloadMobileIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 3L12 13M12 13L7.5 8.88889M12 13L16.5 8.88889"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M4.5 13V18C4.5 19.1046 5.39543 20 6.5 20H17.5C18.6046 20 19.5 19.1046 19.5 18V13"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </svg>
);
