import classNames from 'classnames';
import { Author, Book } from 'generated/graphql';
import { useEffect, useRef, useState } from 'react';
import { useCoverImage } from '../hooks/useCoverImage';
import { AssetSize } from '../types';
import styles from './Cover.module.scss';

type Props = {
  book: Pick<Book, 'title' | 'subtitle' | 'cover'> & {
    authors: Array<Pick<Author, 'name'>>;
  };
  assetSize?: AssetSize;
  stretch?: 'vertical' | 'horizontal';
  disableLazyLoading?: boolean;
};

export const Cover = ({
  book,
  stretch = 'horizontal',
  assetSize = '200',
  disableLazyLoading,
}: Props): JSX.Element => {
  const element = useRef<HTMLDivElement>(null);
  const [myWidth, setMyWidth] = useState('200px');

  const coverImage = useCoverImage(book, assetSize);

  useEffect(() => {
    if (!element.current) return;
    setMyWidth(`${element.current.offsetWidth}px`);
  }, [element.current]);

  if (!book.cover) {
    const style = { ['--myWidth' as string]: myWidth };

    return (
      <div className={styles.outer} style={style}>
        <div
          className={classNames(styles.fallbackImage, styles[stretch], styles[`assetSize-${assetSize}`])}
          ref={element}
        >
          <div className={styles.author}>{book.authors.map((a) => a.name).join(', ')}</div>
          <div className={styles.title}>{book.title}</div>
          <div className={styles.subtitle}>{book.subtitle}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.outer}>
      <img
        key={book.cover}
        src={coverImage}
        loading={disableLazyLoading ? 'eager' : 'lazy'}
        alt={book.title}
        className={classNames(styles.image, styles[`assetSize-${assetSize}`])}
        draggable="false"
      />
    </div>
  );
};
