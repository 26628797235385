import { ReactNode } from 'react';
import styles from './CallToAction.module.scss';

type Props = {
  icon: ReactNode;
  text: ReactNode | string;
  button?: ReactNode;
};

export const CallToAction = ({ icon, text, button }: Props): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        {icon}
        <p className="ml-3">{text}</p>
      </div>
      {button && button}
    </div>
  );
};
