import { ReadingStateWorkPartsFragment, useMyReadingStatesQuery } from 'generated/graphql';
import { useMemo } from 'react';
import { useAuthContext } from './useAuth';

/**
 * Returns all the reading states for the current user
 */
export function useMyReadingStates() {
  const { token, profile } = useAuthContext();

  const { data, loading } = useMyReadingStatesQuery({
    skip: !token || !profile,
  });

  return { readingStates: data?.myReadingStatesWithWork, loading };
}

/**
 * Returns a specific reading state for the current user, for a given book
 */
export function useMyReadingStateByBook({ bookId, workId }: { bookId: string; workId?: string | null }) {
  const { readingStates } = useMyReadingStates();
  const readingState = useMemo(
    () => getReadingState(readingStates, bookId, workId),
    [readingStates, bookId, workId]
  );

  return readingState;
}

function getReadingState(
  readingStates: ReadingStateWorkPartsFragment[] = [],
  bookId: string,
  workId?: string | null
): ReadingStateWorkPartsFragment | undefined {
  if (!readingStates || readingStates.length < 1) return;

  // First, try to find a reading state for the bookId
  const readingState = readingStates.find((rs) => rs.bookId === bookId);
  if (readingState) return readingState;

  // Fallback to the workId
  if (!workId) return;
  return readingStates.find((rs) => rs.workId === workId);
}
