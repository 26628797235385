import { GlobalNotification } from 'components/notification/Notification';
import { NotificationsQuery } from 'generated/graphql';
import { useEffect } from 'react';
import { globalEvents } from 'utils/event';

type Props = {
  data?: NotificationsQuery;
  limit?: number;
};

export const NotificationsListContent = ({ data, limit }: Props): JSX.Element | null => {
  // Hack for the popper container to resize correctly
  useEffect(() => {
    globalEvents.emit('dropdownResize');
  }, []);

  if (!data?.notifications) return null;

  return (
    <>
      {data.notifications.slice(0, limit).map((a) => {
        return <GlobalNotification key={a.id} notification={a} />;
      })}
    </>
  );
};
