import classNames from 'classnames';
import { useUnreadClubsPostCountQuery } from 'generated/graphql';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode } from 'react';
import { useDoubleTap } from 'ui/hooks/useDoubleTap';
import {
  ClubDisplayIcon,
  HighlightDisplayIcon,
  HomeDisplayIcon,
  LibraryDisplayIcon,
  SearchDisplayIcon,
} from 'ui/icons';
import { noop } from 'utils/noop';
import styles from './MobileNavBar.module.scss';

type Props = {
  notifications?: ReactNode;
  unseenNotificationsCount?: number | null;
  updateNotificationCount?: () => void;
  search?: ReactNode;
  openMomentModal?: () => void;
};

export const MobileNavBar = ({ openMomentModal = noop }: Props): JSX.Element => {
  const router = useRouter();

  const bindDoubleTap = useDoubleTap(() => {
    const input = document.querySelector('input[name="query"]') as HTMLInputElement;
    input?.focus();
  });

  const { data: unreadClub } = useUnreadClubsPostCountQuery();

  return (
    <>
      <div className={styles.container} role="mobile-nav">
        <nav className={styles.inner}>
          <Link href="/">
            <a
              className={classNames(styles.link, {
                [styles.active]: router.pathname === '/',
              })}
            >
              <HomeDisplayIcon />
              <span>Home</span>
            </a>
          </Link>
          <Link href="/clubs">
            <a
              className={classNames(styles.link, {
                [styles.active]: router.pathname.split('/')[1] === ('clubs' || 'club'),
                [styles.unread]: unreadClub?.unreadClubsPostCount && unreadClub.unreadClubsPostCount > 0,
              })}
            >
              <ClubDisplayIcon />
              <span>Clubs</span>
            </a>
          </Link>
          <Link href="/search">
            <a
              className={classNames(styles.link, {
                [styles.active]: router.pathname.split('/')[1] === 'search',
              })}
              {...bindDoubleTap}
            >
              <SearchDisplayIcon />
              <span>Explore</span>
            </a>
          </Link>

          <button className={classNames(styles.link, styles.mobileSearchButton)} onClick={openMomentModal}>
            <HighlightDisplayIcon />
            <span>Highlight</span>
          </button>
          <Link href="/library">
            <a
              className={classNames(styles.link, {
                [styles.active]: router.pathname.split('/')[1] === 'library',
              })}
            >
              <LibraryDisplayIcon />
              <span>Library</span>
            </a>
          </Link>
        </nav>
      </div>
      <div className={styles.pushup}></div>
    </>
  );
};
