import { FC } from 'react';
import styles from './GridVertical.module.scss';

/**
 * Used to display books or other bordered items in a vertical, scrollable list
 * The main advantages: reusable borders (hacky) and layout
 */
export const GridVertical: FC = ({ children }) => {
  return <div className={styles.bookContainer}>{children}</div>;
};
