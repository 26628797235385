import { IconProps } from './type';

export const OpenDoorIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.999 6.88235L6.99902 5V19.1176L12.999 21V6.88235Z" stroke={color} strokeWidth="1.6" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9991 2.99707H18.7991V3.79707L18.7991 19.1895V19.9895H17.9991H12.9991V18.3895H17.1991L17.1991 4.59707L7.79907 4.59707V4.95816L6.99907 4.70251L6.99907 19.0829L9.8358 19.9895H6.99907H6.19907V19.1895L6.19907 3.79707V2.99707H6.99907L17.9991 2.99707Z"
      fill={color}
    />
  </svg>
);
