/**
 * @deprecated
 * @returns {boolean}
 */
export function isMobileAppV1(): boolean {
  return isBrowser() && navigator.userAgent === 'LiteralMobile';
}

export function isMobileApp(): boolean {
  return isBrowser() && navigator.userAgent.includes('LiteralMobileV2');
}

export function isMobileAppAndroid(): boolean {
  return isMobileApp() && navigator.userAgent.includes('android');
}

export function isMobileAppIOs(): boolean {
  return isMobileApp() && navigator.userAgent.includes('ios');
}

export function isBrowser(): boolean {
  return typeof window !== 'undefined';
}

export function isSsr(): boolean {
  return typeof window === 'undefined';
}

export function isDesktop(): boolean {
  return !isMobileAppIOs() && !isMobileAppAndroid() && !isMobileApp() && !isMobileAppV1() && !isSsr();
}

export function isShareable(): boolean {
  return typeof navigator !== 'undefined' && !!navigator.share;
}
