import { IconProps } from './type';

export const GoalIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9817 3.57422C6.50407 3.99449 3 7.76364 3 12.3512C3 17.2201 6.94704 21.1672 11.816 21.1672C16.6849 21.1672 20.6319 17.2201 20.6319 12.3512C20.6319 11.5228 20.5177 10.7212 20.304 9.96104C20.145 10.0382 19.8814 10.1577 19.5799 10.2459C19.319 10.3223 19.0478 10.3726 18.7634 10.3942C18.9383 11.0165 19.0319 11.6729 19.0319 12.3512C19.0319 16.3365 15.8012 19.5672 11.816 19.5672C7.83069 19.5672 4.6 16.3365 4.6 12.3512C4.6 8.75461 7.23129 5.77257 10.6738 5.22515L10.9817 3.57422ZM10.3119 7.24819C8.10665 7.89738 6.49688 9.93686 6.49688 12.3523C6.49688 15.2905 8.87872 17.6723 11.8169 17.6723C14.755 17.6723 17.1369 15.2905 17.1369 12.3523C17.1369 11.5647 16.9657 10.817 16.6585 10.1445C16.1819 10.0165 15.7946 9.8675 15.5007 9.75442L15.5006 9.7544C15.3974 9.71468 15.3057 9.6794 15.2257 9.65102C15.0998 9.60636 14.9628 9.56029 14.8017 9.5175L14.7179 10.0235C15.2303 10.661 15.5369 11.4708 15.5369 12.3523C15.5369 14.4068 13.8714 16.0723 11.8169 16.0723C9.76238 16.0723 8.09688 14.4068 8.09688 12.3523C8.09688 10.9556 8.86662 9.73868 10.005 9.10261L10.3119 7.24819Z"
      fill={color}
    />
    <path
      d="M20.5749 7.49976L21.3413 2.92908C20.1591 2.83594 19.6717 3.41624 18.6329 3.3344C16.6707 3.17981 16.9322 2.27237 13.6568 2.32367L12.797 6.93394C16.0723 6.88263 16.3127 7.84517 18.2749 7.99976C19.3137 8.0816 19.3927 7.40662 20.5749 7.49976Z"
      fill="none"
    />
    <path
      d="M13.6568 2.32367C16.9322 2.27237 16.6707 3.17981 18.6329 3.3344C19.6717 3.41624 20.1591 2.83594 21.3413 2.92908L20.5749 7.49976C19.3927 7.40662 19.3137 8.0816 18.2749 7.99976C16.3127 7.84517 16.0723 6.88263 12.797 6.93394M13.6568 2.32367L12.797 6.93394M13.6568 2.32367L13.8579 1.01693M12.797 6.93394L11.9005 12.3517"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <circle cx="11.8169" cy="12.352" r="1.52" fill={color} />
  </svg>
);
