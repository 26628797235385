import { IconProps } from './type';

export const DroppedIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 3L12 13M12 13L7.5 8.88889M12 13L16.5 8.88889" stroke={color} strokeWidth="1.6" />
    <path
      d="M4 17H18.5C19.3284 17 20 17.6716 20 18.5V18.5C20 19.3284 19.3284 20 18.5 20H4"
      stroke={color}
      strokeWidth="1.6"
    />
  </svg>
);
