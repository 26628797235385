import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import classNames from 'classnames';
import { ReactNode, useEffect, useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Portal } from 'react-portal';
import { ArrowLeftIcon, CloseIcon } from 'ui/icons';
import Slot from 'ui/Slot';
import styles from './Modal.module.scss';
import ModalWizard, { ModalWizardConfig } from './ModalWizard';

type Props = {
  children: ReactNode;
  wizardConfig?: ModalWizardConfig;
  close: () => void;
  hideCloseButton?: boolean;
  maxWidth?: number;
};

const Modal = ({ children, close, wizardConfig, hideCloseButton, maxWidth = 720 }: Props): JSX.Element => {
  const scrollable = useRef<HTMLDivElement>(null);
  useHotkeys('esc', close);

  useEffect(() => {
    if (!scrollable.current) return;
    disableBodyScroll(scrollable.current);
    return () => clearAllBodyScrollLocks();
  }, []);

  return (
    <Portal>
      <div
        className={classNames(styles.outer, {
          [styles.wizardMode]: !!wizardConfig,
        })}
        role="modal"
      >
        {!hideCloseButton && (
          <div className={styles.close}>
            <button className={styles.closeButton} onClick={close} data-testid="modal-close-button">
              <div className={classNames(styles.closeLargeScreen, styles.icon)}>
                <CloseIcon />
              </div>
              <div className={classNames(styles.closeMedScreen, styles.icon)}>
                {wizardConfig ? <CloseIcon /> : <ArrowLeftIcon />}
              </div>
            </button>
            <div className={styles.mobileText} id="mobile-modal-text-slot"></div>
            <div className={styles.mobileActions} id="mobile-modal-actions-slot"></div>
          </div>
        )}

        <div
          className={styles.backdrop}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            close();
          }}
        >
          <div className={styles.scrollable} role="modal-scroller" ref={scrollable}>
            <div className={styles.inner} role="modal-inner" onClick={(e) => e.stopPropagation()}>
              <div
                className={classNames(styles.padding, {
                  [styles.extraPadding]: wizardConfig && !hideCloseButton,
                })}
              >
                <div
                  className={styles.contentOuter}
                  style={{ ['--modal-max-width' as never]: `${maxWidth}px` }}
                >
                  {wizardConfig && (
                    <div
                      className={classNames(styles.wizzardWrapper, {
                        [styles.roomForTopBar]: !hideCloseButton,
                      })}
                    >
                      <ModalWizard wizardConfig={wizardConfig} />
                    </div>
                  )}
                  <div className={styles.contentInner}>
                    <div id="modal-header-slot"></div>
                    <div className={styles.contentPadding}>{children}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default Modal;

type MobileActionProps = {
  children: ReactNode;
};

export const ModalActionButtonSlot = ({ children }: MobileActionProps): JSX.Element => {
  return <Slot elementId="mobile-modal-actions-slot">{children}</Slot>;
};

type MobileTextProps = {
  text: string;
};

export const ModalTextSlot = ({ text }: MobileTextProps): JSX.Element => {
  return <Slot elementId="mobile-modal-text-slot">{text}</Slot>;
};

type HeaderSlotProps = {
  children: ReactNode;
};

export const ModalHeaderSlot = ({ children }: HeaderSlotProps): JSX.Element => {
  return <Slot elementId="modal-header-slot">{children}</Slot>;
};
