import { FullPageLoadingSpinner } from 'components/basics/LoadingSpinner';
import { AcceptTermsForm } from 'components/forms/AcceptTerms';
import {
  AcceptancePartsFragment,
  AcceptancePayload,
  useAcceptanceQuery,
  useUpdateAcceptanceMutation,
} from 'generated/graphql';
import { ReactNativeContextProvider } from 'hooks/useReactNative';
import React, { ReactNode, useContext, useEffect } from 'react';
import { BookModalProvider } from './modals/useBookModal';
import { GlobalModalProvider } from './modals/useGlobalModal';
import { OnboardingModalProvider } from './modals/useOnboardingModal';
import { useAuthContext } from './useAuth';
import { NotificationsProvider } from './useNotifications';
import { ScreenSizeProvider } from './useScreenSize';
import { SocketsProvider } from './useSockets';

type State = {
  acceptance: AcceptancePartsFragment | null | undefined;
};

export const GlobalContext = React.createContext<State>({
  acceptance: null,
});

type Props = {
  children: ReactNode;
};

export const GlobalContextProvider = ({ children }: Props): JSX.Element => {
  const { isLoading, profile } = useAuthContext();

  const [updateAcceptanceMutation] = useUpdateAcceptanceMutation();

  const { data: acceptanceData, refetch: refetchAcceptance } = useAcceptanceQuery({
    skip: !profile,
  });

  const updateAcceptance = async (acceptance: AcceptancePayload) => {
    const { acceptedNewsletter, acceptedTerms } = acceptance;
    if (!acceptedTerms) return;

    await updateAcceptanceMutation({
      variables: {
        acceptedNewsletter: acceptedNewsletter || false,
        acceptedTerms,
      },
    });
    await refetchAcceptance();
  };

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };
    appHeight();

    window.addEventListener('resize', appHeight);
    return () => {
      window.removeEventListener('resize', appHeight);
    };
  }, []);

  if (acceptanceData?.acceptance && !acceptanceData?.acceptance?.acceptedTerms) {
    return <AcceptTermsForm acceptance={acceptanceData.acceptance} onSubmit={updateAcceptance} />;
  }

  return (
    <GlobalContext.Provider value={{ acceptance: acceptanceData?.acceptance }}>
      <ReactNativeContextProvider>
        <ScreenSizeProvider>
          <NotificationsProvider>
            <SocketsProvider>
              {isLoading && <FullPageLoadingSpinner />}
              <BookModalProvider>
                <GlobalModalProvider>
                  <OnboardingModalProvider>{children}</OnboardingModalProvider>
                </GlobalModalProvider>
              </BookModalProvider>
            </SocketsProvider>
          </NotificationsProvider>
        </ScreenSizeProvider>
      </ReactNativeContextProvider>
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = (): State => {
  const context = useContext(GlobalContext);
  return context;
};
