import classNames from 'classnames';
import Dropdown from 'components/basics/Dropdowns/Dropdown';
import { LogoLink } from 'components/navigation/LogoLink';
import { MobileTopBarWithBlacklist } from 'components/navigation/MobileTopBarWithBlacklist';
import { MobileNavBar } from 'containers/navigation/MobileNavBar';
import SearchBar from 'containers/Search/SearchBar';
import { useGlobalModalContext } from 'hooks/modals/useGlobalModal';
import { useAuthContext } from 'hooks/useAuth';
import { useDeviceDetectContext } from 'hooks/useDeviceDetect';
import { useNotificationsContext } from 'hooks/useNotifications';
import { useScreenSize } from 'hooks/useScreenSize';
import getConfig from 'next/config';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { Portal } from 'react-portal';
import { JoinArrowIcon, NotificationIcon, NotificationOnDotIcon } from 'ui/icons';
import { isMobileAppV1 } from 'utils/device';
import getApolloClient from 'utils/getApolloClient';
import { routes } from 'utils/routes';
import styles from './Header.module.scss';
import { NavChat } from './NavChat';
import NotificationsList from './NotificationsList';
import { ProfileOptionsDropdown } from './ProfileOptionsDropdown';

const { publicRuntimeConfig } = getConfig();

const HeaderContainer = (): JSX.Element | null => {
  const { setMomentModal } = useGlobalModalContext();
  const { isSignedIn, isLoading, profile } = useAuthContext();
  const { refetchNotificationsCount, notificationsCount } = useNotificationsContext();
  const { smScreen } = useScreenSize();

  const [isNotificationsOpen, setNotificationsOpen] = useState(false);
  const { isMobileApp } = useDeviceDetectContext();
  const router = useRouter();
  const currentPath = router.asPath;
  const buttonRef = useRef(null);
  const isStreamOnline: boolean = publicRuntimeConfig.streamToggle;
  const hasNotifications = notificationsCount && notificationsCount > 0;
  const [isSearchFocused, setSearchFocused] = useState(false);

  useEffect(() => {
    // When Notification Dropdown closes we want to refetch the
    // notification count so the green highlight disappears
    if (!isNotificationsOpen && profile) {
      refetchNotificationsCount();
    }
  }, [isNotificationsOpen, profile]);

  if (isLoading) return null;

  // When signed out, for small screens, on the search page, the search bar is visible at the top
  // so we don't want this navigation to be visible
  if (
    !isSignedIn &&
    smScreen &&
    (router.pathname === routes.search || router.pathname === routes.searchWithQuery)
  ) {
    return null;
  }

  return (
    <>
      <MobileTopBarWithBlacklist />
      <>
        <header
          id="header-nav"
          role="header-nav"
          className={classNames(styles.header, {
            [styles.authenticated]: isSignedIn,
            [styles.hideHeaderOnMobile]: isSignedIn || isMobileApp,
          })}
        >
          <div className={styles.inner}>
            <div className={styles.left}>
              <LogoLink isSignedIn={isSignedIn} />
            </div>

            <div
              className={classNames(styles.search, {
                [styles.searchFocused]: isSearchFocused,
              })}
            >
              <div className={styles.searchInner}>
                <SearchBar onIsActive={setSearchFocused} />
              </div>
            </div>

            <div className={styles.right}>{isSignedIn ? renderSignedIn() : renderSignedOut()}</div>
          </div>
        </header>
        <div className={styles.spacer}></div>
      </>
      {isSignedIn && !isMobileAppV1() && (
        <Portal>
          <MobileNavBar openMomentModal={() => setMomentModal({})} />
        </Portal>
      )}
    </>
  );

  function renderSignedOut() {
    return (
      <>
        <a
          href={routes.pagesAbout}
          className={classNames(styles.standaloneButton, {
            [styles.active]: currentPath === routes.pagesAbout,
          })}
        >
          About
        </a>
        {!smScreen && (
          <a
            href={routes.pagesPartners}
            className={classNames(styles.standaloneButton, {
              [styles.active]: currentPath === routes.pagesPartners,
            })}
          >
            Partners
          </a>
        )}
        <Link
          href={routes.signIn}
          as={{
            pathname: routes.signIn,
            query: { origin: encodeURIComponent(currentPath) },
          }}
        >
          <a className={styles.standaloneButton}>Sign in</a>
        </Link>
        <Link href={routes.register}>
          <a className={styles.greenButton}>
            Join
            <JoinArrowIcon />
          </a>
        </Link>
      </>
    );
  }

  function renderSignedIn() {
    return (
      <>
        <Link href="/">
          <a
            className={classNames(styles.link, { [styles.active]: currentPath === '/' })}
            onClick={() => {
              if (currentPath === '/') {
                const client = getApolloClient();
                client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'aggregatedFeed' });
              }
            }}
          >
            Home
          </a>
        </Link>
        <Link href="/clubs">
          <a className={classNames(styles.link, { [styles.active]: currentPath === routes.clubs })}>Clubs</a>
        </Link>
        <Link href={routes.explore}>
          <a
            className={classNames(styles.link, {
              [styles.active]: currentPath === routes.explore,
            })}
          >
            Explore
          </a>
        </Link>
        <Link href="/library">
          <a
            className={classNames(styles.link, {
              [styles.active]: currentPath.startsWith('/library'),
            })}
          >
            Library
          </a>
        </Link>

        <div className={styles.iconItems}>
          <NavChat
            className={classNames(styles.icon, {
              [styles.active]: currentPath === routes.chat,
            })}
          />
          {isStreamOnline && (
            <>
              <button
                className={classNames(styles.icon, {
                  [styles.unseen]: hasNotifications,
                  [styles.active]: currentPath === routes.notifications,
                })}
                ref={buttonRef}
                onClick={() => setNotificationsOpen((state) => !state)}
              >
                {hasNotifications ? <NotificationOnDotIcon /> : <NotificationIcon />}
              </button>
              {isNotificationsOpen && (
                <Portal>
                  <Dropdown
                    isOpen={isNotificationsOpen}
                    closeMe={() => setNotificationsOpen(false)}
                    buttonRef={buttonRef}
                    positioningStrategy="fixed"
                  >
                    <NotificationsList />
                  </Dropdown>
                </Portal>
              )}
            </>
          )}
        </div>

        <ProfileOptionsDropdown profile={profile} />
      </>
    );
  }
};

export default HeaderContainer;
