import { BookPartsFragment, useGetBookByIdQuery, useGetReviewQuery } from 'generated/graphql';

export default function useGetBook(bookId?: string | null): BookPartsFragment | undefined {
  const { data } = useGetBookByIdQuery({
    variables: { id: bookId || 'skipped' },
    skip: !bookId,
  });

  return data?.book || undefined;
}

export function useGetReview(bookId?: string, profileId?: string) {
  const { data, loading } = useGetReviewQuery({
    variables: {
      bookId: bookId as string,
      profileId: profileId as string,
    },
    skip: !profileId || !bookId,
  });

  return { review: data?.review || undefined, reviewLoading: loading };
}
