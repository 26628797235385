import { IconProps } from './type';

export const PrivateUnlistedIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.99902 11C3.99902 6.58172 7.58075 3 11.999 3V3C16.4173 3 19.999 6.58172 19.999 11V20L16.999 18L14.499 20.2L11.999 18L9.49902 20.2L6.99902 18L3.99902 20L3.99902 11Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinejoin="round"
    />
    <rect x="7.99902" y="8" width="1.6" height="5" rx="0.8" fill={color} />
    <rect x="14.3989" y="8" width="1.6" height="5" rx="0.8" fill={color} />
  </svg>
);
