import classnames from 'classnames';
import { ReactNode } from 'react';
import styles from './SectionHeader.module.scss';

type Props = {
  title: string;
  number?: ReactNode;
  right?: ReactNode;
  mobilePadding?: boolean;

  // Should not be used often. Please use it only if you know what you are doing.
  containerClassName?: string;
};

export const SectionHeader = ({ title, number, right, mobilePadding, containerClassName }: Props) => {
  return (
    <div
      className={classnames(styles.container, containerClassName, {
        [styles.mobilePadding]: mobilePadding,
      })}
    >
      <div className={styles.left}>
        <h2 className="sectionHeader">{title}</h2>
        {number}
      </div>
      {right}
    </div>
  );
};
