import { BrandMark, Logo } from 'components/layout/shared/Brand';
import Link from 'next/link';
import React from 'react';
import styles from './LogoLink.module.scss';

export const LogoLink = ({ isSignedIn }: { isSignedIn: boolean }): JSX.Element => {
  if (isSignedIn) {
    return (
      <Link href="/">
        <a className={styles.mark}>
          <BrandMark />
        </a>
      </Link>
    );
  }

  return (
    <Link href="/">
      <a className={styles.logo}>
        <Logo />
        <BrandMark />
      </a>
    </Link>
  );
};
