import { DocumentNode, useApolloClient, WatchQueryFetchPolicy } from '@apollo/client';
import { useEffect, useState } from 'react';

type Props = {
  query: DocumentNode;
  variables: Record<string, unknown>;
  deps: Array<any>;
  skip?: boolean;
  fetchPolicy?: WatchQueryFetchPolicy;
};

export function useCachedAbortiveQuery<T>({ query, variables, deps, skip, fetchPolicy }: Props) {
  const apolloClient = useApolloClient();
  const [data, setData] = useState<T>();
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (skip) {
      setLoading(false);
      return;
    }

    setLoading(true);
    const watchedQuery = apolloClient.watchQuery<T>({
      query,
      variables,
      fetchPolicy,
    });

    const sub = watchedQuery.subscribe({
      next(x) {
        if (!x.partial) {
          setData(x.data);
          setError(null);
          setLoading(false);
        }
      },
      error(err) {
        setError(err);
        setLoading(false);
      },
      complete() {
        setLoading(false);
      },
    });

    return () => {
      sub.unsubscribe();
    };
  }, deps);

  return { data, error, loading, clearError: () => setError(null) };
}
