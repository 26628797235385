import { IconProps } from './type';

export const NotificationOnDotIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2928 11.7832L19.8896 13.3271C20.8954 14.2997 20.4557 15.9977 19.1042 16.3598L8.02737 19.3278C6.67591 19.69 5.44611 18.4393 5.83091 17.0941L6.87289 13.4516L6.01188 11.3339C4.73072 8.18268 6.5013 4.62171 9.78705 3.7413C10.772 3.47738 11.7595 3.49014 12.6721 3.7282C12.4651 4.21287 12.3224 4.7315 12.2546 5.27341C11.6045 5.10655 10.9019 5.09901 10.2012 5.28678C7.84502 5.91811 6.57537 8.47161 7.49406 10.7312L8.40233 12.9652C8.50649 13.2214 8.52175 13.5051 8.44569 13.771L7.36921 17.5342C7.32645 17.6836 7.4631 17.8226 7.61326 17.7824L18.6901 14.8143C18.8403 14.7741 18.8891 14.5854 18.7774 14.4774L15.9636 11.7566C15.8304 11.6279 15.7287 11.4717 15.6647 11.3003C16.379 11.6155 17.169 11.7905 17.9999 11.7905C18.0981 11.7905 18.1958 11.788 18.2928 11.7832Z"
      fill={color}
    />
    <path
      d="M14.3247 16.8123C14.6821 18.146 13.8906 19.5168 12.557 19.8742C11.2233 20.2315 9.85247 19.44 9.49512 18.1064"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <circle cx="18" cy="6" r="4" fill="#E14D4D" />
  </svg>
);

export const NotificationOnDotDisplayIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1392 5.70931C16.6362 4.98864 14.8742 4.77553 13.1184 5.24599C8.89234 6.37836 6.61505 10.9584 8.26285 15.0114L9.44553 17.9203L7.869 23.4315C7.48419 24.7767 8.714 26.0274 10.0655 25.6653L12.169 25.1016C13.0207 26.8404 15.0089 27.7916 16.9495 27.2716C18.8902 26.7516 20.1364 24.9337 20.0046 23.0021L25.972 21.4031C27.3234 21.041 27.7631 19.343 26.7573 18.3704L22.6364 14.3859L22.4154 12.7885C21.7951 12.6191 21.2149 12.3527 20.692 12.0065L21.0618 14.6796C21.1041 14.9858 21.2479 15.2689 21.4701 15.4838L25.6451 19.5207C25.7569 19.6287 25.708 19.8174 25.5578 19.8576L18.8925 21.6436L12.453 23.3691L9.65134 24.1198C9.50118 24.16 9.36454 24.021 9.40729 23.8716L11.0045 18.288C11.0895 17.9909 11.0725 17.6738 10.9561 17.3874L9.74503 14.4088C8.4597 11.2473 10.2361 7.67475 13.5325 6.79147C15.1306 6.36327 16.7353 6.66105 18.0179 7.4667C18.006 7.31269 18 7.15705 18 7C18 6.55691 18.048 6.12504 18.1392 5.70931ZM16.5354 25.7261C17.6169 25.4364 18.3465 24.489 18.4093 23.4295L13.7643 24.6741C14.3485 25.5602 15.454 26.0159 16.5354 25.7261Z"
      fill={color}
    />
    <circle cx="24" cy="7" r="4" fill="#E14D4D" />
  </svg>
);
