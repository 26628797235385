import linkIt from 'linkify-it';
import styles from './LinkText.module.scss';

type Props = {
  children: string;
  accent?: boolean;
};

const linkify = linkIt();

export const parseString = (string: string, accent: boolean) => {
  if (string === '') {
    return string;
  }

  const matches = linkify.match(string);
  if (!matches) {
    return string;
  }

  const elements = [];
  let lastIndex = 0;
  matches.forEach((match, i) => {
    // Push preceding text if there is any
    if (match.index > lastIndex) {
      elements.push(string.substring(lastIndex, match.index));
    }
    elements.push(
      <a
        href={match.url}
        target="_blank"
        rel="noreferrer"
        className={`${styles.link} ${accent ? styles.accent : ''}`}
        key={i}
      >
        {match.text.replace('https://', '').replace('http://', '').replace('www.', '')}
      </a>
    );
    lastIndex = match.lastIndex;
  });
  // Push remaining text if there is any
  if (string.length > lastIndex) {
    elements.push(string.substring(lastIndex));
  }
  return elements.length === 1 ? elements[0] : elements;
};

export const LinkText = ({ children, accent = false }: Props): JSX.Element => {
  if (!linkify.test('Site github.com!')) return <>{children}</>;

  return <>{parseString(children, accent)}</>;
};
