import { IconProps } from './type';

export const LanguageIcon = ({ color = 'currentColor' }: IconProps): JSX.Element => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20 12H4M20 12C20 10.5937 19.6372 9.27224 19 8.12399M20 12C20 13.4571 19.6104 14.8233 18.9297 16M12 20C7.58172 20 4 16.4183 4 12M12 20C14.9611 20 17.5465 18.3912 18.9297 16M12 20C12 20 8.5 18.9297 8.5 12C8.5 5.07026 12 4 12 4M12 20C12 20 15.5 18.6153 15.5 12C15.5 5.38468 12 4 12 4M4 12C4 10.5937 4.36284 9.27224 5 8.12399M12 4C8.98797 4 6.36473 5.66457 5 8.12399M12 4C15.012 4 17.6353 5.66458 19 8.12399M5 8.12399H19M18.9297 16H5"
      stroke={color}
      strokeWidth="1.6"
    />
  </svg>
);
